import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
    Colours,
    Sizes,
    AlternateAnchor,
    noticeError,
    FormattedNumberRaisedSymbol,
    useIsMounted,
    fetchUserCity, 
    Locales, 
    getLanguageFromLocale,
} from '@ratehub/base-ui';
import { fetchBestRates, SCENARIO_TYPES } from '@ratehub/mtg-common';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import { DEFAULT_BEST_PURCHASE_PARAMS, MORTGAGE_TYPES } from '../definitions/MortgageTerms';
import PATHS from '../definitions/Paths';
import SkeletonPlaceholder from './SkeletonPlaceholder';


function BestRatesMortgages({ ...otherProps }) {
    const intl = useIntl();

    const [ isReady, setIsReady ] = useState(false);
    const [ bestRateFixed, setBestRateFixed ] = useState(null);
    const [ bestRateVariable, setBestRateVariable ] = useState(null);
    const getIsMounted = useIsMounted();

    const hasFixedRate = bestRateFixed?.value && bestRateFixed?.description;
    const hasVariableRate = bestRateVariable?.value && bestRateVariable?.description;

    const PARAMS = {
        ...DEFAULT_BEST_PURCHASE_PARAMS,
        type: [ MORTGAGE_TYPES.FIXED, MORTGAGE_TYPES.VARIABLE ],
        term: [ 60 ],
        language: getLanguageFromLocale(intl.locale),
        isFeatured: 1,
    };

    const WIZARD_FLOW_HREF = intl.locale === Locales.FRENCH
        ? PATHS.WIZARD_MTG_QUOTER_FR
        : PATHS.WIZARD_MTG_QUOTER_EN;

    useEffect(() => {
        // REQUIREMENT: This needs to show the best rates *based on the user's
        // current location*. Getting the user's location can only be done
        // client-side so that's why this is here and not in this page's
        // getStaticProps function.
        fetchUserCity()
            .then(() => {
                if (!getIsMounted()) {
                    return;
                }

                fetchBestRates(SCENARIO_TYPES.PURCHASE, PARAMS)
                    .then(rates => {
                        if (!getIsMounted()) {
                            return;
                        }

                        setBestRateFixed(rates[0]);
                        setBestRateVariable(rates[1]);
                    })
                    .catch(error => {
                        if (!getIsMounted()) {
                            return;
                        }

                        noticeError(error, {
                            message: '[BestRatesMortgages] Failed to fetch best rates',
                            ...PARAMS,
                        });
                    })
                    .finally(() => {
                        if (!getIsMounted()) {
                            return;
                        }

                        setIsReady(true);
                    });
            })
            .catch(error => {
                if (!getIsMounted()) {
                    return;
                }

                noticeError(error, {
                    message: '[BestRatesMortgages] Failed to fetch user location',
                });
                setIsReady(true);
            });
    }, []);

    return (
        <Container {...otherProps}>
            <p className="title rh-title-2xs rh-text-align-center weight-medium">
                <FormattedMessage
                    id="web-components.BestRatesMortgages.title"
                    defaultMessage="Today’s best mortgage rates"
                />
            </p>

            <div className="loaded-content rh-mb-1_5">
                <Choose>
                    <When condition={isReady}>
                        <Choose>
                            <When condition={hasFixedRate && hasVariableRate}>
                                {/* yay, show rates */}
                                <div className="rates">
                                    <div className="rate">
                                        <span className="rateValue rh-title-m leading-xs weight-medium">
                                            <FormattedNumberRaisedSymbol
                                                value={bestRateFixed.value / 100}
                                                format="percent2"
                                                symbolClassName="percentSymbol rh-title-2xs"
                                            />
                                        </span>
                                        <span className="rateType rh-text-xs leading-xs rh-fg-blackberry">
                                            {bestRateFixed.description}
                                        </span>
                                    </div>

                                    <div className="rate">
                                        <span className="rateValue rh-title-m leading-xs weight-medium">
                                            <FormattedNumberRaisedSymbol
                                                value={bestRateVariable.value / 100}
                                                format="percent2"
                                                symbolClassName="percentSymbol rh-title-2xs"
                                            />
                                        </span>
                                        <span className="rateType rh-text-xs leading-xs rh-fg-blackberry">
                                            {bestRateVariable.description}
                                        </span>
                                    </div>
                                </div>
                            </When>
                            <Otherwise>
                                {/* boo, everything is broken */}
                                <p className="rh-text-s rh-pt-0_875 rh-text-align-center">
                                    <FormattedMessage
                                        id="web-components.BestRatesMortgages.loadingRatesError"
                                        defaultMessage="Sorry, could not load rates."
                                    />
                                </p>
                            </Otherwise>
                        </Choose>
                    </When>
                    <Otherwise>
                        <SkeletonPlaceholder />
                    </Otherwise>
                </Choose>
            </div>

            <AlternateAnchor
                className="rh-mb-0_75"
                size="small"
                href={WIZARD_FLOW_HREF}
                message={(
                    <FormattedMessage
                        id="web-components.BestRatesMortgages.anchorLabel"
                        defaultMessage="See which rates I qualify for"
                    />
                )}
            />
        </Container>
    );
}

const Container = styled.section`
    padding: ${Sizes.SPACING.HALF} ${Sizes.SPACING.ONE_AND_A_QUARTER};

    @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
        display: none;
    }

    > .title {
        margin: ${Sizes.SPACING.THREE_QUARTERS} 0 ${Sizes.SPACING.ONE};
    }

    > .loaded-content {
        position: relative;

        /* IMPORTANT: Prop open to prevent layout jank during rate fetch. Using
        a fixed height (not min-height) to be absolutely sure nothing moves.
        This value should be *just* tall enough to contain the .rates element
        and no more. */
        height: 1.8rem;

        > .rates {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            > .rate {
                flex-basis: 50%;
                flex-grow: 1;

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: ${Sizes.SPACING.HALF};

                color: ${Colours.BLUEBERRY};

                > .rateValue {
                    color: ${Colours.BLUEBERRY_DARK};
                    margin-bottom: 0;
                }
            }
        }
    }
`;

export default BestRatesMortgages;
