import { defineMessages } from 'react-intl';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { DOCUMENTS } from '@ratehub/base-ui';
import { getCreditCardResumeURL } from '@ratehub/cc-common';
import { CREDIT_CARD_DOC_TYPES } from '@ratehub/documents/cc-journey-document/stable';

import getShouldShowBasedOnBannerCookie from './getShouldShowBasedOnBannerCookie';


dayjs.extend(isSameOrAfter);


/**
 * returns Base-ui's MessageBanner props if you want to show the Revisit Banner
 * returns null if you don't want to
 * @param {object} sessionStore
 * @returns {object|null}
 */
function getRevisitBannerPropsCreditCards(sessionStore, locale) {
    // Check the address belongs to credit-cards
    const ccRegex = /^.*\/(credit-cards|cartes-de-credit)(\?.*|\/.*|$)/;
    // ex) "ratehub.ca/credit-cards/cardfinder?documentId=123"
    // [ 'ratehub.ca/credit-cards/cardFinder?documentId=123', // full match
    //   'credit-cards',                                      // 1st parentheses match
    //   '/cardFinder?documentId=123'                         // 2nd parentheses match
    // ]

    // Check the address belongs to /blog/credit-cards
    const blogCCRegex = /^.*\/(blog|blogue)\/(.*credit-cards.*|.*cartes-de-credit.*)(\?.*|\/.*|$)/;
    // ex) "ratehub.ca/blog/best-credit-cards-for-an-avenger"
    // [ 'ratehub.ca/blog/best-credit-cards-for-an-avenger', // full match
    //   'blog',                                             // 1st parentheses match
    //   'credit-cards'                                      // 2nd parentheses match
    // ]

    const result = ccRegex.exec(window.location.href)?.[2] ?? blogCCRegex.exec(window.location.href)?.[2];
    if (result == null) {
        return null;
    }
    // Don't show banner at cardfinder or refresh or plastk pages
    // disallowed example - /cardfinder?debug=true, /cardfinder/test, /cardfinder
    // NOTE: hosted app refresh has Fr url whereas plastk doesn't
    const disallowedRegex = /^(\/cardfinder|\/chercheur-des-cartes|\/eligibility-checker|\/controleur-d-eligibilite|\/apply\/refresh-financial-secured-card|\/demande\/carte-avec-garantie-de-refresh|\/apply\/plastk-secured-visa|(\/todays-best-)[^?/]+|\/find-the-best-credit-cards-canada)(\?.*|\/.*|$)/;
    if (disallowedRegex.test(result)) {
        return null;
    }

    const metadata = sessionStore
        .getDocumentMetadataByPath(DOCUMENTS.CREDIT_CARD_JOURNEY.PATH)
        .filter(isResumable)
        .sort(sortByType)
        .sort(sortByDateUpdated)
        ?.[0];

    if (!metadata) {
        return null;
    }

    if (!getShouldShowBasedOnBannerCookie(
        `revisit-banner-${DOCUMENTS.CREDIT_CARD_JOURNEY.TYPE}`,
        metadata.id,
        metadata.userUpdatedAt ?? metadata.updatedAt,
    )) {
        return null;
    }

    return getMessageBannerProp(locale, metadata.id, metadata.creditCardDocType, metadata.creditCardSlug);
}

function sortByType(docA, docB) {
    if (docA.creditCardDocType === CREDIT_CARD_DOC_TYPES.CARD_FINDER
        && docB.creditCardDocType === CREDIT_CARD_DOC_TYPES.HOSTED_APP) {
        return 1;
    }
    if (docA.creditCardDocType === CREDIT_CARD_DOC_TYPES.HOSTED_APP
        && docB.creditCardDocType === CREDIT_CARD_DOC_TYPES.CARD_FINDER) {
        return -1;
    }
    if (docA.creditCardDocType === docB.creditCardDocType) {
        return 0;
    }

    throw new Error(
        '[getRevisitBannerPropsCreditCards] Unexpected credit card type: '
        + `${docA.creditCardDocType}, ${docB.creditCardDocType}`,
    );
}

/**
 * Sort function that only sorts dates for the same creditCardDocType
 * @param {object} docA
 * @param {object} docB
 * @returns
 */
function sortByDateUpdated(docA, docB) {
    // Keep original order if different creditCardDocType being compared
    if (docA.creditCardDocType !== docB.creditCardDocType) {
        return 0;
    }

    return dayjs(docA.userUpdatedAt ?? docA.updatedAt).isSameOrAfter(dayjs(docB.userUpdatedAt ?? docB.updatedAt))
        ? -1
        : 1;
}

function isResumable(documentMetadata) {
    // we should filter out bugged document metadata
    if (documentMetadata.userUpdatedAt == null && documentMetadata.updatedAt == null) {
        return false;
    }

    // Cannot resume hosted app's that do not have a creditCardSlug
    if (documentMetadata.creditCardDocType === CREDIT_CARD_DOC_TYPES.HOSTED_APP
        && !documentMetadata.creditCardSlug) {
        return false;
    }

    // Cannot resume document if it's not a valid credit creditCardDocType
    if (!Object.values(CREDIT_CARD_DOC_TYPES).includes(documentMetadata.creditCardDocType)) {
        return false;
    }

    return documentMetadata.isResumable === true; // null / undefined / false will be excluded
}

// returns props for base-ui's MessageBanner
function getMessageBannerProp(locale, id, type, cardSlug) {
    return {
        documentId: id,
        documentType: DOCUMENTS.CREDIT_CARD_JOURNEY.TYPE,
        messageBannerStyle: 'compact',
        iconKey: 'note',
        variant: 'success',
        message:
            type === CREDIT_CARD_DOC_TYPES.HOSTED_APP
                ? MESSAGES.CREDIT_CARDS_REVISIT_HOSTED_APP_MESSAGE
                : MESSAGES.CREDIT_CARDS_REVISIT_CARDFINDER_MESSAGE,
        ctaVariant: 'alternativeAnchor',
        ctaMessage: MESSAGES.CREDIT_CARDS_REVISIT_CTA,
        ctaDataName: 'edb-revisit cardfinder-cta',
        href: getCreditCardResumeURL(id, locale, type, cardSlug),
    };
}

const MESSAGES = defineMessages({
    CREDIT_CARDS_REVISIT_CARDFINDER_MESSAGE: {
        id: 'edb.cc.revisit-banner.cardfinder-message',
        defaultMessage: 'Resume the search for your perfect card',
    },
    CREDIT_CARDS_REVISIT_HOSTED_APP_MESSAGE: {
        id: 'edb.cc.revisit-banner.hostedApp-message',
        defaultMessage: 'Resume your credit card application',
    },
    CREDIT_CARDS_REVISIT_CTA: {
        id: 'edb.cc.revisit-banner.cta-button',
        defaultMessage: 'Resume',
    },
});

export default getRevisitBannerPropsCreditCards;
