import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import Colours from '../definitions/Colours';


function ReviewStar({ percentWidth, className, ...otherProps }) {
    return (
        <Container
            className={classNames(className, 'rh-position-relative rh-display-inline-block rh-width-100p')}
            partialWidth={percentWidth}
            {...otherProps}
        >
            {/* this is the full star background with colour STONE */}
            <svg viewBox={viewBox}>
                <polygon
                    points={polyPoints}
                    fill={Colours.STONE}
                    opacity="0.5"
                />
            </svg>

            {/* overlaid with a YUZU coloured star, according to percentWidth */}
            <svg
                viewBox={viewBox}
                className="overlaid-star rh-position-absolute"
            >
                <polygon
                    points={polyPoints}
                    fill={Colours.YUZU}
                />
            </svg>
        </Container>
    );
}

ReviewStar.propTypes = {
    percentWidth: PropTypes.number,
    className: PropTypes.string,
};

ReviewStar.defaultProps = {
    percentWidth: 100,
    className: undefined,
};


const viewBox = '0 0 24 24';
const polyPoints = '12 19 4.94657697 22.7082039 6.2936609 14.854102 0.587321804 9.29179607 8.47328849 8.14589803 12 1 15.5267115 8.14589803 23.4126782 9.29179607 17.7063391 14.854102 19.053423 22.7082039';

const Container = styled.span`
    font-size: 0;

    .overlaid-star {
        left: 0;
        top: 0;
        clip-path: ${({ partialWidth }) => `polygon(0 0, ${partialWidth}% 0, ${partialWidth}% 100%, 0 100%)`};
    }
`;


export default ReviewStar;
