import React from 'react';
import PropTypes from 'prop-types';

import CMSComponentSelector from './CMSComponentSelector';


function BlockRenderer({ blocks, blockMap }) {
    return (
        <>
            <For
                each="block"
                of={blocks}
                index="i"
            >
                <CMSComponentSelector
                    // Used to accept rhId as the key but it's possible it's not unique because of blueprints
                    key={`${block.blockName}-${i}`}
                    blockMap={blockMap}
                    rowIndex={i}
                    {...block}
                />
            </For>
        </>
    );
}

BlockRenderer.propTypes = {
    blocks: PropTypes.array,
    blockMap: PropTypes.object.isRequired,
};

BlockRenderer.defaultProps = {
    blocks: [],
};

export default BlockRenderer;
