import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


function StoreFrontContainer({ heading, mainTitle, children, ...otherProps }) {
    // support both heading object or standalone heading props until FIT-1272
    const headingBlockProps = heading ? heading : { title: mainTitle };
    const hasAnyHeading = mainTitle || heading?.mainTitle;

    return (
        <StoreFrontContainer.Container {...otherProps}>
            <HeadingBlock
                {...headingBlockProps}
            />

            <StoreFrontContainer.List
                alignLeft={hasAnyHeading}
            >
                {children}
            </StoreFrontContainer.List>
        </StoreFrontContainer.Container>
    );
}

StoreFrontContainer.propTypes = {
    heading: HeadingPropTypes,
    mainTitle: PropTypes.string,
    children: PropTypes.array.isRequired,
};

StoreFrontContainer.defaultProps = {
    heading: undefined,
    mainTitle: undefined,
};

const LIST_BREAKPOINT_WIDTH = '43.75rem'; // matches breakpoint in StoreFrontItem

StoreFrontContainer.Container = styled.div``;

StoreFrontContainer.List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    list-style: none;

    margin: 0;
    padding: 0;

    @media (min-width: ${LIST_BREAKPOINT_WIDTH}) {
        flex-wrap: nowrap;
        justify-content: ${props => props.alignLeft ? 'flex-start': 'center'};
    }
`;

StoreFrontContainer.blockKey = 'rh/store-front';
StoreFrontContainer.hasHeading = true;
StoreFrontContainer.requiresLayoutRow = true;

export default StoreFrontContainer;
