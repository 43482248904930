import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sizes } from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import HeadingBlock from './HeadingBlock';


function LogoBlockContainer({ heading, children, ...otherProps }) {
    const logoSize = calculateLogoSize(children?.length);

    // Include logo size with each child
    const logos = React.Children.map(children, child => ({
        ...child,
        props: {
            ...child?.props,
            attrs: {
                ...child?.props?.attrs,
                size: logoSize,
            },
        },
    }));

    return (
        <LogoBlockContainer.Container
            {...otherProps}
        >
            <HeadingBlock
                {...heading}
            />

            <ul className="rh-display-flex rh-p-0">
                {logos}
            </ul>
        </LogoBlockContainer.Container>
    );
}

LogoBlockContainer.propTypes = {
    heading: HeadingPropTypes,
    children: PropTypes.any.isRequired,
};

LogoBlockContainer.defaultProps = {
    heading: undefined,
};

function calculateLogoSize(logoCount) {
    if (logoCount > 5) {
        return LOGO_SIZES.SMALL;
    }

    if (logoCount > 3) {
        return LOGO_SIZES.MEDIUM;
    }

    return LOGO_SIZES.LARGE;
}

const LOGO_SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

LogoBlockContainer.Container = styled.div`
    > ul {
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: ${Sizes.SPACING.TWO};
    }
`;

LogoBlockContainer.blockKey = 'rh/logo-block-container';
LogoBlockContainer.hasHeading = true;
LogoBlockContainer.requiresLayoutRow = true;

export default LogoBlockContainer;
