import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuid from 'uuid/v4';


import Config from '../definitions/Config';
import Locales from '../definitions/Locales';
import noticeError from '../functions/noticeError';
import mountGoogleIdentityService from '../functions/mountGoogleIdentityService';


function GoogleSignInButton({ locale, onSignIn, onLoad, ...otherProps }) {
    // in case we have multiple buttons, each one needs unique id
    const [ buttonId ] = useState(() => `google-sign-in-button-${uuid()}`);
    
    // NOTE TO READER: please think of this block (the useState + useEffect) like a callback.
    // Due to how the Google API works however, it would cause stale closure problems when the callback is executed.
    // Ideally we would just put onSignIn as dependency in the useEffect block we call the Google script, but
    // it would cause the google button to get re-rendered when onSignIn function gets updated
    // Instead, we store Google API response, call onSignIn here
    const [ response, setResponse ] = useState(null);
    useEffect(() => {
        if (response == null) {
            return;
        }
        if (response.credential == null) {
            noticeError(new Error('GoogleSignInButton: Google API did not return credential'), { response });
            return;
        }
        onSignIn(response.credential);
        // Now that we've raised the callback, clear it so it doesn't immediately call again if onSignIn is changed.
        setResponse(null);
    }, [ response, onSignIn ]);

    // Load Google Identity Service Script and let it render Google-Sign-In button
    useEffect(() => {
        mountGoogleIdentityService().then(isLoaded => {
            if (isLoaded) {
                // IMPORTANT NOTE about google.accounts.id.initialize / google.accounts.id.renderButton
                // According to Google API doc, You only call the google.accounts.id.initialize method once
                //   even if you use multiple modules in the same web page. Otherwise it would override
                //   the initialize method previously done. This means you can have only one chance
                //   to set callback function via initialize method
                // However, it's found that whenever renderButton method follows initialize method,
                //   We can set up the different callback each time - we rely on this behaviour for now
                // TAKEAWAY
                //   ex) initialize(callback X) > renderButton A > initialize(callback Y) > renderButton B
                //       - callback X is tied with button A
                //       - callback Y is tied with button B
                //   ex) initialize(callback X) > initialize(callback Y) > renderButton A > renderButton B
                //       - callback Y is tied with both button A and button B
                // CAUTION: We need to test thoroughly to ensure the right callback function is called by each button
                //   when two or more Google buttons are used on the same screen
                window.google.accounts.id.initialize({
                    client_id: Config.GOOGLE_SIGN_IN_CLIENT_ID,
                    nonce: buttonId,
                    // this function is responsible of handling RS256 token from google
                    // NOTE: ideally we'd just use a callback here, but read the useEffect block above this.
                    callback: resp => setResponse(resp),
                });
                window.google.accounts.id.renderButton(
                    document.getElementById(buttonId),
                    {
                        theme: 'outline',
                        size: 'large', // Google API also supports 'medium', and 'small'
                        width: 200, // if you don't set this, button's width will change while it's being loaded
                        locale,
                    },
                );

                // Notify our parents that we've loaded the button
                onLoad?.();
            }
        });
    }, []);

    return (
        <Container
            {...otherProps}
        >
            <div
                id={buttonId}
                className="google-sign-in-button"
            >
                {/* Google Script will render button here */}
            </div>
        </Container>
    );
}

GoogleSignInButton.propTypes = {
    locale: PropTypes.oneOf([ Locales.ENGLISH, Locales.FRENCH ]),
    onSignIn: PropTypes.func.isRequired,
    onLoad: PropTypes.func,
};

GoogleSignInButton.defaultProps = {
    locale: Locales.ENGLISH,
    onLoad: undefined,
};

const Container = styled.div`
    // Google API generates button inside iframe always with height 42px
    // This will hold 42px while it is being rendered
    > .google-sign-in-button {
        height: 42px;
    }
`;

export default GoogleSignInButton;
