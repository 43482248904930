import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { merge } from 'lodash';

import {
    CircleSpinner,
    ZIndex,
    Highchart,
    Formats,
    getDeepClone,
    AnimatedContainer,
    ANIMATION_TYPES,
    X_AXIS_TYPES,
} from '@ratehub/base-ui';

import fetchChartSeries from '../functions/fetchChartSeries';


const DEFAULT_X_AXIS_OPTIONS = Object.freeze({
    xStart: undefined,
    xEnd: undefined,
    xTitle: '',
    xTitlePosition: 'middle',
    xFormat: X_AXIS_TYPES.DATETIME,
});

const DEFAULT_Y_AXIS_OPTIONS = Object.freeze({
    yTitle: undefined,
    yFormat: 'percent',
    hasSecondaryYAxis: false,
    y2Title: undefined,
    y2Format: 'percent',
});

function Chart({
    title = undefined,
    titleVariant = TITLE_STYLE_OPTIONS.BLUEBERRY_DARKEST,
    height = HEIGHT_OPTIONS.MEDIUM,
    type = 'line',
    footnote = undefined,
    series,
    yAxisOptions = DEFAULT_Y_AXIS_OPTIONS,
    xAxisOptions = DEFAULT_X_AXIS_OPTIONS,
    className = '',
}) {
    const [ chartSeriesProps, setChartSeriesProps ] = useState(undefined);
    const [ fetchStatus, setFetchStatus ] = useState('initialized');
    const intl = useIntl();
    const language = intl.locale.substring(0, 2);

    useEffect(() => {
        setFetchStatus(FETCH_STATUS.PENDING);

        fetchChartSeries({
            series,
            xStart: xAxisOptions?.xStart,
            xEnd: xAxisOptions?.xEnd,
            xFormat: xAxisOptions.xFormat,
            language,
        })
            .then(data => {
                setChartSeriesProps(data);
                setFetchStatus(FETCH_STATUS.SUCCESS);
            })
            .catch(() => {
                setFetchStatus(FETCH_STATUS.ERROR);
            });
    }, [
        JSON.stringify(series),
        xAxisOptions?.xStart,
        xAxisOptions?.xEnd,
        xAxisOptions.xFormat,
    ]);

    const mergedXOptions = merge(getDeepClone(DEFAULT_X_AXIS_OPTIONS), xAxisOptions);
    const mergedYOptions = merge(getDeepClone(DEFAULT_Y_AXIS_OPTIONS), yAxisOptions);

    const chartProps = {
        chart: {
            type,
            height: HEIGHT_MAP[height],
        },
        series: chartSeriesProps,
        yAxis: buildYAxisProps(mergedYOptions, intl),
        xAxis: buildXAxisProps(mergedXOptions),
        tooltip: buildTooltipProps({
            xOptions: mergedXOptions,
            yOptions: mergedYOptions,
            intl,
        }),
    };

    return (
        <div
            className={classNames(
                'rh-flex-grow-1 rh-min-width-0 rh-bg-coconut',
                className,
                { 'rh-pt-2_5': !title }
            )}
        >
            <If condition={title}>
                <h2
                    data-test-name="chart-title"
                    className={classNames(
                        'chart-title rh-text-l weight-medium rh-my-0 rh-py-1 rh-text-align-center',
                        TITLE_STYLES[titleVariant]
                    )}
                >
                    {title}
                </h2>
            </If>
            <div
                className={classNames(
                    'chart-container rh-position-relative rh-border-width-1px rh-border-style-solid rh-border-color-stone-light rh-border-radius-10px rh-pt-2_5',
                    { 'has-title' : title },
                )}
            >
                <Choose>
                    <When condition={fetchStatus === FETCH_STATUS.SUCCESS}>
                        <AnimatedContainer
                            data-test-name="highchart-component"
                            className="animated-container rh-pb-1 rh-px-1_5"
                            type={ANIMATION_TYPES.FADE}
                            withColour={false}
                            isVisible={fetchStatus === FETCH_STATUS.SUCCESS}
                            alwaysRenderChildMarkup
                        >
                            <Highchart
                                {...chartProps}
                            />
                            <If condition={footnote}>
                                <p
                                    className="rh-text-2s rh-text-align-center rh-mt-1 rh-fg-stone-darkest rh-mx-8"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: footnote }}
                                />
                            </If>
                        </AnimatedContainer>
                    </When>
                    <When condition={fetchStatus === FETCH_STATUS.ERROR}>
                        <p className="rh-text-m rh-text-align-center">
                            <FormattedMessage
                                id="base-ui.components.chart.error"
                                defaultMessage="Failed to load chart."
                            />
                        </p>
                    </When>
                    <Otherwise>
                        <CircleSpinner
                            position="absolute"
                            className="rh-bg-transparent"
                            zIndex={ZIndex.ELEMENTS}
                        />
                    </Otherwise>
                </Choose>
            </div>
        </div>
    );
}

function adjustValueFormat(value, format) {
    // TODO: The idea behind this function would be to adjust future values here, if needed, due to inconsistencies in CSV data returned from API
    // For now, we only need to adjust the value if it's a percent format
    const isPercentFormat = format?.includes('percent') || false;

    return isPercentFormat
        ? value >= 1 ? value / 100 : value * 100 // possible inconsistencies in CSV data returned from API
        : value;

}

function buildTooltipProps({ xOptions, yOptions, intl }) {
    const {
        xFormat,
    } = xOptions;

    const {
        yFormat,
        y2Format,
    } = yOptions;

    return {
        formatter: function() {
            const xValue = xFormat === X_AXIS_TYPES.DATETIME ? Highcharts.dateFormat('%b %e, %Y', this.x) : this.x;

            const seriesFormat = this?.series?.options?.yAxis === 1 ? y2Format : yFormat;
            const yValue = adjustValueFormat(this.y, seriesFormat);

            return `
                <div class="layout-detailed rh-text-align-center">
                    <div class="text-row rh-display-flex rh-align-items-center rh-text-2xs rh-fg-stone-darkest rh-mb-0_75">
                        <span class="rh-display-line-block rh-border-radius-50p" style="background-color:${this.color};width:10px;height:10px;">&nbsp;</span>
                        <span class="rh-ml-0_25">${this.series.name}</span>
                    </div>
                    <div class="text-row">${xValue}</div>
                    <div class="text-row weight-medium">${intl.formatNumber(yValue, Formats.number[seriesFormat])}</div>
                </div>
            `;
        },
    };
}

function buildXAxisProps(options) {
    const {
        xTitle,
        xTitlePosition,
        xFormat,
    } = options;

    return {
        ...(xTitle && {
            title: {
                text: xTitle,
                align: xTitlePosition,
                enabled: true,
            },
        }),
        type: xFormat,
    };
}

function buildYAxisProps(options, intl) {
    const {
        yTitle,
        yFormat,
        hasSecondaryYAxis,
        y2Title,
        y2Format,
    } = options;

    function labelFormatter(format) {
        return function() {
            return intl.formatNumber(
                adjustValueFormat(this.value, format),
                Formats.number[format]
            );
        };
    }

    function buildYAxis(title, format, opposite = false) {
        return ({
            ...(title && {
                title: {
                    text: title,
                    enabled: true,
                },
            }),
            labels: {
                formatter: labelFormatter(format),
            },
            ...(opposite && { opposite }),
        });
    }

    const yAxis1 = buildYAxis(yTitle, yFormat);
    const yAxis2 = hasSecondaryYAxis ? buildYAxis(y2Title, y2Format, true) : null;

    return hasSecondaryYAxis ? [ yAxis1, yAxis2 ] : yAxis1;
}

const FETCH_STATUS = {
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
};

const TITLE_STYLE_OPTIONS = {
    STONE_LIGHT: 'stone-light',
    BLUEBERRY_DARKEST: 'blueberry-darkest',
    BLACKBERRY: 'blackberry',
};

const TITLE_STYLES = {
    [TITLE_STYLE_OPTIONS.STONE_LIGHT]: 'rh-bg-stone-light rh-fg-blackberry',
    [TITLE_STYLE_OPTIONS.BLUEBERRY_DARKEST]: 'rh-bg-blueberry-darkest rh-fg-coconut',
    [TITLE_STYLE_OPTIONS.BLACKBERRY]: 'rh-bg-blackberry rh-fg-coconut',
};

const HEIGHT_OPTIONS = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const HEIGHT_MAP = {
    [HEIGHT_OPTIONS.SMALL]: '400',
    [HEIGHT_OPTIONS.MEDIUM]: '600',
    [HEIGHT_OPTIONS.LARGE]: '800',
};

Chart.propTypes = {
    title: PropTypes.string,
    titleVariant: PropTypes.string,
    height: PropTypes.string,
    type: PropTypes.string,
    footnote: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.shape({
        seriesChartType: PropTypes.string,
        name: PropTypes.string.isRequired,
        dataSource: PropTypes.string.isRequired,
    })).isRequired,
    yAxisOptions: PropTypes.shape({
        yTitle: PropTypes.string,
        yFormat: PropTypes.string,
        hasSecondaryYAxis: PropTypes.bool,
        y2Title: PropTypes.string,
        y2Format: PropTypes.string,
    }),
    xAxisOptions: PropTypes.shape({
        xTitle: PropTypes.string,
        xTitlePosition: PropTypes.string,
        xFormat: PropTypes.string,
        xStart: PropTypes.string.isRequired,
        xEnd: PropTypes.string,
    }),
    className: PropTypes.string,
};

Chart.blockKey = 'rh/chart';
Chart.requiresLayoutRow = true;

const StyledChart = styled(Chart)`

    .chart-title {
        border-radius: 10px 10px 0 0;
    }

    .chart-container {
        min-height: 400px;

        &.has-title {
            border-radius: 0 0 10px 10px;
            border-top: 0;
        }
    }
`;

export default StyledChart;
