import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DefaultIntlFormatValues, formatPercentage } from '@ratehub/base-ui';

import MESSAGES from '../definitions/Messages';
import getSpendingCategoryDescription from './getSpendingCategoryDescription';


/**
 * For a rewards card, return an object with a summary and a list of messages describing its reward earnings.
 *
 * @param {boolean} isEarningAsPercentage prefer percentages to points / $ for earning rate
 * @param {Object} earningsDescription
 * @returns {{summaryMessage: JSX.Element, summaryDetails: Array[{key: number, message: JSX.Element}]}}
 */
function getSummarizedEarnings(isEarningAsPercentage, earningsDescription) {
    const intl = useIntl();

    const rateValues = earningsDescription.map(entry => entry.rate);
    const lowestRate = Math.min(...rateValues);
    const highestRate = Math.max(...rateValues);

    // for each earningsDescription entry, iterate over its `types` array to form the description its `rate` applies to
    const summaryDetails = earningsDescription.map((entry, index) => {
        const earnRate = isEarningAsPercentage
            ? formatPercentage(entry.rate, intl, 0, 2)
            : intl.formatMessage(MESSAGES.pointsPerDollar, { multiplier: entry.rate, oneDollar: 1 });

        return {
            key: index,     // based on values from the CMS (certain to be static, so `index` is acceptable)
            message: (
                <FormattedMessage
                    id="cc.condensed.rewards.earn-rate-item"
                    defaultMessage="<strong>{earnRate}</strong> {categories}"
                    values={{
                        earnRate: earnRate,
                        categories: getSpendingCategoryDescription(entry.types, intl),
                        ...DefaultIntlFormatValues,
                    }}
                />
            ),
        };
    });

    return {
        summaryMessage: (
            <FormattedMessage
                id="cc.condensed.rewards.summary-headline"
                defaultMessage={`{isCashBack, select,
                            true {
                                {lowestRatePC}
                                {highestRate, select,
                                    0 {}
                                    other {– {highestRatePC}}
                                }
                                earn rate
                            }
                            other {
                                {lowestRate, plural, =1 {1pt} other {#pts}}
                                {highestRate, select,
                                    0 {}
                                    other {– {highestRate, plural, =1 {1pt} other {#pts}}}
                                }
                                / dollar earn rate
                            }
                        }`}
                values={{
                    lowestRatePC: formatPercentage(lowestRate, intl, getSignificantDecimals(lowestRate)),
                    highestRatePC: formatPercentage(highestRate, intl, getSignificantDecimals(highestRate)),
                    lowestRate: lowestRate,
                    highestRate: lowestRate === highestRate || earningsDescription.length === 1
                        ? 0
                        : highestRate,
                    isCashBack: isEarningAsPercentage,
                }}
            />),
        summaryDetails: summaryDetails,
    };
}


/**
 * Determines how many decimal points we want to show, so as not to exclude any significant digits.
 *
 * @param {number} rate
 * @param {number} [maxDecimals=2] the maximum number of decimals allowed when formatting
 * @returns {number}

 * @example getSignificantDecimals(1.5001)     // 2 • 1.5%
 * @example getSignificantDecimals(1.5001, 3)  // 3 • 1.5%
 * @example getSignificantDecimals(1.5001, 4)  // 4 • 1.5001%
 * @example getSignificantDecimals(2.0077)     // 2 • 2.01%
 */
function getSignificantDecimals(rate, maxDecimals = 2) {
    const decimals = String(rate).split('.')[1];

    return decimals
        ? Math.min(decimals.length, maxDecimals)
        : 0;
}


export default getSummarizedEarnings;
