import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import Locales from '../definitions/Locales';
import MessagePropType from '../definitions/MessagePropType';
import NumberInput from './NumberInput';


function CurrencyInput({
    id,
    name,
    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    decimalScale,
    allowNegative,

    label,
    placeholder,

    onCreateField,

    ...otherProps
}) {
    const intl = useIntl();
    const prefix = intl.locale === Locales.ENGLISH ? '$' : undefined;
    const suffix = intl.locale === Locales.FRENCH ? '$' : undefined;

    return (
        <NumberInput
            id={id}
            name={name}
            value={value}

            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}

            allowNegative={allowNegative}
            decimalScale={decimalScale}

            label={label}
            placeholder={placeholder ?? DEFAULT_PLACEHOLDER}
            prefix={prefix}
            suffix={suffix}

            onCreateField={onCreateField}

            {...otherProps}
        />
    );
}

const DEFAULT_PLACEHOLDER = (
    <FormattedMessage
        id="BaseUI.CurrencyInput.defaultPlaceholder"
        defaultMessage="$ Enter amount"
    />
);

CurrencyInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string, // legacy support; will be parsed to a number
    ]),

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    decimalScale: PropTypes.number,
    allowNegative: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    onCreateField: PropTypes.func,
};

CurrencyInput.defaultProps = {
    id: undefined,
    value: undefined,

    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,

    decimalScale: undefined,
    allowNegative: false,

    label: undefined,
    placeholder: undefined,

    onCreateField: undefined,
};

export default CurrencyInput;
