import { useState, useEffect } from 'react';

import { fetchUserCity, PROVINCE, getProvincePrincipalCity, useIsMounted } from '@ratehub/base-ui';

import { useIntl } from 'react-intl';
import enrichAllProductGroups from '../functions/enrichAllProductGroups';


const DEFAULT_PROVINCE = PROVINCE.ONTARIO;

export default function useFeaturedProducts({ verifyLocation, productGroups, hideCTAIfNotMonetized }) {
    const { locale } = useIntl();

    const getIsMounted = useIsMounted();
    const [ city, setCity ] = useState(() => getProvincePrincipalCity(DEFAULT_PROVINCE));
    const [ isLocationVerified, setIsLocationVerified ] = useState(!verifyLocation); // Default to true if we're not asked to verify location


    // If we're asked to verify location, fetch the user's location and refresh product groups if necessary
    useEffect(() => {
        if (!verifyLocation) {
            return;
        }

        fetchLocationAndProductGroups()
            .then((updatedCity) => {
                if (!getIsMounted()) {
                    return;
                }

                setCity(updatedCity);
                setIsLocationVerified(true);
            });
    }, [ verifyLocation ]);

    async function fetchLocationAndProductGroups() {
        let userCity;

        try {
            userCity = await fetchUserCity() ?? getProvincePrincipalCity(DEFAULT_PROVINCE);
        } catch {
            // Error noticed within fetchUserCity
            userCity = getProvincePrincipalCity(DEFAULT_PROVINCE);
        }

        // only re-fetch and enrich if province changes
        if (userCity.province.code !== city.province.code) {
            // NOTE: This does a mutation to productGroups which is
            //  why this isn't returned or assigned to any variables
            try {
                await enrichAllProductGroups(productGroups, {
                    locale,
                    province: userCity.province.code,
                    hideCTAIfNotMonetized,
                });
            } catch {
                // Error noticed within enrichAllProductGroups
            }
        }

        return userCity;
    }

    return {
        // loading state
        isLocationVerified,

        // data
        city,
        get provinceCode() {
            return this.city.province.code;
        },
    };
}

