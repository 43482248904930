export const InsLifeWizardFormTypes = Object.freeze({
    STANDARD: 'standard',
    DISABILITY: 'disability',
    GROUP: 'group',
});

// If any more types are added in the future, we'll need to add it ins-common/definitions/LifeInsuranceTypeMessages
export const InsLifeWizardInsuranceTypes = Object.freeze({
    TERM: 'term',
    WHOLE: 'whole',
    UNIVERSAL: 'universal',
    CRITICAL: 'critical',
    FUNERAL: 'funeral',
    MORTGAGE: 'mortgage',
    JOINT_LIFE: 'joint-life',
    RENEWABLE: 'renewable',
    SENIOR: 'senior',
    DISABILITY: 'disability',
    GROUP: 'group',
});

export const InsuranceTypesMappedToFormTypes = {
    [InsLifeWizardFormTypes.STANDARD]: [
        InsLifeWizardInsuranceTypes.TERM,
        InsLifeWizardInsuranceTypes.WHOLE,
        InsLifeWizardInsuranceTypes.UNIVERSAL,
        InsLifeWizardInsuranceTypes.CRITICAL,
        InsLifeWizardInsuranceTypes.FUNERAL,
        InsLifeWizardInsuranceTypes.MORTGAGE,
        InsLifeWizardInsuranceTypes.JOINT_LIFE,
        InsLifeWizardInsuranceTypes.RENEWABLE,
        InsLifeWizardInsuranceTypes.SENIOR,
    ],
    [InsLifeWizardFormTypes.DISABILITY]: [
        InsLifeWizardInsuranceTypes.DISABILITY,
    ],
    [InsLifeWizardFormTypes.GROUP]: [
        InsLifeWizardInsuranceTypes.GROUP,
    ],
};

// This default export is required for the legacy life wizard
export default InsLifeWizardFormTypes;
