import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import {
    Colours,
    Caption,
    Sizes,
    RemoveDefaultButtonStyles,
    ZIndex,
    IconChooser,
    FullScreenTakeover,
    MessagePropType,
    IconChevronDown,
    Typography,
    AnimatedFadeContainer,
    MAGIC_LINK_VARIANTS,
    useSession,
} from '@ratehub/base-ui';

import HeaderUserAccountMenuDropdown from './HeaderUserAccountMenuDropdown';
import HeaderUserAccountMenuLogin from './HeaderUserAccountMenuLogin';
import { TRANSITION_TIMING_LONG, TRANSITION_EASING_EASE } from './HeaderUserAccountMenuDropdown';


const ACCOUNTS_MENU_ID = 'accountsDropdownMenu';
const MIN_WIDTH_BREAKPOINT = '(min-width: 1023px)';

function HeaderUserAccountMenu({
    items,
    requiresLogin,
    magicLinkVariant,
    resumeApplicationLink,
    loginDocumentOptions,
}) {
    const intl = useIntl();
    const sessionStore = useSession();
    const [ isNarrow, setIsNarrow ] = useState(false);
    const [ isDropDownOpen, setIsDropDownOpen ] = useState(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia(MIN_WIDTH_BREAKPOINT);

        function resizeHandler(e) {
            setIsNarrow(!e.matches);
        }

        setIsNarrow(!mediaQueryList.matches); // set initial state on first load
        mediaQueryList.addListener(resizeHandler); // watch for future changes

        return () => mediaQueryList.removeListener(resizeHandler);
    }, []);

    useEffect(
        () => {
            sessionStore.fetchSession();
        },
        [ sessionStore ],
    );

    function handleClick() {
        setIsDropDownOpen(true);
    }

    // Close the menu when the user clicks off of the dropdown.
    function handleBlur(e) {
        const relatedTarget = e.relatedTarget || document.activeElement; // IE11

        if (!isNarrow) {
            // data-dropdownlink is expected to be on dropdown menu items
            if (!relatedTarget || relatedTarget && !relatedTarget.dataset.dropdownlink) {
                setIsDropDownOpen(false);
            }
        }
    }

    const userGreeting = intl.formatMessage(Messages.USER_GREETING_MESSAGE, {
        hasUserFirstName: !!sessionStore.profile.firstName,
        userFirstName: sessionStore.profile.firstName,
    });

    const DropdownContainer = isNarrow
        ? FullScreenTakeover
        : React.Fragment;

    const dropdownContainerProps = isNarrow
        ? {
            isOpen: isDropDownOpen,
            onClose: () => setIsDropDownOpen(!isDropDownOpen),
            showBranding: true,
            screenReaderLabel: Messages.ACCOUNT_MENU_ACCESSIBILE_LABEL,
            verticalAlignment: 'flex-start',
        }
        : undefined;

    return (
        <Container
            isDropDownOpen={isDropDownOpen}

            isVisible={sessionStore.hasFetchedSession}
            alwaysRenderChildMarkup={true}
            withColour={false}
        >
            <Choose>
                <When condition={sessionStore.isLoggedIn || sessionStore.isLoggingOut}>
                    <button
                        // initializeHeader.js looks for this ID to disable this
                        // button when the mobile menu is open.
                        id="userAccountButton"
                        className={classNames({
                            menuButton: true,
                            hidden: isDropDownOpen && isNarrow,
                        })}
                        aria-label={intl.formatMessage(Messages.ACCOUNT_BUTTON_ACCESSIBLE_LABEL)}
                        aria-controls={!isNarrow ? ACCOUNTS_MENU_ID : undefined}
                        onClick={handleClick}
                        onBlur={handleBlur}
                        type="button"
                    >
                        {/* USER AVATAR / SHORT NAME DISPLAY */}
                        <div
                            className="avatarContainer"
                        >
                            <Choose>
                                <When condition={sessionStore.shortName}>
                                    <Caption
                                        className="shortNameLabel"
                                        message={sessionStore.shortName}
                                    />
                                </When>
                                <Otherwise>
                                    <IconChooser
                                        iconKey="user"
                                        className="userIcon"
                                        size="XS"
                                        outlineWidth="0"
                                        stroke={Colours.BLUEBERRY_DARK}
                                        strokeWidth="3px"
                                    />
                                </Otherwise>
                            </Choose>
                        </div>

                        {/*
                            TODO: We actually want ChevronIconDown here, but
                            need to make it an official part of the base-ui icon
                            set before we export it from base-ui (it's currently
                            a "renegade" icon).

                            See https://ratehub.atlassian.net/browse/EVR-1380
                        */}
                        <IconChevronDown
                            width={Sizes.ICONS.S}
                            height={Sizes.ICONS.S}
                            outlineWidth="0"
                            strokeWidth="1px"
                            className="chevron"
                        />
                    </button>

                    <DropdownContainer {...dropdownContainerProps}>
                        <HeaderUserAccountMenuDropdown
                            id={ACCOUNTS_MENU_ID}
                            navItems={items}
                            isDropDownOpen={isDropDownOpen}
                            setIsDropDownOpen={setIsDropDownOpen}
                            requiresLogin={requiresLogin}
                            signOutText={intl.formatMessage(Messages.ACCOUNT_SIGN_OUT_LABEL)}
                            userGreetingText={userGreeting}
                        />
                    </DropdownContainer>
                </When>
                <Otherwise>
                    <HeaderUserAccountMenuLogin
                        modalOpen={isDropDownOpen}
                        requiresLogin={requiresLogin}
                        magicLinkVariant={magicLinkVariant}
                        resumeApplicationLink={resumeApplicationLink}
                        documentOptions={loginDocumentOptions}
                    />
                </Otherwise>
            </Choose>
        </Container>
    );
}

HeaderUserAccountMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        label: MessagePropType,
    })).isRequired,
    requiresLogin: PropTypes.bool,
    magicLinkVariant: PropTypes.oneOf(Object.values(MAGIC_LINK_VARIANTS)),
    resumeApplicationLink: PropTypes.string,
    loginDocumentOptions: PropTypes.shape({
        document: PropTypes.object,
        documentType: PropTypes.string,
        documentToken: PropTypes.string,
        documentRedirect: PropTypes.string,
    }),
};

HeaderUserAccountMenu.defaultProps = {
    requiresLogin: false,
    magicLinkVariant: undefined,
    resumeApplicationLink: undefined,
    loginDocumentOptions: undefined,
};

const Container = styled(AnimatedFadeContainer)`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    @media ${MIN_WIDTH_BREAKPOINT} {
        /* dropdown menu is positioned against this */
        position: relative;

        z-index: ${ZIndex.PRIMARY_NAVIGATION};
    }

    > .userGreeting {
        display: none;
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        margin-top: 0;

        @media ${MIN_WIDTH_BREAKPOINT} {
            display: block;
            font-size: ${Sizes.FONTS['2XS']};
            font-weight: ${Typography.WEIGHTS.REGULAR};
            margin-bottom: 0;
            margin-right: ${Sizes.SPACING.THREE_QUARTERS};
        }
    }

    > .menuButton {
        display: flex;
        align-items: center;
        height: 100%;

        ${RemoveDefaultButtonStyles};
        cursor: pointer;

        &.hidden {
            visibility: hidden;
        }

        @media ${MIN_WIDTH_BREAKPOINT} {
            visibility: visible;
        }

        &:hover,
        &:focus {
            > .avatarContainer {
                > .shortNameLabel {
                    color: ${Colours.BLUEBERRY};
                }

                > .userIcon {
                    stroke: ${Colours.BLUEBERRY};
                }
            }
        }

        &:active {
            > .avatarContainer {
                > .shortNameLabel {
                    color: ${Colours.BLUEBERRY_DARKEST};
                }

                > .userIcon {
                    stroke: ${Colours.BLUEBERRY_DARKEST};
                }
            }
        }

        > .avatarContainer {
            display: flex;
            align-items: center;
            justify-content: center;

            width: ${Sizes.ICONS.S};
            height: ${Sizes.ICONS.S};

            border-radius: 50%;
            background-color: ${Colours.BLUEBERRY_LIGHTEST};

            > .shortNameLabel {
                margin-top: 0;
                margin-bottom: 0;

                text-transform: uppercase;
                color: ${Colours.BLUEBERRY_DARK};
            }

            > .userIcon {
                /* optically balance the icon */
                transform: translateY(-1px);
            }
        }

        > .chevron {
            transition: transform ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE};
            transform: ${props => props.isDropDownOpen ? 'rotate(180deg)': 'none'};
        }
    }
`;

const Messages = defineMessages({
    USER_GREETING_MESSAGE: {
        id: 'Accounts.header.userGreeting',
        defaultMessage: `{hasUserFirstName, select,
            true {Hi, {userFirstName}}
            other {Hi!}
        }`,
    },
    ACCOUNT_SIGN_OUT_LABEL: {
        id: 'Accounts.header.signOutLabel',
        defaultMessage: 'Sign out',
    },
    ACCOUNT_MENU_ACCESSIBILE_LABEL: {
        id: 'Accounts.header.accountMenuAccessibleLabel',
        defaultMessage: 'User Account menu',
    },
    ACCOUNT_BUTTON_ACCESSIBLE_LABEL: {
        id: 'Accounts.header.accountButtonAccessibleLabel',
        defaultMessage: 'User Account',
    },
});

export default observer(HeaderUserAccountMenu);
