import { trackHeapEvent } from '@ratehub/base-ui';


/**
 * 'CC redirect to partner'-specific call to `trackHeapEvent`.
 *
 * @param {Object} params - The parameters for the event.
 * @param {string} params.referrerURL - The URL of the referrer.
 * @param {string} params.affiliateId - The ID of the affiliate.
 * @param {Object} params.additionalInfo - Additional information for the event.
 * @param {Object} params.cardInfo - The credit card information.
 * @param {string} params.cardInfo.name - The name of the credit card.
 * @param {string} params.cardInfo.bank - The bank/provider the credit card.
 * @param {Object} params.cardInfo.giftCardOffer - The gift card offer for the credit card.
 * @param {string} params.cardInfo.detailsURL - The URL for the credit card details.
 * @param {boolean} params.cardInfo.isMonetized - Indicates whether the credit card is monetized.
 * @param {boolean} params.cardInfo.isSponsored - Indicates whether the credit card is sponsored.
 */
function trackHeapCCRedirectEvent({ referrerURL, affiliateId, additionalInfo, cardInfo }) {
    const { name, bank, giftCardOffer, detailsURL, isMonetized, isSponsored } = cardInfo;

    trackHeapEvent('CC redirect to partner', {
        cardName: name,
        provider: bank,
        href: detailsURL,
        monetized: isMonetized.toString(),
        sponsored: isSponsored.toString(),
        ...(referrerURL && { refererUrl: referrerURL }),
        ...(affiliateId && { affiliateId }),
        // Need to make sure only string values are passed to Heap
        // to simplify analytic tracking
        ...stringifyObjectValues(giftCardOffer),
        ...stringifyObjectValues(additionalInfo),
    });
}

/**
 * Converts all non-string values in an object to JSON strings for 1 depth.
 * Only 1 depth to avoid complex objects inside heap events.
 * e.g. { a: 1, b: '2', c: { d: 3 } } => { a: '1', b: '2', c: '{ "d": 3 }' } 
 * @param {Object} obj - The object to convert.
 * @returns {Object} A new object with all non-string values converted to strings.
 */
function stringifyObjectValues(obj) {
    if (!obj) {
        return {};
    }

    return Object.keys(obj).reduce((newObj, key) => {
        const objValue = obj[key];
        newObj[key] = typeof objValue === 'string' ? objValue : JSON.stringify(objValue);
        return newObj;
    }, {});
}


export default trackHeapCCRedirectEvent;
