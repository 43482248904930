import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    Sizes,
    Products,
    CircleSpinner,
} from '@ratehub/base-ui';

import HeadingPropTypes from '../definitions/HeadingPropTypes';
import useFeaturedProducts from '../hooks/useFeaturedProducts';
import isFeaturedProductApplicable from '../functions/isFeaturedProductApplicable';
import FeaturedProductGroup from './FeaturedProductGroup';
import HeadingBlock from './HeadingBlock';
import AdvertisingDisclosure from './AdvertisingDisclosure';


function FeaturedProductList({
    heading,

    href,
    hrefText,

    products,
    productType,

    verifyProvince,
    showAdDisclosure,
    hideCTAIfNotMonetized,
    className,

    ...otherProps
}) {
    const {
        isLocationVerified,
        provinceCode,
        city,
    } = useFeaturedProducts({
        // TODO: Hopefully we can one day refactor 'useFeaturedProducts' so that it doesn't need this shape
        productGroups: [ { type: productType, products: products } ],
        verifyLocation: verifyProvince,
        hideCTAIfNotMonetized,
    });
    // TODO: Does this need to be memo-ized?
    const shouldShowSpinner = useMemo(
        () => !isLocationVerified,
        [ isLocationVerified ],
    );


    return (
        <MyContainer
            className={classNames('rh-position-relative', className)}
            {...otherProps}
        >
            <HeadingBlock
                className="heading-block rh-mb-2"
                {...heading}
            />

            <If condition={showAdDisclosure && getProductTypeAllowsAdDisclosure(productType)}>
                <AdvertisingDisclosure
                    className="rh-display-flex rh-ml-auto rh-mb-1_5"
                    size="extra-small"
                />
            </If>

            <FeaturedProductGroup
                className="rh-mt-0_75"
                href={href}
                hrefText={hrefText}
                products={products.filter(product => isFeaturedProductApplicable(product, provinceCode))}
                city={city}
                hideCTAIfNotMonetized={hideCTAIfNotMonetized}
            />

            <If condition={shouldShowSpinner}>
                <CircleSpinner />
            </If>
        </MyContainer>
    );
}

FeaturedProductList.propTypes = {
    heading: HeadingPropTypes,

    href: PropTypes.string,
    hrefText: PropTypes.string,

    products: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        isSponsored: PropTypes.bool,

        applyHref: PropTypes.string,
        applyText: PropTypes.string,
        imageSrc: PropTypes.string,
        imageAlt: PropTypes.string,

        rate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.object,
        ]),
        description: PropTypes.string,
    })),
    productType: PropTypes.oneOf(Object.values(Products)).isRequired,

    verifyProvince: PropTypes.bool,
    showAdDisclosure: PropTypes.bool,
    hideCTAIfNotMonetized: PropTypes.bool,      // this prop is only relevant to credit cards at the moment

    className: PropTypes.string,
};

FeaturedProductList.defaultProps = {
    heading: undefined,
    href: undefined,
    hrefText: undefined,
    products: [],
    verifyProvince: true,
    showAdDisclosure: true,
    hideCTAIfNotMonetized: false,
    className: undefined,
};


/**
 * Initially, this intends to prevent showing the advertising disclosure for EDB products.
 * @param {string} productType
 * @return {boolean}
 */
function getProductTypeAllowsAdDisclosure(productType) {
    return ![
        Products.GICS,
        Products.CRYPTO,
        Products.SAVINGS,
        Products.CHEQUING,
        Products.CREDIT_CARDS,
        Products.ROBO_ADVISORS,
    ].includes(productType);
}

const MIN_WIDTH_BREAKPOINT = '(min-width: 62.5em)';

const MyContainer = styled.div`
    > .heading-block {
        @media ${MIN_WIDTH_BREAKPOINT} {
            margin-bottom: ${Sizes.SPACING.THREE};
        }
    }
`;

FeaturedProductList.blockKey = 'rh/featured-product-list';
FeaturedProductList.hasHeading = true;
FeaturedProductList.requiresLayoutRow = true;

export default FeaturedProductList;
