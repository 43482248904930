import { useState } from 'react';
import { observable } from 'mobx';

import { navigateWindowTo, noticeError } from '@ratehub/base-ui';
import { SQUARE_ONE_ID } from '@ratehub/ins-common';

import getInsuranceRedirectUrl from '../functions/getInsuranceRedirectUrl';


function useHomeInsuranceQuoteLauncher(providerRedirects) {
    const [ self ] = useState(() => observable({
        redirectURL: null,
        doNotService: false,
        
        isSquareOneWidgetVisible: false,
        _hasLoggedSquareOneConversion: false,

        handleFieldChange({ insuranceTypeField, provinceField }) {
            // If we were previously showing the SQ1 widget, stop showing it.
            self.isSquareOneWidgetVisible = false;

            // This is to catch the use case if the application changes the value of INSURANCE_TYPES picklist values.
            if (insuranceTypeField && !providerRedirects[insuranceTypeField]?.length) {
                noticeError(new RangeError(`Missing provider redirect information for ${insuranceTypeField}.`));
                return;
            }

            if (provinceField && insuranceTypeField) {
                const url = getInsuranceRedirectUrl(providerRedirects[insuranceTypeField], provinceField);

                // We want to evaluate doNotService on change for a nicer UX
                //  This way users are aware before they even click submit
                self.doNotService = !url;
                self.redirectURL = url ?? null;
            }
        },

        handleSubmit() {
            if (self.doNotService) {
                return;
            }

            // SPECIAL CASE: instead of redirecting to SQ1 we show their widget
            if (self.redirectURL === SQUARE_ONE_ID) {
                self.isSquareOneWidgetVisible = true;

                // Log SQ1 conversion
                // SQ1 is somewhat unique in that we use google analytics to track the conversion.
                //  Because this happens so early on in the funnel theres no real user data or payload
                //  involved. So all we can really track is that we redirected someone to their site.
                // NOTE: don't do this more than once.
                if (!self._hasLoggedSquareOneConversion) {
                    const googleTagManagerValues = {
                        event: 'insurance-conversion',
                        eventCategory: 'insurance',
                        eventAction: 'home',
                        eventLabel: window.location.href,
                    };

                    // Push the event into Google Tag Manager.
                    if (window.dataLayer?.push) {
                        // dataLayer is loaded via global script
                        // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
                        dataLayer.push(googleTagManagerValues);
                    }

                    // We also need to push this event into Google Analytics
                    if (typeof ga === 'function') {
                        // ga (Google Analytics) is loaded via global script
                        // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
                        ga('send', {
                            ...googleTagManagerValues,
                            hitType: 'event',
                        });
                    }

                    // Do not log this more than once.
                    self._hasLoggedSquareOneConversion = true;
                }
            } else if (self.redirectURL) {
                navigateWindowTo(self.redirectURL);
            }
        },
    }));

    return self;
}

export default useHomeInsuranceQuoteLauncher;
