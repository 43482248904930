import { isObservableArray } from 'mobx';

import { isAlive } from 'mobx-state-tree';
import messageToString from './messageToString';


function formatSelectOptions({ options, optionsSort, renderOptionLabel, intl }) {
    const formattedOptions = formatOptions({ options, optionsSort, renderOptionLabel, intl });
    const flatOptions = formattedOptions.reduce(flattenOptions, []);

    return {
        formattedOptions,
        flatOptions,
    };
}

/* HELPERS */

// Option formatting helpers
function formatOptions({ options, optionsSort, renderOptionLabel, intl }) {
    // Re-map options to our value, label format
    const newOptions = options.slice().map(option => {
        // If the option object has an array of nested
        // options instead of value, it's an option group,
        // so we re-map all the nested options
        if (option.options) {
            return {
                label: formatLabel(option.label, renderOptionLabel, intl),
                // Using isAlive here cause this was causing MST errors when a component unmounts while this is being
                //  evaluated. This is directly due to ChoiceField and MST.
                options: !isObservableArray(option.options) || isAlive(option.options)
                    ? option.options.slice().map(
                        innerOption => innerOption && formatFlatOption(innerOption, renderOptionLabel, intl),
                    )
                    : [],
            };
        }

        return formatFlatOption(option, renderOptionLabel, intl);
    });

    // Sort AFTER we translate the label
    if (optionsSort) {
        newOptions.sort(optionsSort);
    }

    return newOptions;
}

function formatFlatOption(option, renderOptionLabel, intl) {
    // Option might just be a single value instead of an object
    const value = option?.value ?? option;

    return {
        value: value,
        label: formatLabel(option.label ?? value, renderOptionLabel, intl),
        ...(option?.disabled && { disabled: true }),
    };
}

function formatLabel(label, renderOptionLabel, intl) {
    return messageToString(renderOptionLabel?.(label) ?? label, intl);
}

// Flatten reducer
function flattenOptions(options, current) {
    if (current.options) {
        return current.options.reduce(flattenOptions, options);
    }

    options.push(current);
    return options;
}


export default formatSelectOptions;