import { PROVINCE, Config } from '@ratehub/base-ui';

import INSURANCE_TYPES from './InsuranceTypes';


const RATEHUB_HOME_INSURANCE_REDIRECT_URL = '/insurance/home-quotes';

const SQUARE_ONE_ID = 'square-one';
const ALLSET_ID = 'allset';
const APPOLO_ID = 'apollo-insurance';
const RH_INSURANCE_ID = 'rh';
const CHEEP_ID = 'cheep-insurance';
const NUERA_ID = 'nuera';
const LSM_ID = 'lsm';
const LIONS_PEAK_ID = Config.ENABLE_NEW_LIONS_PEAK_KEY ? 'lions-peak' : 'lionsPeak';
const TORRUS_ID = 'torrus';

const HOME_PROVIDERS = [
    {
        id: RH_INSURANCE_ID,
        redirectUrl: RATEHUB_HOME_INSURANCE_REDIRECT_URL,
        regions: {
            [PROVINCE.ONTARIO]: 100,
        },
    },
    {
        id: SQUARE_ONE_ID,
        redirectUrl: SQUARE_ONE_ID,     // they use a special widget; our code looks for this ID as the URL.
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 60,
            [PROVINCE.ALBERTA]: 50,
            [PROVINCE.MANITOBA]: 100,
            [PROVINCE.SASKATCHEWAN]: 100,
        },
    },
    {
        id: CHEEP_ID,
        redirectUrl: Config.CHEEP_INSURANCE_HOME_REDIRECT_URL,
        regions: {
            [PROVINCE.NEWFOUNDLAND_AND_LABRADOR]: 100,
            [PROVINCE.NEW_BRUNSWICK]: 100,
            [PROVINCE.NOVA_SCOTIA]: 100,
        },
    },
    {
        id: NUERA_ID,
        redirectUrl: Config.NUERA_INSURANCE_REDIRECT_URL,
        regions: {
            [PROVINCE.ALBERTA]: 50,
        },
    },
    {
        id: APPOLO_ID,
        redirectUrl: Config.APOLLO_HOME_REDIRECT_URL,
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 40,
        },
    },
];

const TENANT_PROVIDERS = [
    {
        id: SQUARE_ONE_ID,
        redirectUrl: SQUARE_ONE_ID,     // they use a special widget; our code looks for this ID as the URL.
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 70,
            [PROVINCE.ALBERTA]: 70,
            [PROVINCE.MANITOBA]: 70,
            [PROVINCE.SASKATCHEWAN]: 70,
            [PROVINCE.ONTARIO]: 70,
        },
    },
    {
        id: APPOLO_ID,
        redirectUrl: Config.APOLLO_TENANT_REDIRECT_URL,
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 30,
            [PROVINCE.ALBERTA]: 30,
            [PROVINCE.MANITOBA]: 30,
            [PROVINCE.SASKATCHEWAN]: 30,
            [PROVINCE.ONTARIO]: 30,
            [PROVINCE.NEW_BRUNSWICK]: 100,
            [PROVINCE.NOVA_SCOTIA]: 100,
            [PROVINCE.NEWFOUNDLAND_AND_LABRADOR]: 100,
            [PROVINCE.PRINCE_EDWARD_ISLAND]: 100,
            [PROVINCE.NUNAVUT]: 100,
            [PROVINCE.NORTHWEST_TERRITORIES]: 100,
            [PROVINCE.YUKON]: 100,
        },
    },
    {
        id: ALLSET_ID,
        redirectUrl: Config.ALLSET_INSURANCE_TENANT_REDIRECT_URL,
        regions: {
            [PROVINCE.QUEBEC]: 100,
        },
    },
];

const CONDO_PROVIDERS = [
    {
        id: CHEEP_ID,
        redirectUrl: Config.CHEEP_INSURANCE_CONDO_REDIRECT_URL,
        regions: {
            [PROVINCE.NEWFOUNDLAND_AND_LABRADOR]: 100,
            [PROVINCE.NEW_BRUNSWICK]: 100,
            [PROVINCE.NOVA_SCOTIA]: 100,
        },
    },
    {
        id: SQUARE_ONE_ID,
        redirectUrl: SQUARE_ONE_ID,     // they use a special widget; our code looks for this ID as the URL.
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 60,
            [PROVINCE.ALBERTA]: 100,
            [PROVINCE.MANITOBA]: 100,
            [PROVINCE.SASKATCHEWAN]: 100,
            [PROVINCE.ONTARIO]: 100,
        },
    },
    {
        id: ALLSET_ID,
        redirectUrl: Config.ALLSET_INSURANCE_CONDO_REDIRECT_URL,
        regions: {
            [PROVINCE.QUEBEC]: 100,
        },
    },
    {
        id: APPOLO_ID,
        redirectUrl: Config.APPOLO_CONDO_REDIRECT_URL,
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 40,
        },
    },
];

const LIFE_PROVIDERS = [
    {
        id: LSM_ID,
        regions: {
            [PROVINCE.ALBERTA]: 40,
            [PROVINCE.BRITISH_COLUMBIA]: 40,
            [PROVINCE.MANITOBA]: 40,
            [PROVINCE.SASKATCHEWAN]: 40,
            [PROVINCE.ONTARIO]: 40,
            [PROVINCE.NORTHWEST_TERRITORIES]: 100,
            [PROVINCE.PRINCE_EDWARD_ISLAND]: 100,
            [PROVINCE.QUEBEC]: 100,
            [PROVINCE.NEW_BRUNSWICK]: 100,
            [PROVINCE.NEWFOUNDLAND_AND_LABRADOR]: 100,
            [PROVINCE.NOVA_SCOTIA]: 100,
            [PROVINCE.NUNAVUT]: 100,
            [PROVINCE.YUKON]: 100,
        },
    },
    {
        // Forwards to CIS
        id: LIONS_PEAK_ID,
        regions: {
            [PROVINCE.BRITISH_COLUMBIA]: 60,
            [PROVINCE.ALBERTA]: 60,
            [PROVINCE.MANITOBA]: 60,
            [PROVINCE.SASKATCHEWAN]: 60,
            [PROVINCE.ONTARIO]: 60,
        },
    },
    {
        id: TORRUS_ID,
        regions: {
            [PROVINCE.QUEBEC]: 0,
        },
    },
];

const INSURANCE_PROVIDERS = {
    [INSURANCE_TYPES.HOME]: HOME_PROVIDERS,
    [INSURANCE_TYPES.CONDO]: CONDO_PROVIDERS,
    [INSURANCE_TYPES.TENANT]: TENANT_PROVIDERS,
    [INSURANCE_TYPES.LIFE]: LIFE_PROVIDERS,
};

export default INSURANCE_PROVIDERS;
export {
    SQUARE_ONE_ID,
    ALLSET_ID,
    APPOLO_ID,
    RH_INSURANCE_ID,
    CHEEP_ID,
    NUERA_ID,
    LSM_ID,
    LIONS_PEAK_ID,
    TORRUS_ID,
};
