import { Config, AFFILIATE_QUERY_PARAM_KEYS } from '@ratehub/base-ui';

import { Products } from '../definitions/Products';

/**
 * Enrich shared deposit fields for deposits product queues by assigning values from cmsEntry
 * or apiEntry to slot as appropriate to allow same product array format as other verticals
 * @param {object} cmsEntry
 * @param {object} apiEntry
 * @param {object} slot
 */
function enrichDepositsProduct(cmsEntry, apiEntry, slot) {
    if (!cmsEntry || !apiEntry) {
        throw new Error(
            `[enrichDepositsProduct] Both enrichment entries for deposits product expected. cmsEntry: ${JSON.stringify(cmsEntry, null, 4)}; apiEntry: ${JSON.stringify(apiEntry, null, 4)}`,
        );
    }

    Object.assign(slot, {
        id: apiEntry.id,
        title: cmsEntry.titleOverride || apiEntry.badgeTitle,
        isSponsored: cmsEntry.isSponsored || apiEntry.isSponsored,
        hasPromo: apiEntry.hasPromo,
        applyHref: getApplyHref(cmsEntry, apiEntry),
        applyText: cmsEntry.applyText,
        description: cmsEntry.description || apiEntry.badgeDescription,

        ...getProviderInformation(apiEntry),
        detailsHref: apiEntry.detailsURL || '',
        // isFeatured should always be passed as true from BE
        isMonetized: apiEntry.isFeatured,
        providerSlug: apiEntry?.provider?.slug || '',
    });
}

function getApplyHref(cmsEntry, apiEntry) {
    const cmsURL = cmsEntry.hrefOverride || cmsEntry.applyHref;
    // Crypto products are not fully set up, so we need to match the key in the CMS not from the API
    const apiURL = apiEntry.productType === Products.CRYPTO
        ? apiEntry.apply_url
        : apiEntry.applyURL;
    const cmsCustomApplySlug = cmsEntry.customApplySlug;


    if (!cmsURL && !apiURL) {
        throw new Error(`[enrichDepositsProduct] Product type ${cmsEntry.type} with id ${cmsEntry.id} is missing key information: ${JSON.stringify(cmsEntry, null, 4)}`);
    }

    if (Config.ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION || cmsCustomApplySlug) {
        if (cmsCustomApplySlug && apiURL) {
            return `${apiURL}${apiURL.includes('?') ? '&' : '?'}${AFFILIATE_QUERY_PARAM_KEYS.customApplySlug}=${cmsCustomApplySlug}`;

        // crypto is a skeleton vertical that can't support custom apply slugs
        } else if (apiEntry.productType === Products.CRYPTO && cmsURL && apiURL) {
            const encodedURL = typeof btoa === 'function'
                ? btoa(cmsURL)
                : new Buffer.from(cmsURL).toString('base64');

            // crypto doesn't have API or conversion support yet, so we use the generic /go route for redirects
            return `${apiURL}/go/${encodedURL}`;
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[enrichDepositsProduct] Product type “${cmsEntry.type}” with id “${cmsEntry.id}” is missing an apply URL: “${JSON.stringify(apiEntry, null, 4)}”`);
        }
    // TODO: Remove below and if check after ENABLE_CC_CUSTOM_APPLY_SLUG_MIGRATION migration is complete
    } else {
        /**
         * If a cmsURL is supplied, ensure it is encoded so that
         * when we append it as a query param, it is not malformed
         * due to it potentially containing its own query params.
        */
        if (cmsURL && apiURL) {
            const encodedURL = typeof btoa === 'function'
                ? btoa(cmsURL)
                : new Buffer.from(cmsURL).toString('base64');

            // crypto doesn't have API or conversion support yet, so we use the generic /go route for redirects
            return apiEntry.productType === Products.CRYPTO
                ? `${apiURL}/go/${encodedURL}`
                : `${apiURL}${apiURL.includes('?') ? '&' : '?'}goto_64=${encodedURL}`;
        } else if (apiURL) {
            return apiURL;
        } else {
            throw new Error(`[enrichDepositsProduct] Product type ${cmsEntry.type} with id ${cmsEntry.id} is missing key information from response: ${JSON.stringify(apiURL, null, 4)}`);
        }
    }
}

function getProviderInformation(apiEntry) {
    return {
        providerName: apiEntry?.provider.name,
        imageSrc: apiEntry?.provider?.logo,
        imageAlt: apiEntry?.provider?.name,
    };
}


export default enrichDepositsProduct;
