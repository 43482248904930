// TODO This is intended only as a temporary fix until the CMS supports making this explicit for each credit card.

function isEarningsAPercentageOverride(cardId) {
    return EARNINGS_AS_PERCENTAGE_OVERRIDE.includes(cardId);
}

// - credit card ids that request we show their earning summaries using "%" instead of "points/$1"
// - by default, store-credit cards use the latter, but these two Walmart cards are an exception
const EARNINGS_AS_PERCENTAGE_OVERRIDE = [
    'walmart-mastercard',
    'walmart-rewards-world-mastercard',
];


export default isEarningsAPercentageOverride;
