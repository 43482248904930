import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Colours, Sizes, Dot, RemoveDefaultButtonStyles } from '@ratehub/base-ui';


function TabControlsAsList({ labels, selectedIndex, onChangeSelectedIndex, ...otherProps }) {
    return (
        <Container
            {...otherProps}
        >
            <For
                each="text"
                index="index"
                of={labels}
            >
                <TabControlsAsList.ListItem
                    key={text}
                    isSelected={index === selectedIndex}
                >
                    <Dot
                        className="selected-dot"
                    />

                    <button
                        className={classNames('rh-title-l', 'tab-button', 'weight-regular', 'rh-my-0', 'rh-px-0')}
                        onClick={handleClick}
                        data-tabindex={index}
                        type="button"
                    >
                        {text}
                    </button>
                </TabControlsAsList.ListItem>
            </For>
        </Container>
    );

    function handleClick(e) {
        onChangeSelectedIndex(parseInt(e.target.dataset.tabindex));
    }
}

TabControlsAsList.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedIndex: PropTypes.number.isRequired,
    onChangeSelectedIndex: PropTypes.func.isRequired,
};


/* STYLE CONSTS */
const TRANSITION_TIMING_LONG = '0.4s';
const TRANSITION_TIMING_SHORT = '0.1s';
const TRANSITION_EASING_EASE = 'ease';

const Container = styled.ul`
    -ms-grid-column: 1;
    grid-column: 1;

    padding-left: 0;
    list-style: none;

    margin-top: 0;
    margin-bottom: 0;

    .selected-dot {
        display: inline-block;

        transform: scale(0) translateX(-1em);
        transition:
            transform ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
            width ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
            height ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE};
    }

    .tab-button {
        ${RemoveDefaultButtonStyles};
        border-width: 0;
        outline: none;
        text-align: left;

        background-color: ${Colours.TRANSPARENT};

        transition:
            transform ${TRANSITION_TIMING_LONG} ${TRANSITION_EASING_EASE},
            color ${TRANSITION_TIMING_SHORT} ${TRANSITION_EASING_EASE};

        /* This is a silly rule which we don't need because we are declaring a focus style below. For some reason
        this is how Firefox defines their default state for buttons */
        &::-moz-focus-inner {
            border-width: 0;
        }

        cursor: pointer;

        &:hover,
        &:focus {
            color: ${Colours.BLUEBERRY};
        }

        &:active {
            color: ${Colours.BLUEBERRY_DARK};
        }
    }
`;

TabControlsAsList.ListItem = styled.li`
    display: flex;
    align-items: center;

    cursor: pointer;

    margin-bottom: ${Sizes.SPACING.ONE};

    > .selected-dot {
        flex: 0 0 auto;
        width: 0;
        height: 0;

        ${props => props.isSelected && `
            width: ${Sizes.SPACING.ONE};
            height: ${Sizes.SPACING.ONE};
            transform: scale(.8);
        `}
    }

    > .tab-button {
        flex: 0 1 auto;
        ${props => props.isSelected && `
            transform: translateX(${Sizes.SPACING.ONE});
        `}
    }
`;


export default TabControlsAsList;
