import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { 
    usePageSettings, 
    BusinessUnits, 
    FullScreenTakeover, 
    RemoveDefaultButtonStyles, 
    Colours, 
    Sizes,
} from '@ratehub/base-ui';

import NewsletterIconLabel from './NewsletterIconLabel';
import SidebarNewsletterModalAll from './SidebarNewsletterModalAll';
import SidebarNewsletterModalMortgages from './SidebarNewsletterModalMortgages';


const CLOSE_DIALOG_TIMEOUT_MS = 3000;

function SidebarNewsletterSignUpSmall() {
    const { businessUnit } = usePageSettings();
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    function handleSignUpComplete() {
        // Close dialog after a short delay.
        setTimeout(() => {
            setIsModalOpen(false);
        }, CLOSE_DIALOG_TIMEOUT_MS);
    }

    return (
        <>
            <NewsletterSignUpButton
                type="button"
                onClick={() => setIsModalOpen(true)}
                aria-haspopup="dialog"
                aria-pressed={isModalOpen}
                data-name="sidebarNewsletterSignupSmall-open-modal"
            >
                <NewsletterIconLabel hasHoverState={true} />
            </NewsletterSignUpButton>

            <FullScreenTakeover
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                showBranding={false}
                screenReaderLabel={(
                    <FormattedMessage
                        id="web-components.sidebarNewsletterSignUpSmall.modalAccessibilityLabel"
                        defaultMessage="Newsletter sign up form"
                    />
                )}
                isContentFullWidth={true}
                displayMode="lightbox"
            >
                <Choose>
                    <When condition={businessUnit === BusinessUnits.MORTGAGES}>
                        <SidebarNewsletterModalMortgages onSignUpComplete={handleSignUpComplete} />
                    </When>
                    <Otherwise>
                        <SidebarNewsletterModalAll onSignUpComplete={handleSignUpComplete} />
                    </Otherwise>
                </Choose>
            </FullScreenTakeover>
        </>
    );
}

const NewsletterSignUpButton = styled.button`
    ${RemoveDefaultButtonStyles};

    margin: 0 auto;
    padding: ${Sizes.SPACING.ONE} ${Sizes.SPACING.THREE_QUARTERS};
    width: 100%;

    /* prevent browser styles from resetting font-size context in case any children use ems */
    font-size: inherit;

    transition: color 200ms ease-out;

    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        color: ${Colours.GRAPE_DARKEST};
    }
`;

export default SidebarNewsletterSignUpSmall;
