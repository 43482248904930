import { PROVINCE, submitConversionPayload } from '@ratehub/base-ui';

import createLeadConversionPayload from './createLeadConversionPayload';


/**
 * creates the credit card lead conversion payload and submits it, asynchronously
 *
 * @param {Object} card FE “cardInfo” structure for credit card data
 * @param {Object} lead basic, minimal lead information
 * @param {string} lead.email the lead’s email address
 * @param {Object} lead.giftCard the lead’s chosen gift card
 * @param {string} lead.language language abbreviation: en or fr
 * @param {string} [lead.creditScore] CardFinder slug for credit score
 * @param {string} [lead.income] yearly income dollar amount
 * @param {string} [lead.refererUrl] an override to the default refererUrl found in the header for tracking
 *
 * @return {Promise<void>}
 */
async function submitLeadConversion(card, lead) {
    try {
        // @TODO: use user's real location
        // default to Toronto for now since conversion service requires location parameters
        const location = {
            cityName: 'Toronto',
            cityID: '4190',
            provinceCode: PROVINCE.ONTARIO,
        };

        const payload = createLeadConversionPayload({
            card,
            lead,
            location,
            experiments: {},
        });

        await submitConversionPayload(payload);
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.error('[CC submitLeadConversion] error', ex);
    }
}

export default submitLeadConversion;
