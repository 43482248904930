import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';

import MessagePropType from '../definitions/MessagePropType';
import getSelectOptionByValue from '../functions/getSelectOptionByValue';
import SelectBase from './SelectBase';


function SelectDownshift({
    id,
    name,
    value,
    label,
    placeholder,

    formattedOptions,
    flatOptions,

    isDisabled,
    isInline,

    isInvalid,
    shouldShowInvalid,

    onChange,
    onFocus,
    onBlur,
    onKeyPress,

    className,
    inputClassName,

    ...otherProps
}) {
    const selectedOption = getSelectOptionByValue(formattedOptions, value);

    const {
        isOpen,
        toggleMenu,
        setHighlightedIndex,

        highlightedIndex,

        selectItem,
        selectedItem,

        getToggleButtonProps,
        getMenuProps,
        getItemProps,
    } = useSelect({
        id: id ?? name,
        inputId: id ?? name,
        initialSelectedItem: selectedOption,

        // We need to send the flat options since Downshift is index-based and option group titles cannot be selected.
        items: flatOptions,
        itemToString: (i) => i ? i.label : '',

        onSelectedItemChange: handleSelectedItemChange,
    });

    // This useEffect is needed for when value is changed programatically/after first render,
    //  Downshift needs to know that the selected item is changed so the states are aligned.
    useEffect(
        () => {
            if (value && selectedOption) {
                selectItem(selectedOption);
            }
            if (value !== selectedOption?.value) {
                selectItem(undefined);
            }
        },
        [ value, selectItem, selectedOption?.value, selectedOption?.label ],
    );

    // Event handlers
    function handleSelectedItemChange(selected) {
        const newValue = selected?.selectedItem?.value ?? null;

        onChange?.(newValue);
    }


    function handleSelectFocus(e) {
        onFocus && onFocus(e);
    }

    function handleSelectBlur(e) {
        /* When the menu is open, the focus will automatically move from the button
            to the menu. We don't want to call blur here since the focus is
            still within our select. */
        if (!isOpen) {
            onBlur && onBlur(e);
        }
    }

    function handleSelectKeyPress(e) {
        // If the user presses enter or space, toggle the menu and set the highlighted
        //  option to the first ioption
        if (e.key === 'Enter' || e.key === ' ') {
            toggleMenu();
            setHighlightedIndex(0);
        }

        onKeyPress?.(e);
    }

    function handleMenuBlur(e) {
        /* The menu will re-focus the button when a selection is made UNLESS the user forces a blur by
            clicking elsewhere on the screen. If this occurs, the button's blur would have
            already been called (to focus the menu on open) and so there is no other way to blur our SelectDownshift
            without requiring the menu's blur. */
        onBlur && onBlur(e);
    }

    return (
        <SelectBase
            className={className}

            isInline={isInline}
            isOpen={isOpen}

            options={addPropsToOptions(formattedOptions, getItemProps)}
            highlightedIndex={highlightedIndex}

            disabled={isDisabled}

            label={label}
            placeholder={placeholder}

            shouldShowInvalid={shouldShowInvalid}

            // Using the combobox as our toggle button so the user can interact with the chevron button or input
            comboboxProps={getToggleButtonProps({
                disabled: isDisabled,
            })}

            inputProps={({
                id: id ?? name,
                name,
                className: inputClassName,

                disabled: isDisabled,
                value: selectedItem?.label ?? '',

                readOnly: true,

                onBlur: handleSelectBlur,
                onFocus: handleSelectFocus,
                onKeyPress: handleSelectKeyPress,

                ...(
                    isInvalid
                        ? { 'data-invalid': 'true' }
                        : undefined
                ),

                ...otherProps,
            })}

            buttonProps={({
                disabled: isDisabled,
                tabIndex: -1, // We don't want users to interact with this button other than click
            })}

            menuProps={getMenuProps({
                onBlur: handleMenuBlur,
            })}
        />
    );
}

SelectDownshift.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: MessagePropType,
    placeholder: MessagePropType,

    formattedOptions: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any.isRequired,
        }),
        PropTypes.object, // mobx observable array
        PropTypes.array,
    ]).isRequired,
    flatOptions: PropTypes.oneOfType([
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.any.isRequired,
        }),
        PropTypes.object, // mobx observable array
        PropTypes.array,
    ]).isRequired,

    isDisabled: PropTypes.bool,
    isInline: PropTypes.bool,

    isInvalid: PropTypes.bool,
    shouldShowInvalid: PropTypes.bool,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func,

    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

SelectDownshift.defaultProps = {
    id: undefined,
    value: undefined,
    label: undefined,
    placeholder: undefined,

    isInvalid: false,
    shouldShowInvalid: false,

    isDisabled: false,
    isInline: false,

    onChange: undefined,
    onFocus: undefined,
    onBlur: undefined,
    onKeyPress: undefined,

    className: undefined,
    inputClassName: undefined,
};


function addPropsToOptions(options, getItemProps) {
    let i = 0;

    // We only want to apply the props to the selectable options
    //  and we need to determine their index without the option group titles
    //  to get these props from Downshift.
    return options.map(option => {
        if (option.options) {
            return {
                ...option,
                options: option.options.map(innerOption => {
                    const newInnerOption = {
                        ...innerOption,
                        index: i,
                        otherProps: { ...getItemProps({ item: innerOption, index: i, disabled: innerOption.disabled }) },
                    };

                    i++;
                    return newInnerOption;
                }),
            };
        }

        const newOption = {
            ...option,
            index: i,
            otherProps: { ...getItemProps({ item: option, index: i, disabled: option.disabled }) },
        };

        i++;
        return newOption;
    });
}

export default SelectDownshift;
