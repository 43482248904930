import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';

import {
    Sizes,
    messageToString,
} from '@ratehub/base-ui';

import NEWSLETTER_MESSAGES from '../definitions/NewsletterMessages';
import NewsletterSignUpInlineForm from './NewsletterSignUpInlineForm';
import Checklist from './Checklist';


const NEWSLETTER_IMAGE = {
    src: '/static/newsletter-thumb-en-all.jpg',
    width: 350,
    height: 920,
};

function SidebarNewsletterModalAll({ onSignUpComplete }) {
    const intl = useIntl();

    return (
        <Container data-name="SidebarNewsletterModalAll-modal">
            <h2 className="rh-title-2xl rh-my-0">
                <FormattedMessage
                    id="web-components.SidebarNewsletterModalAll.title"
                    defaultMessage="Join our weekly newsletter for tips and deals!"
                />
            </h2>

            <p className="rh-text-m rh-mb-1_5">
                <FormattedMessage
                    id="web-components.SidebarNewsletterModalAll.description"
                    defaultMessage="Sign up now for important finance tips, news and exclusive offers."
                />
            </p>

            <div className="flex-container">
                <img
                    className="newsletter-preview"
                    alt={messageToString(MESSAGES.NEWSLETTER_THUMBNAIL_ALT, intl)}
                    {...NEWSLETTER_IMAGE}
                />

                <div className="column">
                    <NewsletterSignUpInlineForm
                        inputLabel={(
                            <FormattedMessage
                                id="web-components.SidebarNewsletterModalAll.inputAccessibilityLabel"
                                defaultMessage="Email address"
                            />
                        )}

                        /* @TODO: Please revisit all analytics for Sidebar Newsletter in https://ratehub.atlassian.net/browse/FIT-3171 */
                        dataName="sidebar-modal-newsletter-sign-up-form-all"

                        disclaimer={NEWSLETTER_MESSAGES.DISCLAIMER}

                        onSignUpComplete={onSignUpComplete}

                        asyncButtonMessages={{
                            ready: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_READY,
                            'in-progress': NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_IN_PROGRESS,
                            complete: NEWSLETTER_MESSAGES.SIGN_UP_BUTTON_COMPLETE,
                        }}
                    />

                    <Checklist
                        className="rh-mt-1_25"
                        title={(
                            <FormattedMessage
                                id="web-components.SidebarNewsletterModalAll.listTitle"
                                defaultMessage="What you get in our weekly newsletter:"
                            />
                        )}
                        checkListMessages={getChecklistMessages(intl)}
                    />
                </div>
            </div>
        </Container>
    );
}

SidebarNewsletterModalAll.propTypes = {
    onSignUpComplete: PropTypes.func,
};

SidebarNewsletterModalAll.defaultProps = {
    onSignUpComplete: undefined,
};

const MEDIA_QUERY_MAX_WIDTH = '42em';

const PADDING_LARGE = Sizes.SPACING.TWO;
const PADDING_SMALL = Sizes.SPACING.ONE;

const Container = styled.div`
    padding-left: ${PADDING_LARGE};
    padding-right: ${PADDING_LARGE};
    padding-bottom: ${PADDING_LARGE};
    text-align: center;

    @media (max-width: ${MEDIA_QUERY_MAX_WIDTH}) {
        padding-left: ${PADDING_SMALL};
        padding-right: ${PADDING_SMALL};
        padding-bottom: ${PADDING_SMALL};
    }

    > .flex-container {
        display: flex;
        flex-wrap: wrap-reverse;
        gap: ${Sizes.SPACING.ONE_AND_A_HALF};
        justify-content: center;
        align-items: flex-end;

        .newsletter-preview {
            max-width: 10.5rem;
            height: auto;
        }

        .column {
            flex-basis: 19rem;
            flex-grow: 1;
        }
    }
`;

function getChecklistMessages(intl) {
    return [
        MESSAGES.CHECKLIST_LIST_ITEM_1,
        MESSAGES.CHECKLIST_LIST_ITEM_2,
        MESSAGES.CHECKLIST_LIST_ITEM_3,
        MESSAGES.CHECKLIST_LIST_ITEM_4,
    ].map(msg => intl.formatMessage(msg));
}

const MESSAGES = defineMessages({
    NEWSLETTER_THUMBNAIL_ALT: {
        id: 'web-components.SidebarNewsletterModalAll.newsletterThumbnailAlt',
        defaultMessage: 'Ratehub.ca newsletter',
    },

    CHECKLIST_LIST_ITEM_1: {
        id: 'web-components.SidebarNewsletterModalAll.listItem1',
        defaultMessage: 'Free personal finance tips on mortgages, credit cards, insurance, savings and investment',
    },
    CHECKLIST_LIST_ITEM_2: {
        id: 'web-components.SidebarNewsletterModalAll.listItem2',
        defaultMessage: 'The day’s best rates for mortgages, savings and investments',
    },
    CHECKLIST_LIST_ITEM_3: {
        id: 'web-components.SidebarNewsletterModalAll.listItem3',
        defaultMessage: 'Best credit card and sign-up bonuses',
    },
    CHECKLIST_LIST_ITEM_4: {
        id: 'web-components.SidebarNewsletterModalAll.listItem4',
        defaultMessage: 'Over 100,000 Canadians already subscribe to our weekly newsletter to make better financial decisions',
    },
});

export default SidebarNewsletterModalAll;
