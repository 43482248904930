import { getReferrerURL, getVisitorId } from '@ratehub/base-ui';


const PRODUCT_TYPE = 'CREDIT_CARD';

/**
 * Constructs the base credit card base payload to send to the conversion service
 * @return {Object}
 */
function createBaseConversionPayload({ card, location, language, experiments, refererUrl }) {
    return {
        productType: PRODUCT_TYPE,
        city: location.cityName,
        province: location.provinceCode,
        language: language,
        conversionSourceType: PRODUCT_TYPE,
        conversionSourceId: card.id,
        monetized: card.isMonetized,
        experiments: experiments,
        refererUrl: refererUrl ?? getReferrerURL() ?? undefined,
        visitor: getVisitorId() ?? '0',
    };
}

export default createBaseConversionPayload;
