import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessage, defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import queryString from 'query-string';
import classNames from 'classnames';
import { rgba } from 'polished';

import {
    Form,
    Sizes,
    Colours,
    isWidget,
    Checkbox,
    IconGift,
    IconArrow,
    EmailInput,
    StringInput,
    getTermsURL,
    PrimaryButton,
    useExperiment,
    CondensedTheme,
    messageToString,
    ExperimentSegments,
    getPrivacyPolicyURL,
    PageBehaviourButton,
    getLanguageFromLocale,
    DefaultIntlFormatValues,
    AFFILIATE_QUERY_PARAM_KEYS,
    FormattedListWithCheckmarks,
} from '@ratehub/base-ui';

import EXPERIMENTS from '../definitions/Experiments';
import PRODUCT_TYPES from '../definitions/ProductTypes';
import GiftCardOfferShape from '../definitions/GiftCardOfferShape';
import UserInfoCCPropTypes from '../definitions/UserInfoCCPropTypes';
import submitLeadConversion from '../functions/submitLeadConversion';
import isEarningsAPercentage from '../functions/isEarningsAPercentage';
import getSummarizedEarnings from '../functions/getSummarizedEarnings';


function CreditCardIncentive({
    cardInfo,
    onAcceptOrDecline,
    userInfo,
    referrerURL,
    className,
}) {
    const intl = useIntl();
    const isNotAWidget = !isWidget();

    const noThanksSegment = useExperiment(EXPERIMENTS.noThanks)?.segment;
    const noThanksONE = noThanksSegment === ExperimentSegments.VARIANT_1 && isNotAWidget;   // convenience consts for readability
    const noThanksTWO = noThanksSegment === ExperimentSegments.VARIANT_2 && isNotAWidget;
    const noThanksCONTROL = !noThanksONE && !noThanksTWO;

    const noThanksAltSegment = useExperiment(EXPERIMENTS.noThanksAlt)?.segment;
    const noThanksAltONE = noThanksAltSegment === ExperimentSegments.VARIANT_1 && isNotAWidget;   // convenience consts for readability
    const noThanksAltTWO = noThanksAltSegment === ExperimentSegments.VARIANT_2 && isNotAWidget;
    const noThanksAltCONTROL = !noThanksAltONE && !noThanksAltTWO;

    const isCompactIncentive = useExperiment(EXPERIMENTS.smallerIncentive)?.segment === ExperimentSegments.VARIANT_1
        && isNotAWidget;

    const offerAmount = cardInfo.giftCardOffer.maxDollarValue;
    const offerType = cardInfo.giftCardOffer.offerType;
    const offerHasExpirationDate = !!cardInfo.giftCardOffer?.expiryDate;

    // Reward cards will have a non-empty earningsDescription array
    const earnRateSummary = cardInfo.earningsDescription.length && !isCompactIncentive
        ? getSummarizedEarnings(
            isEarningsAPercentage({ cardId: cardInfo.id, cardProductType: cardInfo.productType }),
            cardInfo.earningsDescription
        ).summaryMessage
        : '';

    const hasAffiliateId = typeof window !== 'undefined'
        && queryString.parse(window.location.search)[AFFILIATE_QUERY_PARAM_KEYS.affiliateId];


    // As of now, only the first card in giftCardOffer.giftCards is considered.
    // The idea of multiple gift offers per card has fallen out of favour.
    const chosenGiftCard = cardInfo.giftCardOffer.giftCards[0];

    function handleAcceptGiftOffer(email, usersName) {
        // naive splitting of usersName into first and last; will not be perfect
        const matches = usersName.match(/(\w+)(\s(.+))?/);

        submitLeadConversion(cardInfo, {
            email: email,
            giftCard: chosenGiftCard,
            language: getLanguageFromLocale(intl.locale),
            creditScore: userInfo?.creditScore,
            income: userInfo?.income,
            firstName: matches[1],
            lastName: matches?.[3],     // support when surname-less "Drake" applies
            ...(referrerURL && { refererUrl: referrerURL }),
        });

        onAcceptOrDecline({ email, isOfferAccepted: true });
    }


    return (
        <Container className={classNames('rh-display-flex', 'rh-flex-direction-column', 'rh-align-items-center', className)}>
            <Choose>
                <When condition={isCompactIncentive}>
                    <SmallerDialogue
                        cardName={cardInfo.name}
                        providerName={cardInfo.providerName}
                        giftOffer={cardInfo.giftCardOffer}
                        onAcceptOrDecline={onAcceptOrDecline}
                        handleAcceptGiftOffer={handleAcceptGiftOffer}
                    />
                </When>

                <Otherwise>
                    <header className="rh-display-flex rh-align-items-center rh-mx-3 rh-mb-2">
                        <IconGift
                            className="the-icon rh-icon-s rh-mr-0_5 rh-flex-shrink-0"
                            outlineWidth="0"
                            stroke={Colours.GRAPE_DARK}
                            fill={Colours.GRAPE_LIGHT}
                        />

                        <h1 className="rh-title-2xl weight-bold rh-m-0 rh-text-align-center showDot">
                            <FormattedMessage
                                {...LOCAL_MESSAGES.mainTitle}
                                values={{
                                    offerAmount: offerAmount,
                                    offerType: offerType,
                                }}
                            />
                        </h1>
                    </header>

                    <div className="inner-container rh-mx-1 rh-mb-2 rh-p-1_5 rh-max-width-50 rh-border-radius-8px rh-bg-coconut">
                        <div className="incentive-header">
                            <div className="card-and-checkmarks rh-display-flex rh-flex-wrap rh-justify-content-center rh-gap-2">
                                <div className="card-image rh-display-flex rh-flex-direction-column rh-align-items-center">
                                    <If condition={cardInfo.giftCardOffer?.expiryDate}>
                                        <span
                                            className={classNames('rh-text-xs', 'weight-medium', 'rh-bg-yuzu', 'rh-mb-1', 'rh-py-0_125', 'rh-px-0_5',
                                                'rh-width-100p', 'rh-box-sizing-border-box', 'rh-text-align-center', 'rh-border-radius-10px')}
                                        >
                                            <FormattedMessage
                                                {...LOCAL_MESSAGES.offerEnds}
                                            />
                                            <time
                                                className="rh-display-inline-block"
                                                dateTime={cardInfo.giftCardOffer.expiryDate}
                                            >
                                                <FormattedDate
                                                    value={cardInfo.giftCardOffer.expiryDate}
                                                    year="numeric"
                                                    month="short"
                                                    day="numeric"
                                                />
                                            </time>
                                        </span>
                                    </If>

                                    <img
                                        src={cardInfo.cardImageURL}
                                        alt={cardInfo.name}
                                        width={CARD_WIDTH}
                                        height={CARD_HEIGHT}
                                    />
                                </div>

                                <div
                                    className={classNames('apply-and-checkmarks', {
                                        'rh-display-flex rh-align-items-center': noThanksAltTWO,
                                    })}
                                >
                                    <If condition={!noThanksAltTWO}>
                                        <h2 className="rh-title-s rh-mt-0 rh-mb-0_5">
                                            <FormattedMessage
                                                id="edb.cc.incentive.apply-for"
                                                defaultMessage="Apply for the {name} and get a {offerAmount, number, CAD0} {offerType}."
                                                values={{
                                                    name: cardInfo.name,
                                                    offerAmount: offerAmount,
                                                    offerType: offerType,
                                                }}
                                            />
                                        </h2>
                                    </If>

                                    <FormattedListWithCheckmarks
                                        className={classNames('rh-text-s', 'rh-mb-0', 'rh-pl-0', {
                                            'rh-mt-1': !(noThanksAltTWO && !offerHasExpirationDate),
                                            'rh-mt-0_5-negative': noThanksAltTWO && !offerHasExpirationDate,
                                        })}
                                        liClassName="rh-mt-1"
                                        message={defineMessage({
                                            id: 'edb.cc.incentive.card-summary',
                                            defaultMessage: `
                                                {earnRateSummary, select,
                                                    0 {}
                                                    undefined {}
                                                    other {<li><strong>Earn rewards:</strong> {earnRateSummary}</li>}
                                                }
                                                {signUpBonusPoints, select,
                                                    0 {}
                                                    undefined {}
                                                    other {<li><strong>Welcome bonus:</strong>
                                                        {isCashback, select,
                                                            true {{signUpBonusDollars, number, CAD0}}
                                                            other {{signUpBonusPoints, number} pts (a {signUpBonusDollars, number, CAD0} dollar value)}
                                                        }
                                                    </li>}
                                                }
                                                <li>
                                                    <strong>Annual fee:</strong> {isFirstYearFeeWaived, select,
                                                        true {<line-through>{annualFee, number, CAD0}</line-through>
                                                            <green>{zero, number, CAD0} first year</green>}
                                                        other {{annualFee, number, CAD0}}}
                                                </li>
                                            `,
                                        })}
                                        values={{
                                            earnRateSummary: earnRateSummary,
                                            isCashback: cardInfo.productType === PRODUCT_TYPES.CASH_BACK,
                                            signUpBonusPoints: cardInfo.signUpBonusPoints,
                                            signUpBonusDollars: cardInfo.signUpBonusDollars,
                                            isFirstYearFeeWaived: cardInfo.isFirstYearFeeWaived,
                                            annualFee: cardInfo.annualFee,
                                            green: str => <span key={str} className="rh-fg-lime">{str}</span>,  // eslint-disable-line
                                            zero: 0,
                                        }}
                                        data-test-name="incentive-checkmarks"
                                    />
                                </div>

                                <If condition={noThanksAltONE || noThanksAltTWO}>
                                    <NoThanksButton
                                        className="rh-mt-0 rh-mx-1"
                                        offerType={offerType}
                                        onAcceptOrDecline={onAcceptOrDecline}
                                    />
                                </If>
                            </div>

                            <p
                                className="rh-text-s rh-text-align-center rh-my-2"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: cardInfo.giftCardOffer.promoDetailsHTML,
                                }}
                            />
                        </div>

                        <div className="incentive-claim rh-display-flex rh-flex-direction-column rh-align-items-center rh-p-1_5 rh-bg-stone-lightest rh-border-radius-8px">
                            <h2 className="rh-title-s rh-mt-0 rh-mb-1">
                                <FormattedMessage
                                    {...LOCAL_MESSAGES.claimYourOffer}
                                />
                            </h2>
                            <p className="rh-text-s rh-m-0 rh-text-align-center weight-medium">
                                <FormattedMessage
                                    id="edb.cc.incentive.wait-for-completion"
                                    defaultMessage="To receive the {offerType}, do not leave the application page before it is completed."
                                    values={{
                                        offerType: offerType,
                                    }}
                                />
                            </p>

                            <Form
                                className="rh-display-flex rh-flex-wrap rh-mt-1"
                                onSubmit={(values) => handleAcceptGiftOffer(values.email, values.usersName)}
                            >
                                <NameAndEmailFields
                                    hasAffiliateId={hasAffiliateId}
                                    handleAcceptGiftOffer={handleAcceptGiftOffer}
                                />

                                <div
                                    className={classNames('button-wrapper', 'rh-display-flex', 'rh-flex-wrap', 'rh-width-100p',
                                        'rh-align-items-center', 'rh-justify-content-center', 'rh-gap-1_5', 'rh-mb-1', {
                                            'rh-flex-direction-column': !noThanksTWO,
                                        })}
                                >
                                    <If condition={noThanksTWO}>
                                        <NoThanksButton
                                            className="rh-mt-0 rh-mx-1"
                                            offerType={offerType}
                                            onAcceptOrDecline={onAcceptOrDecline}
                                        />
                                    </If>

                                    <PrimaryButton
                                        size="small"
                                        className="button-get-offer rh-mt-0 rh-mx-1"
                                        data-name="gift-card-offer-submit"
                                    >
                                        <FormattedMessage {...LOCAL_MESSAGES.getThisOffer} />
                                    </PrimaryButton>

                                    <If condition={noThanksONE}>
                                        <NoThanksButton
                                            offerType={offerType}
                                            onAcceptOrDecline={onAcceptOrDecline}
                                        />
                                    </If>
                                </div>
                            </Form>

                            <hr className="rh-horizontal-rule rh-width-100p rh-mb-1_5" />

                            <div className="trusted-by rh-display-flex rh-align-items-center">
                                <img
                                    src="https://cms.ratehub.ca/wp-content/uploads/2024/04/interac-black-and-white.png"
                                    alt={messageToString((
                                        <FormattedMessage
                                            id="edb.cc.incentive.interac-alt"
                                            defaultMessage="Interac logo in black and white"
                                        />
                                    ), intl)}
                                    width="32"
                                />

                                <img
                                    src="https://cms.ratehub.ca/wp-content/uploads/2024/04/amazon-dark-grey-461x141-1.png"
                                    alt={messageToString((
                                        <FormattedMessage
                                            id="edb.cc.incentive.amazon-alt"
                                            defaultMessage="Amazon logo in black and white"
                                        />
                                    ), intl)}
                                    height="20"
                                />

                                <img
                                    src="https://cms.ratehub.ca/wp-content/uploads/2024/04/indigo-dark-grey-150x80-1.png"
                                    alt={messageToString((
                                        <FormattedMessage
                                            id="edb.cc.incentive.indigo-alt"
                                            defaultMessage="Indigo logo in black and white"
                                        />
                                    ), intl)}
                                    height="20"
                                />

                                <img
                                    src="https://cms.ratehub.ca/wp-content/uploads/2024/04/cineplex-dark-grey-355x96-1.png"
                                    alt={messageToString((
                                        <FormattedMessage
                                            id="edb.cc.incentive.cineplex-alt"
                                            defaultMessage="Cineplex logo in black and white"
                                        />
                                    ), intl)}
                                    height="20"
                                />
                            </div>
                        </div>

                        <footer className="footer rh-display-flex rh-flex-direction-column rh-align-items-center">
                            <p className={classNames('rh-text-xs', 'rh-mt-2', {
                                'rh-mb-2': noThanksCONTROL && noThanksAltCONTROL,
                            })}
                            >

                                <FormattedMessage
                                    id="edb.cc.incentive.ratehub-terms"
                                    defaultMessage="By clicking above, you agree to Ratehub’s <privacy>Privacy Policy</privacy> and <terms>Terms and Conditions</terms>."
                                    values={{
                                        ...DefaultIntlFormatValues,
                                        /* eslint-disable react/jsx-one-expression-per-line, react/jsx-max-props-per-line */
                                        privacy: str => <a href={getPrivacyPolicyURL(intl.locale)} target="_blank">{str}</a>,
                                        terms: str => <a href={getTermsURL(intl.locale)} target="_blank">{str}</a>,
                                        /* eslint-enable react/jsx-one-expression-per-line, react/jsx-max-props-per-line */
                                    }}
                                />
                            </p>

                            <If condition={noThanksCONTROL && noThanksAltCONTROL}>
                                <NoThanksButton
                                    offerType={offerType}
                                    onAcceptOrDecline={onAcceptOrDecline}
                                />
                            </If>
                        </footer>
                    </div>
                </Otherwise>
            </Choose>
        </Container>
    );
}

CreditCardIncentive.propTypes = {
    cardInfo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        giftCardOffer: GiftCardOfferShape.isRequired,
        cardImageURL: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        providerName: PropTypes.string.isRequired,

        signUpBonusPoints: PropTypes.number,
        signUpBonusDollars: PropTypes.number,
        isFirstYearFeeWaived: PropTypes.bool.isRequired,
        annualFee: PropTypes.number.isRequired,
        productType: PropTypes.string.isRequired,
        earningsDescription: PropTypes.array.isRequired,
    }).isRequired,

    onAcceptOrDecline: PropTypes.func.isRequired,
    userInfo: UserInfoCCPropTypes,

    referrerURL: PropTypes.string,

    className: PropTypes.string,
};


function NameAndEmailFields({
    hasAffiliateId,
    isCompact = false,
}) {
    return (
        <CondensedTheme value={false}>
            <div
                className={classNames('two-up', 'rh-display-flex', 'rh-flex-wrap', 'rh-width-100p', {
                    // we will need more white space below this input field wrapper when the subscribe checkbox is omitted
                    'rh-mb-1_5': hasAffiliateId,
                })}
            >
                <StringInput
                    className="side-by-side"
                    name="usersName"
                    label={(
                        <FormattedMessage
                            id="edb.cc.incentive.usersName.label"
                            defaultMessage="Your name"
                        />
                    )}
                    defaultErrorMessage={(
                        <FormattedMessage
                            id="edb.cc.incentive.name-validation.empty"
                            defaultMessage="Please enter your name"
                        />
                    )}
                />

                <EmailInput
                    className="side-by-side"
                    name="email"
                    placeholder={''}
                    label={(
                        <FormattedMessage
                            id="edb.cc.incentive.email.label"
                            defaultMessage="Your email address"
                        />
                    )}
                />
            </div>

            <If condition={!hasAffiliateId}>
                <div
                    className={classNames('rh-display-flex', 'rh-align-items-center', 'rh-width-100p', {
                        'rh-my-1_5 rh-mx-0_5': !isCompact,
                        'rh-my-1': isCompact,
                    })}
                >
                    <Checkbox
                        className="checkbox-subscribe"
                        name="subscribe"
                        id="subscribe"
                        size={isCompact ? 'small' : undefined}
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label
                        className={classNames('rh-ml-1 rh-my-0', {
                            'rh-text-s': !isCompact,
                            'rh-text-xs': isCompact,
                        })}
                        htmlFor="subscribe"
                    >
                        <FormattedMessage
                            id="edb.cc.incentive.newsletters"
                            defaultMessage="I’d like to receive Ratehub credit card offers and newsletters. I may unsubscribe at any time."
                        />
                    </label>
                </div>
            </If>
        </CondensedTheme>
    );
}

NameAndEmailFields.propTypes = {
    hasAffiliateId: PropTypes.string,
    isCompact: PropTypes.bool,
};


function SmallerDialogue({
    cardName,
    providerName,
    giftOffer,
    hasAffiliateId,
    onAcceptOrDecline,
    handleAcceptGiftOffer,
}) {
    const offerAmount = giftOffer.maxDollarValue;
    const offerType = giftOffer.offerType;

    return (
        <div className="smaller-dialogue rh-p-1_25 rh-border-radius-8px rh-bg-coconut">
            <header>
                <PageBehaviourButton
                    onClick={() => onAcceptOrDecline({ isOfferAccepted: false })}
                    iconComponent={iconProps => (
                        <IconArrow
                            {...iconProps}
                            outlineWidth="0"
                            direction="right"
                        />
                    )}
                    message={(
                        <FormattedMessage
                            {...LOCAL_MESSAGES.applyWithout}
                            values={{ offerType: offerType }}
                        />
                    )}
                    size="extra-small"
                    className="rh-mb-1_5 rh-ml-auto"
                />

                <div className="header-box rh-p-1 rh-bg-grape-lightest rh-border-radius-8px">
                    <If condition={giftOffer?.expiryDate}>
                        <span
                            className={classNames('expiry-pill rh-position-absolute rh-display-inline-block rh-text-xs rh-px-0_5',
                                'rh-fg-coconut rh-bg-grape-dark rh-border-radius-8px')}
                        >
                            <FormattedMessage
                                {...LOCAL_MESSAGES.offerEnds}
                            />
                            <time
                                className="rh-display-inline-block"
                                dateTime={giftOffer.expiryDate}
                            >
                                <FormattedDate
                                    value={giftOffer.expiryDate}
                                    year="numeric"
                                    month="short"
                                    day="numeric"
                                />
                            </time>
                        </span>
                    </If>

                    <p className="rh-text-s rh-m-0 rh-mt-0_5 rh-mb-0_25">
                        {cardName}
                    </p>

                    <h2 className="rh-title-xs rh-fg-grape-dark rh-m-0">
                        <FormattedMessage
                            {...LOCAL_MESSAGES.mainTitle}
                            values={{
                                offerAmount: offerAmount,
                                offerType: offerType,
                            }}
                        />
                    </h2>
                </div>
            </header>

            <div className="smaller-innner-container rh-mt-1 rh-p-1">
                <h2 className="rh-title-xs rh-m-0 rh-mt-0_25">
                    <FormattedMessage {...LOCAL_MESSAGES.claimYourOffer} />
                </h2>

                <p
                    className="rh-text-s rh-mt-0_25 rh-mb-0"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: giftOffer.promoDetailsHTML,
                    }}
                />

                <Form
                    className="rh-display-flex rh-flex-wrap rh-mt-1"
                    onSubmit={(values) => handleAcceptGiftOffer(values.email, values.usersName)}
                >
                    <div className="rh-display-flex rh-flex-direction-column rh-align-items-center">
                        <NameAndEmailFields
                            hasAffiliateId={hasAffiliateId}
                            handleAcceptGiftOffer={handleAcceptGiftOffer}
                            isCompact={true}
                        />
                    </div>

                    <div
                        className={classNames('button-wrapper rh-display-flex rh-flex-direction-column rh-mt-2',
                            'rh-width-100p rh-align-items-center rh-gap-0_5')}
                    >
                        <PrimaryButton
                            size="small"
                            className="button-get-offer rh-mt-0 rh-mx-1"
                            data-name="gift-card-offer-submit"
                        >
                            <FormattedMessage {...LOCAL_MESSAGES.getThisOffer} />
                        </PrimaryButton>

                        <p className="rh-text-xs rh-m-0 rh-fg-blackberry-light">
                            <FormattedMessage
                                id="edb.cc.incentive.proceed-to-provider"
                                defaultMessage="Proceed to {providerName}’s secure website"
                                values={{ providerName: providerName }}
                            />
                        </p>
                    </div>
                </Form>
            </div>
        </div>
    );
}

SmallerDialogue.propTypes = {
    cardName: PropTypes.string.isRequired,
    providerName: PropTypes.string.isRequired,
    giftOffer: GiftCardOfferShape.isRequired,
    hasAffiliateId: PropTypes.string,
    onAcceptOrDecline: PropTypes.func.isRequired,
    handleAcceptGiftOffer: PropTypes.func.isRequired,
};


function NoThanksButton({ className, offerType, onAcceptOrDecline }) {
    return (
        <PageBehaviourButton
            className={classNames('button-decline-offer', 'rh-mt-0', className)}
            size="small"
            onClick={() => onAcceptOrDecline({ isOfferAccepted: false })}
            data-name="edb.cc.incentive.no-thanks"
        >
            <FormattedMessage
                {...LOCAL_MESSAGES.applyWithout}
                values={{ offerType: offerType }}
            />
        </PageBehaviourButton>
    );
}

NoThanksButton.propTypes = {
    className: PropTypes.string,
    offerType: PropTypes.string.isRequired,
    onAcceptOrDecline: PropTypes.func.isRequired,
};


const CARD_WIDTH = 180;
const CARD_HEIGHT = CARD_WIDTH * 0.625;

const LOCAL_MESSAGES = defineMessages({
    applyWithout: {
        id: 'edb.cc.incentive.no-thanks',
        defaultMessage: 'Apply without the {offerType}',
    },
    offerEnds: {
        id: 'edb.cc.incentive.offer-ends',
        defaultMessage: 'Offer ends ',
    },
    mainTitle: {
        id: 'edb.cc.incentive.main-title',
        defaultMessage: 'Get an exclusive {offerAmount, number, CAD0} {offerType}',
    },
    claimYourOffer: {
        id: 'edb.cc.incentive.claim-your-offer',
        defaultMessage: 'Claim your offer',
    },
    getThisOffer: {
        id: 'edb.cc.incentive.get-offer',
        defaultMessage: 'Get this offer',
    },
});

const Container = styled.div`
    > .inner-container {
        // offset-x, offset-y blur spread colour
        box-shadow: 1px 3px 10px 4px ${rgba(Colours.STONE_DARK, 0.20)};

        .card-and-checkmarks {
            .card-image {
                flex: 0 1 ${CARD_WIDTH}px;
            }

            .apply-and-checkmarks {
                flex: 21 1 300px;

                > .rh-mt-0_5-negative {
                    margin-top: -${Sizes.SPACING.HALF};
                }
            }
        }

        .incentive-claim {
            .two-up {
                gap: 0.25rem 1rem;

                .side-by-side {
                    flex: 1 0 200px;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .checkbox-subscribe {
                flex-grow: 0; // overrides for Checkbox
            }

            .button-wrapper {
                .button-get-offer {
                    max-width: 15rem;
                }
            }
        }

        .trusted-by {
            gap: 1.2rem;
        }
    }

    // this button may appear in one of two locations depending upon the cc-no-thanks experiment

    > .inner-container > .incentive-claim > .button-wrapper,
    > .footer {
        .button-decline-offer {
            margin-top: ${Sizes.SPACING.HALF}; // overrides for PageBehaviourButton
            margin-bottom: 0;
        }
    }

    // smaller dialogue variation
    > .smaller-dialogue {
        width: 26em;
        max-width: 90%;
        box-shadow: 1px 3px 10px 4px ${rgba(Colours.STONE_DARK, 0.20)};

        .header-box {
            position: relative;
            border: 1px solid ${Colours.GRAPE};

            > .expiry-pill {
                padding-top: 1px;
                top: -0.7em;
                left: 1em;
            }
        }

        .two-up {
            gap: 0.5rem;

            .side-by-side {
                flex: 0 1 100%;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .button-wrapper {
            .button-get-offer {
                max-width: 15rem;
            }
        }
    }
`;

export default CreditCardIncentive;
