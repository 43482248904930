import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import Colours from '../definitions/Colours';


function AnimatedFadeContainer({
    isVisible,
    duration,
    withColour,
    children,
    onBegin,
    onEnd,
    alwaysRenderChildMarkup,
    ...otherProps
}) {
    return (
        <CSSTransition
            in={isVisible}
            timeout={duration}
            classNames="fade"
            mountOnEnter={!alwaysRenderChildMarkup}
            unmountOnExit={!alwaysRenderChildMarkup}
            onEnter={() => onBegin && onBegin(isVisible)}
            onExit={() => onBegin && onBegin(isVisible)}
            onEntered={() => onEnd && onEnd(isVisible)}
            onExited={() => onEnd && onEnd(isVisible)}
        >
            <Container
                duration={duration}
                withColour={withColour}
                alwaysRenderChildMarkup={alwaysRenderChildMarkup}
                isVisible={isVisible}
                aria-hidden={!isVisible}
                {...otherProps}
            >
                {children}
            </Container>
        </CSSTransition>
    );
}

AnimatedFadeContainer.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    duration: PropTypes.number,
    withColour: PropTypes.bool,

    onBegin: PropTypes.func,
    onEnd: PropTypes.func,

    alwaysRenderChildMarkup: PropTypes.bool,

    children: PropTypes.node.isRequired,
};

AnimatedFadeContainer.defaultProps = {
    duration: 500,
    withColour: true,

    onBegin: undefined,
    onEnd: undefined,

    alwaysRenderChildMarkup: false,
};

const Container = styled.div`
    /* Mount state: only hide if we're not transitioning */
    ${props => props.alwaysRenderChildMarkup
        && !props.isVisible
        && `
        &:not(.fade-enter-active):not(.fade-exit):not(.fade-exit-active) {
            display: none;
        }
    `}

    /* Fade-in: enter -> enter-active -> enter-done */
    &.fade-enter {
        opacity: 0;
        ${props => props.withColour
            && `
            background-color: ${Colours.BLUEBERRY_LIGHT};
       `}
    }
    &.fade-enter-active {
        opacity: 1;
        transition: all ${props => props.duration}ms ease-in;
        ${props => props.withColour
            && `
            background-color: transparent;
        `}
    }
    &.fade-enter-done {
        opacity: 1;
    }

    /* Fade-out: exit -> exit-active -> exit-done */
    &.fade-exit {
        opacity: 1;
        ${props => props.withColour
            && `
            background-color: ${Colours.BLUEBERRY_LIGHTEST};
        `}
    }
    &.fade-exit-active {
        opacity: 0;
        transition: opacity ${props => props.duration}ms ease-out;
    }
    &.fade-exit-done {
        opacity: 0;
    }
`;

export default AnimatedFadeContainer;
