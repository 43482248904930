import SPENDING_CATEGORY_MESSAGES from '../definitions/SpendingCategoryMessages';

/**
 * get a description for the set of spending category types
 *
 * @param {string[]} categoryTypes
 * @param {import('react-intl').IntlShape} intl
 * @return {string} concatenation of descriptions for each category type provided
 */
function getSpendingCategoryDescription(categoryTypes, intl) {
    return categoryTypes
        .map(type => SPENDING_CATEGORY_MESSAGES[type]
            ? intl.formatMessage(SPENDING_CATEGORY_MESSAGES[type]).toLowerCase()
            : type)     // types without a matching message become their own description
        .join(', ');
}

export default getSpendingCategoryDescription;
