import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Config from '../definitions/Config';
import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import ZIndex from '../definitions/ZIndex';
import Typography from '../definitions/Typography';
import MessagePropType from '../definitions/MessagePropType';
import useSession from '../hooks/useSessionStore';
import IconHeart from './icons/IconHeart';
import EmailCaptureModal from './EmailCaptureModal';
import SignInModal from './SignInModal';
import { MAGIC_LINK_SIGN_IN_SCREEN } from './MagicLinkForms';
import Paragraph from './Paragraph';


/* TODO: As of PRO-1011, we added SaveToProfileButtonNew
   We are not sure whether we discard this sticky SaveToProfileButton or not
   Update this component properly when it's decided */
function SaveToProfileButton({
    dataName,
    isFixedToBottom,
    signInMessages,
    variant,
}) {
    // When this feature is disabled, we show the Button to both Guest and Verified user
    //    For Verified user, we show "Feature coming soon" message and make button disappear
    // When this feature is enabled, we show the Button only to Guest,
    //    For Verified user, button never appears

    const isSaveFeatureEnabled = Config.ENABLE_SAVING_DATA_FOR_CALCULATORS;

    const sessionStore = useSession();
    const [ isClicked, setIsClicked ] = useState(false);
    const [ isVisible, setIsVisible ] = useState(!isSaveFeatureEnabled);
    const [ resumeApplicationLink, setResumeApplicationLink ] = useState(undefined);

    // When the feature is enabled, we show the Button only to Guest
    useEffect(() => {
        if (isSaveFeatureEnabled && sessionStore.hasFetchedSession && !sessionStore.isLoggedIn) {
            setIsVisible(true);
        }
    }, [ isSaveFeatureEnabled, sessionStore.hasFetchedSession, sessionStore.isLoggedIn ]);

    // We need to ensure the first render on the client matches the server
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setResumeApplicationLink(window.location.href);
        }
    }, []);

    function handleClick() {
        setIsClicked(true);

        // TODO: when all feature flags are confirmed enabled, we can remove this block
        if (!isSaveFeatureEnabled && sessionStore?.isLoggedIn) {
            setTimeout(
                () => setIsVisible(false),
                5000,
            );
        }
    }

    async function saveEmail({ email }) {
        // Save email to SessionStore and to guest profile
        await sessionStore?.pushProfileUpdate({ email: email });

        // Since user already submitted their email, we can safely hide the button
        setIsVisible(false);
    }

    return (
        <Choose>
            <When condition={sessionStore?.isLoggedIn === false && isClicked}>
                <Choose>
                    {/* TODO: remove EmailCaptureModal after new Save-to-profile experience confirmed to launch*/}
                    <When condition={Config.ENABLE_SIGN_IN_ON_SAVE_TO_PROFILE_BUTTON}>
                        <SignInModal
                            messages={signInMessages && {
                                [MAGIC_LINK_SIGN_IN_SCREEN]: signInMessages,
                            }}
                            resumeApplicationLink={resumeApplicationLink}
                            shouldShowNewsletter={true}
                            shouldShowGoogleSignIn={true}
                            onClose={() => setIsClicked(false)}
                        />
                    </When>
                    <Otherwise>
                        <EmailCaptureModal
                            onSubmit={saveEmail}
                            onClose={() => setIsClicked(false)}
                        />
                    </Otherwise>
                </Choose>
            </When>
            <When condition={isVisible}>
                <Container
                    isFixedToBottom={isFixedToBottom}
                    variant={variant}
                >
                    <button
                        className="save-button"
                        onClick={handleClick}
                        type="button"
                        data-name={dataName}
                    >
                        <IconHeart
                            className="save-icon"
                            stroke={
                                variant === 'light'
                                    ? Colours.BLUEBERRY_DARK
                                    : Colours.COCONUT
                            }
                            width={Sizes.ICONS.S}
                            height={Sizes.ICONS.S}
                        />
                        <Paragraph
                            as="span"
                            size="small"
                            variant="light"
                            className="save-text"
                            message={(
                                <Choose>
                                    <When condition={isClicked}>
                                        <FormattedMessage
                                            id="Accounts.SaveToProfileButton.ClickedResponse1"
                                            defaultMessage="<b>Feature coming soon! </b>Thanks for showing interest."
                                        />
                                    </When>
                                    <Otherwise>
                                        <FormattedMessage
                                            id="Accounts.SaveToProfileButton.Unclicked"
                                            defaultMessage="{
                                                variant, select,
                                                    light {Favourite}
                                                    other {Save results}
                                                }"
                                            values={{ variant }}
                                        />
                                    </Otherwise>
                                </Choose>
                            )}
                        />
                    </button>
                </Container>
            </When>
            {/* Button disappears when Verified user clicks button and we don't want screen to be disturbed
                TODO: when feature flag is confirmed enabled, we can remove Spacer
                    since the button doesn't appear from the beginning for Verified user
            */}
            <When condition={!isSaveFeatureEnabled && !isVisible && !isFixedToBottom}>
                <Spacer />
            </When>
        </Choose>
    );
}

SaveToProfileButton.propTypes = {
    dataName: PropTypes.string,
    isFixedToBottom: PropTypes.bool,
    signInMessages: PropTypes.shape({
        MAGIC_LINK_SIGN_IN_TITLE: MessagePropType,
        MAGIC_LINK_SIGN_IN_CTA: MessagePropType,
        EMAIL_LABEL: MessagePropType,
        EMAIL_PLACEHOLDER: MessagePropType,
        DESCRIPTION: MessagePropType,
    }),
    variant: PropTypes.oneOf([ 'light', 'dark' ]),
};

SaveToProfileButton.defaultProps = {
    dataName: 'SaveToProfileButton',
    isFixedToBottom: false,
    signInMessages: undefined,
    variant: 'dark',
};

const Container = styled.div`
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: flex-end;
    margin-right: -1.125rem;
    overflow: hidden;
    padding: 1.125rem 1.125rem 0 1.125rem;

    ${props => props.isFixedToBottom
        ? `
            position: fixed;
            right: 4%;
        `
        : 'position: sticky;'}
    z-index: ${ZIndex.ELEMENTS};

    > .save-button {
        ${props =>
        props.variant === 'light'
            ? `
            background-color: ${Colours.COCONUT};
            border: 1px solid ${Colours.BLUEBERRY_DARK};
            color: ${Colours.BLUEBERRY_DARK};
            transition: box-shadow 300ms;
        `
            : `
                background-color: ${Colours.BLUEBERRY_DARK};
                border: none;
                color: ${Colours.COCONUT};
                transition: box-shadow 300ms, background-color 300ms;
            `}
        border-radius: 0.375rem 0.375rem 0 0;
        display: flex;
        font-family: ${Typography.FONT_FAMILY_STACK};
        font-size: ${Sizes.FONTS.XS};
        margin: 0;
        padding: 0.625rem;
        width: 10.325rem;

        > .save-icon {
            flex-basis: ${Sizes.ICONS.S};
            flex-shrink: 0;
        }

        > .save-text {
            align-self: center;
            ${props =>
        props.variant === 'light'
            ? `color: ${Colours.BLUEBERRY_DARK};`
            : `color: ${Colours.COCONUT};`}
            height: 100%;
            margin-bottom: 0;
            margin-left: 1rem;
            margin-top: 0;
            text-align: left;

            > b {
                font-weight: ${Typography.WEIGHTS.MEDIUM};
            }
        }
    }

    > .save-button:hover {
        ${props =>
        props.variant === 'light'
            ? 'transition: box-shadow 300ms;'
            : `
            background-color: ${Colours.BLUEBERRY_DARKEST};
            transition: box-shadow 300ms, background-color 300ms;
        `}
        box-shadow: 0 0.063rem 1.125rem 0 ${Colours.STONE};
        cursor: pointer;
    }
`;

const Spacer = styled.div`
    padding: 0 0 ${Sizes.SPACING.FOUR} 0;
`;

export default observer(SaveToProfileButton);
