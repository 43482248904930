import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import Sizes from '../definitions/Sizes';
import { MessageBannerVariants } from '../definitions/MessageBannerDefinitions';
import SOCIAL_PROVIDERS from '../definitions/SocialProviders';
import EXPERIMENTS from '../definitions/Experiments';
import MAGIC_LINK_VARIANTS from '../definitions/MagicLinkVariants';
import { MY_QUOTE_ROUTE } from '../definitions/Routes';
import MagicLinkSignInMessagesPropType from '../definitions/MagicLinkSignInMessagesPropType';

import noticeError from '../functions/noticeError';
import trackHeapEvent from '../functions/trackHeapEvent';
import { requestMagicLink } from '../functions/requestMagicLink';
import subscribeToNewsletter from '../functions/subscribeToNewsletter';
import getDashboardBasePath from '../functions/getDashboardBasePath';

import useSession from '../hooks/useSessionStore';
import useExperiment from '../hooks/useExperiment';
import { usePageSettings } from './PageSettingsContext';

import MessageBanner from './MessageBanner';
import MagicLinkSignIn from './MagicLinkSignIn';
import MagicLinkConfirmationScreen from './MagicLinkConfirmationScreen';
import CircleSpinner from './CircleSpinner';


const MAGIC_LINK_SIGN_IN_SCREEN = 'SignIn';
const MAGIC_LINK_CONFIRMATION_SCREEN = 'Confirmation';

function MagicLinkForms({
    supportPhone,
    messages,
    hideLoginDescription,
    onRequestLinkCustom,
    emailForForcedLogin,
    resumeApplicationLink,
    documentOptions,
    magicLinkVariant,
    shouldShowNewsletter,
    shouldShowGoogleSignIn,
}) {
    // TODO: remove these temporal variables after Experiment is done
    const googleSignInSegment = useExperiment(EXPERIMENTS.ENABLE_GOOGLE_SIGN_IN)?.segment;
    const isGoogleSignInEnabled = shouldShowGoogleSignIn && googleSignInSegment > 0;

    const ctx = usePageSettings();
    const sessionStore = useSession();
    const [ email, setEmail ] = useState(emailForForcedLogin);
    const [ activeScreen, setActiveScreen ] = useState(MAGIC_LINK_SIGN_IN_SCREEN);
    const intl = useIntl();
    const {
        dispatchMagicLinkRequest,
        isRequestingMagicLink,
        magicLinkError,
    } = useMagicLink({
        resumeApplicationLink,
        magicLinkVariant,
        shouldShowGoogleSignIn,
        googleSignInSegment,
        documentOptions,
        onRequestLinkCustom,
    });

    useEffect(
        () => {
            if (emailForForcedLogin) {
                setActiveScreen(MAGIC_LINK_CONFIRMATION_SCREEN);

                handleSignIn(emailForForcedLogin);
            }
        },
        [ emailForForcedLogin ],
    );

    async function handleSignIn(signInEmail, isNewsletterChecked) {
        setEmail(signInEmail);

        if (isNewsletterChecked) {
            subscribeToNewsletter({
                email: signInEmail,
                businessUnit: ctx.businessUnit,
                pageType: ctx.pageType,
                tags: ctx.tags,
                categories: ctx.categories,
            }).catch(error => { // do not crash on error
                noticeError(error, {
                    message: '[MagicLinkForms] Error occurred while subscribing to newsletter',
                    email: signInEmail,
                });
            });
            if (shouldShowGoogleSignIn) {
                trackHeapEvent('[GoogleSSOExperiment] Newsletter subscribed', {
                    loginSource: 'MagicLink',
                    segment: googleSignInSegment,
                });
            }
        }

        if (await dispatchMagicLinkRequest(signInEmail)) {
            if (shouldShowGoogleSignIn) {
                trackHeapEvent('[GoogleSSOExperiment] MagicLink requested', {
                    loginSource: 'MagicLink',
                    segment: googleSignInSegment,
                });
            }
            setActiveScreen(MAGIC_LINK_CONFIRMATION_SCREEN);
        }
    }

    async function handleGoogleSignIn(providerToken, isNewsletterChecked) {
        if (shouldShowNewsletter && isNewsletterChecked) {
            try {
                // Due to page redirection in sessionStore.signInBySocialService,
                // We need to await subscribeToNewsletter done
                await subscribeToNewsletter({
                    providerToken,
                    socialProvider: SOCIAL_PROVIDERS.GOOGLE,
                    businessUnit: ctx.businessUnit,
                    pageType: ctx.pageType,
                    tags: ctx.tags,
                    categories: ctx.categories,
                });
                trackHeapEvent('[GoogleSSOExperiment] Newsletter subscribed', {
                    loginSource: SOCIAL_PROVIDERS.GOOGLE,
                    segment: googleSignInSegment,
                });
            } catch (error) { // do not crash on error
                noticeError(error, {
                    message: '[MagicLinkForms] Error occurred while subscribing to newsletter',
                    note: 'google-sign-in',
                });
            }
        }
        const { document, documentType, documentRedirect } = documentOptions;

        // same with authorize page
        const resumeLink = resumeApplicationLink
            ?? documentRedirect
            ?? `${getDashboardBasePath(intl.locale)}${MY_QUOTE_ROUTE}`;

        await sessionStore.signInBySocialService({
            providerToken,
            redirectTo: resumeLink,
            document,
            documentType,
        });
    }

    return (
        <Container>
            <If condition={magicLinkError}>
                <MessageBanner
                    iconKey="exclamation-mark"
                    variant={MessageBannerVariants.ERROR}
                    message={(
                        <FormattedMessage
                            id="Accounts.MagicLinkForms.ErrorMessage"
                            defaultMessage={`{activeScreen, select,
                                SignIn {There has been a problem signing you in. Please try again later.}
                                Confirmation {There has been a problem resending the email. Please try again later.}
                                other {}
                            }`}
                            values={{ activeScreen }}
                        />
                    )}
                    data-name="magicLinkForm-errorBanner"
                />
            </If>

            <If condition={isRequestingMagicLink}>
                <CircleSpinner position="fixed" />
            </If>

            <Choose>
                <When condition={activeScreen === MAGIC_LINK_SIGN_IN_SCREEN}>
                    <MagicLinkSignIn
                        email={email}
                        onSubmit={handleSignIn}
                        messages={messages[MAGIC_LINK_SIGN_IN_SCREEN]}
                        hideDescription={hideLoginDescription}
                        shouldShowNewsletter={shouldShowNewsletter}
                        shouldShowGoogleSignIn={isGoogleSignInEnabled}
                        onGoogleSignIn={handleGoogleSignIn}
                    />
                </When>

                <When condition={activeScreen === MAGIC_LINK_CONFIRMATION_SCREEN}>
                    <MagicLinkConfirmationScreen
                        email={email}
                        supportPhone={supportPhone}
                        messages={messages[MAGIC_LINK_CONFIRMATION_SCREEN]}
                    />
                </When>
            </Choose>
        </Container>
    );
}

MagicLinkForms.propTypes = {
    supportPhone: PropTypes.string,
    messages: PropTypes.shape({
        [MAGIC_LINK_SIGN_IN_SCREEN]: MagicLinkSignInMessagesPropType,
        [MAGIC_LINK_CONFIRMATION_SCREEN]: PropTypes.object,
    }),
    hideLoginDescription: PropTypes.bool,
    onRequestLinkCustom: PropTypes.func,
    emailForForcedLogin: PropTypes.string,
    magicLinkVariant: PropTypes.oneOf(Object.values(MAGIC_LINK_VARIANTS)),
    resumeApplicationLink: PropTypes.string,
    documentOptions: PropTypes.shape({
        document: PropTypes.object,
        documentType: PropTypes.string,
        documentToken: PropTypes.string,
        documentRedirect: PropTypes.string,
    }),
    shouldShowNewsletter: PropTypes.bool,
    shouldShowGoogleSignIn: PropTypes.bool,
};

MagicLinkForms.defaultProps = {
    supportPhone: undefined,
    messages: {},
    hideLoginDescription: undefined,
    onRequestLinkCustom: undefined,
    emailForForcedLogin: undefined,
    magicLinkVariant: undefined,
    resumeApplicationLink: undefined,
    documentOptions: {},
    shouldShowNewsletter: false,
    shouldShowGoogleSignIn: false,
};

function useMagicLink({
    magicLinkVariant,
    resumeApplicationLink,
    shouldShowGoogleSignIn, // TODO: remove this after Experiment is done
    googleSignInSegment, // TODO: remove this after Experiment is done
    documentOptions,
    onRequestLinkCustom,
}) {
    const [ isRequestingMagicLink, setRequestingMagicLink ] = useState(false);
    const [ magicLinkError, setMagicLinkError ] = useState(null);

    const queryParams = {};
    if (resumeApplicationLink != null) {
        queryParams.redirect = resumeApplicationLink;
    }

    // NOTE: Temporal code for heap analytics
    // googleSignInSegment will be passed to Profile-faas endpoint
    // and it will be sent back to /authorize page as a part of magic-link
    // please refer to /authorize page for more info
    if (shouldShowGoogleSignIn) {
        queryParams.googleSignInSegment = googleSignInSegment;
    }

    async function dispatchMagicLinkRequest(requestedEmail) {
        let requestError = null; // setState is async

        setRequestingMagicLink(true);
        setMagicLinkError(null);

        try {
            onRequestLinkCustom
                ? await onRequestLinkCustom(requestedEmail)
                : await requestMagicLink(requestedEmail, queryParams, documentOptions, magicLinkVariant);
        } catch (error) {
            requestError = error;
            setMagicLinkError(error);

            // eslint-disable-next-line no-console
            console.error(`[useMagicLink]: error requesting magic link: ${error.message}`, error);
        }

        setRequestingMagicLink(false);

        return requestError === null;
    }

    return {
        // state
        isRequestingMagicLink,
        magicLinkError,
        // methods
        dispatchMagicLinkRequest,
    };
}

const Container = styled.div`
    max-width: 600px;

    margin-left: auto;
    margin-right: auto;

    padding-top: ${Sizes.SPACING.FOUR};
    padding-bottom: ${Sizes.SPACING.FOUR};
`;

export default observer(MagicLinkForms);

export { MAGIC_LINK_SIGN_IN_SCREEN, MAGIC_LINK_CONFIRMATION_SCREEN };
