import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useIsMounted from '../hooks/useIsMounted';
import MessagePropType from '../definitions/MessagePropType';
import fetchChartData from '../functions/fetchChartData';
import Chart, { CHART_TYPES, CHART_TOOLTIP_LAYOUTS } from './Chart';


const VALID_SERIES_DATASOURCE_REGEX = /^[A-Za-z0-9-]+\.[A-Za-z0-9-]+(\.[A-Za-z0-9- ]+)?$/;

function RateHistoryLineChart({ series, startDate, endDate, className }) {
    const [ chartData, setChartData ] = useState({});
    const getIsMounted = useIsMounted();

    // Prevent passing series by reference AND ensure we are working with an array.
    let seriesArray = Array.isArray(series) ? [ ...series ] : [ { ...series } ];

    // Clean the incoming object a little
    seriesArray = seriesArray
        .map((seriesItem) => {
            // Trim the string fields
            if (typeof seriesItem.name === 'string') {
                seriesItem.name = seriesItem.name.trim();
            }
            if (typeof seriesItem.dataSource === 'string') {
                seriesItem.dataSource = seriesItem.dataSource.trim();
            }

            // Ensure stats key exists
            if (!seriesItem.stats) {
                seriesItem.stats = {};
            }
            return seriesItem;
        })
        // Remove any series that don't have a name or dataSource
        .filter((seriesItem) => seriesItem.dataSource && seriesItem.name);

    // Fetch chart data on mount or when the series config changes
    useEffect(() => {
        // Make sure all slugs are valid, otherwise don't render chart at all.
        const areAllDataSourcesValid = seriesArray.length && seriesArray
            .every((seriesItem) =>
                seriesItem.dataSource.match(VALID_SERIES_DATASOURCE_REGEX),
            );

        if (areAllDataSourcesValid) {
            fetchSeriesData(seriesArray, startDate, endDate)
                .then((data) => {
                    // We may have become unmounted before the fetch resolved.
                    if (getIsMounted()) {
                        setChartData(data);
                    }
                })
                .catch(() => {
                    if (getIsMounted()) {
                        setChartData({});
                    }
                });
        } else {
            setChartData({});
        }
    }, [ JSON.stringify(series), startDate, endDate ]);

    return (
        <div className={classNames(
            'rh-position-relative',
            className,
        )}
        >
            <Chart
                series={chartData?.series}
                xAxis={{
                    values: chartData?.dates,
                }}
                tooltip={{
                    xValueFormat: 'medium',
                    layout: CHART_TOOLTIP_LAYOUTS.DETAILED,
                }}
            />
        </div>
    );
}

const SERIES_SHAPE = {
    // displayed to user in tooltip, etc.
    name: MessagePropType.isRequired,

    // e.g. `best-rates.5y-fixed` or `boc.5y-bond`
    dataSource: PropTypes.string.isRequired,

    // enables min/mean/max lines
    stats: PropTypes.shape({
        min: PropTypes.bool,
        mean: PropTypes.bool,
        max: PropTypes.bool,
    }),
};

RateHistoryLineChart.propTypes = {
    series: PropTypes.oneOfType([
        PropTypes.shape(SERIES_SHAPE),
        PropTypes.arrayOf(PropTypes.shape(SERIES_SHAPE)),
    ]).isRequired,
    startDate: PropTypes.string, // YYYY-MM-DD
    endDate: PropTypes.string, // YYYY-MM-DD
    className: PropTypes.string,
};

RateHistoryLineChart.defaultProps = {
    startDate: '2000-01-01',
    endDate: undefined,
    className: undefined,
};

/**
 * Fetches data from API & generates series object that can be passed to Chart.
 *
 * @params series {object || array} A single SERIES_SHAPE or an array of SERIES_SHAPEs
 * @returns {array} An array of series objects suitable for passing to Chart component's series
 * prop. Contains name, data, type & stats keys.
 */
async function fetchSeriesData(series, startDate, endDate) {
    // Temporary backwards compatibility mapping for old mortgage charts
    // Remove this once all mortgage charts have been updated
    let seriesArray = series
        .map(item => {
            return {
                ...item,
                dataSource: item.dataSource.replace(/^best\./, 'best-rates.'),
            };
        });

    const chartData = await fetchChartData(
        seriesArray.map(data => data.dataSource),
        startDate,
        endDate,
    );

    // Jam incoming seriesArray together with fetched ChartData
    // Adds 'data' key to each series in seriesArray, with 'main' & 'stats' keys
    seriesArray.forEach((seriesItem) => {
        const data = {
            stats: {},
        };

        if (chartData.series[seriesItem.dataSource]) {
            data.main = chartData.series[seriesItem.dataSource];
        }

        Object.entries(seriesItem.stats).forEach(([ stat, value ]) => {
            const dataArray = chartData.series[seriesItem.dataSource].map((item) => item.y);

            if (value) {
                switch (stat) {
                    case 'min':
                        data.stats[stat] = Math.min(...dataArray);
                        break;
                    case 'max':
                        data.stats[stat] = Math.max(...dataArray);
                        break;
                    case 'mean':
                        data.stats[stat] = dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length;
                        break;
                }
            }
        });

        seriesItem.data = data;
    });

    return {
        series: seriesArray.map((seriesItem) => ({
            name: seriesItem.name,
            data: seriesItem.data.main,

            // type of *series*, not BU. Overrides the parent chart's type.
            type: CHART_TYPES.LINE,

            stats: {
                min: seriesItem.data?.stats?.min,
                mean: seriesItem.data?.stats?.mean,
                max: seriesItem.data?.stats?.max,
            },
        })),
        dates: chartData.dates,
    };
}

export default RateHistoryLineChart;
