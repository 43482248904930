import { types } from 'mobx-state-tree';


const SUBMISSION_SOURCES = {
    WIZARD: 'mtg-wizard',
    APPLICATION: 'mtg-app',
    LEAD_FORM: 'lead-form',
    BBM: 'bbm',
    OMP: 'omp',
    PRE_LEAD: 'pre-lead',
    CALCULATOR: 'calculator',
};

const SubmissionSourceEnum = types.enumeration('SubmissionSource', Object.values(SUBMISSION_SOURCES));

export default SUBMISSION_SOURCES;
export { SubmissionSourceEnum };
