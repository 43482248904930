import { fetchSavings } from '@ratehub/inv-common';

import { Products } from '../definitions/Products';
import enrichDepositsProduct from './enrichDepositsProduct';


function getRate(apiEntry) {
    // Return the promo rate OR the regular rate as the `rate` on the model items object.
    return apiEntry.bonusInterestRate ?? apiEntry.interestRate;
}

function enrichProduct(cmsEntry, apiEntry, slot) {
    // Enrich common fields with other deposits
    enrichDepositsProduct(cmsEntry, apiEntry, slot);
    Object.assign(slot, {
        rate: cmsEntry.rateOverride || getRate(apiEntry),
        productType: Products.SAVINGS,
    });
}

export default async function enrichSavingsProducts(slots, options) {
    const response = await fetchSavings({
        products: slots.map(({ requested, fallback }) => ({
            requested: requested.map(product => ({
                id: product.id,
            })),
            fallback: {
                type: fallback?.type,
            },
        })),
        ...options,
    });

    // Support new and legacy return shape
    const products = response.data?.products ?? response.data;

    slots.forEach((slot, index) => {
        let matchedRate;

        for (let i = 0; i < slot.requested.length; i++) {
            const savingsAccount = slot.requested[i];
            matchedRate = products.find(
                product => product?.id === savingsAccount.id
            );

            // Clear id so we are sure to use the fallback if required
            slot.id = undefined;

            // Rates are not always featured, so a matched rate is not guaranteed
            if (matchedRate) {
                enrichProduct(savingsAccount, matchedRate, slot);
                // We only need to enrich the first product we come across.
                break;
            }
        }

        // If no matchedRate is found, use fallback
        if (!matchedRate) {
            const fallbackRate = products[index];

            if (fallbackRate) {
                enrichProduct(slot.fallback, fallbackRate, slot);
            }
        }
    });
}
