import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import Colours from '../definitions/Colours';
import IconCheckmark from '../components/icons/IconCheckmark';


function CheckmarkBullet({ className }) {
    return (
        <StyledCheckmarkBullet
            className={classNames(className)}
            strokeWidth="4"
            outlineWidth="0"
            fill={Colours.MINT_LIGHT}
            stroke={Colours.MINT_DARKEST}
        />
    );
}

CheckmarkBullet.propTypes = {
    className: PropTypes.string,
};

CheckmarkBullet.defaultProps = {
    className: undefined,
};

// for proper rendering within an <li>, the <li> should be styled with the following
// (as may be seen in FormattedListWithCheckmarks):
//      list-style-type: none;
//      display: flex;
//      align-items: flex-start;
const StyledCheckmarkBullet = styled(IconCheckmark)`
    flex: 0 0 auto;
    width: 1.5em;     // important that these are em so that font-size changes to the containing text size scales these too
    height: 1.5em;
    margin-right: 0.5em;
`;

export default CheckmarkBullet;
