import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import { ImageSizes } from '@ratehub/base-ui';

import ImageCollectionPropType from '../definitions/ImageCollectionPropType';
import withLink from './withLink';
import VisualElement from './VisualElement';


const VisualElementWithLink = withLink(VisualElement);

/* STYLE CONSTS */
const MOBILE_LAYOUT_SWITCH = '50rem';

function LogoBlockItem({ imageCollection, imageSrc, imageAlt, imageHref, className, size, ...otherProps }) {
    const Image = imageHref ? VisualElementWithLink : VisualElement;

    return (
        <Container className={`logo-size-${size}`}>
            <Image
                imageCollection={imageCollection
                    ? createImageCollection(imageCollection, size)
                    : undefined
                }
                imageUrl={imageSrc}
                alt={imageAlt}
                href={imageHref}
                target={imageHref ? '_blank' : undefined}
                isLazy
                className={classNames(
                    'rh-display-block rh-height-100p rh-width-auto rh-opacity-0_6',
                    className,
                )}
                {...otherProps}
            />
        </Container>
    );
}

LogoBlockItem.propTypes = {
    imageCollection: ImageCollectionPropType,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    imageHref: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
};

LogoBlockItem.defaultProps = {
    imageCollection: undefined,
    imageSrc: undefined,
    imageAlt: '',
    imageHref: undefined,
    className: undefined,
    size: 'medium',
};

const Container = styled.li`
    list-style: none;

    &.logo-size-small {
        height: 2.5em;
    }

    &.logo-size-medium {
        height: 3.125em;
    }

    &.logo-size-large {
        height: 3.65em;
    }

    a {
        &:hover,
        &:focus {
            img {
                opacity: 1;
            }
        }
    }
`;

function createImageCollection(imageCollection, size) {
    const sizes = imageCollection.sizes;

    const targetSize = size === 'large'
        ? sizes?.[ImageSizes.S]
        : sizes?.[ImageSizes.XS];

    return {
        sizes: [
            {
                url: targetSize?.url || sizes[ImageSizes.FULL].url,
                breakpoint: `(min-width: ${MOBILE_LAYOUT_SWITCH})`,
            },
        ],
        fallback: targetSize?.url || sizes[ImageSizes.FULL].url,
        width: targetSize?.width || sizes[ImageSizes.FULL].width,
        height: targetSize?.height || sizes[ImageSizes.FULL].height,
        alt: imageCollection.alt || imageCollection.title,
        mime: imageCollection.mime,
    };
}

LogoBlockItem.blockKey = 'rh/logo-block-item';

export default LogoBlockItem;
