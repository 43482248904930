import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconCheckmark({ outlineWidth, stroke, strokeWidth, fill, ...otherProps }) {
    return (
        <IconCheckmark.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M44.29 23.55L28.27 41a.94.94 0 01-1.38 0L19 33.12" />
        </IconCheckmark.Svg>
    );
}

IconCheckmark.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf([
        ...Object.values(Colours),
    ]),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf([
        ...Object.values(Colours),
    ]),
};

IconCheckmark.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    strokeWidth: '2px',
    fill: Colours.TRANSPARENT,
};

IconCheckmark.Svg = styled.svg`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconCheckmark;
