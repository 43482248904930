import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import CityPropType from '../definitions/CityPropType';
import MessagePropType from '../definitions/MessagePropType';
import fetchCityInformation from '../functions/fetchCityInformation';
import Autocomplete from './Autocomplete';
import InputController from './InputController';


// NOTE TO DEVELOPER:
// The very strange autoComplete value is to disable Chrome's autofill.
// Chrome does not respect the conventional autoComplete="off" | "no"
// Autofill causes problems with our API-driven search behaviour.
// The value selected comes from here: https://github.com/facebook/react/issues/1159

// NOTE 2:
// `data-lpignore="true" is to disable LastPass: it keeps offering to fill this in.
// https://support.logmeininc.com/lastpass/help/manage-your-form-fills-lp040002

function CityInput({
    id,
    name,

    value,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    isRequired,
    isDisabled,

    placeholder,
    label,

    canShowInvalid,

    onCreateField,

    ...otherProps 
}) {
    return (
        <InputController
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            isRequired={isRequired}
            isDisabled={isDisabled}
            defaultErrorMessage={(
                <FormattedMessage
                    {...MESSAGES.DEFAULT_ERROR}
                />
            )}
            getParsedValue={city => isCompleteCity(city) ? city : null}
            canShowInvalid={canShowInvalid}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => (
                <Autocomplete
                    {...inputProps}

                    label={label}
                    placeholder={placeholder ?? MESSAGES.PLACEHOLDER}

                    fetchSuggestions={fetchCitySuggestions}
                    suggestionRenderer={suggestionRenderer}

                    autoComplete="new"
                    data-lpignore="true"

                    isInvalid={inputState.isInvalid}
                    shouldShowInvalid={inputState.shouldShowInvalid}
                    errorMessage={inputState.errorMessage}
                />
            )}
        </InputController>
    );
}

CityInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: CityPropType,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,

    label: MessagePropType,
    placeholder: MessagePropType,

    canShowInvalid: PropTypes.bool,

    onCreateField: PropTypes.func,
};

CityInput.defaultProps = {
    id: undefined,
    value: undefined,
    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,
    isDisabled: false,
    isRequired: undefined,
    label: undefined,
    placeholder: undefined,
    canShowInvalid: false,
    onCreateField: undefined,
};

const MESSAGES = defineMessages({
    DEFAULT_ERROR: {
        id: 'base-ui.cityInput.defaultError',
        defaultMessage: 'Please enter a city',
    },
    PLACEHOLDER: {
        id: 'base-ui.cityInput.placeholder',
        defaultMessage: 'Enter city',
    },
    TYPE_TO_SEARCH: {
        id: 'base-ui.cityInput.typeToSearchLabel',
        defaultMessage: 'Type to search',
    },
});

/**
 * 
 * @param {string} query 
 * @returns {Promise<Array>} - Array of city objects
 */
function fetchCitySuggestions(query) {
    // no comma means we're searching by city name
    if (!query.includes(',')) {
        return fetchCityInformation({ name: query }, 'CityInput.fetchSuggestions');
    }

    // assumption is that the query is in the format "city, province"
    const [ city, province ] = query.split(',').map(part => part.trim());

    if (!city) {
        return []; // just ignore this query since its probably a temporary change (e.g. user is changing the city part of the query)
    }

    return fetchCityInformation({ name: `${city},${province}` }, 'CityInput.fetchSuggestions');
}

function suggestionRenderer(city) {
    return `${city.name}, ${city.province.code}`;
}

function isCompleteCity(city) {
    return city && city.name && city.province && city.province.code;
}

export default CityInput;
