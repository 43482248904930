import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Sizes from '../definitions/Sizes';
import AddressPropType from '../definitions/AddressPropType';
import Messages from '../definitions/AddressMessages';

import AddressSearchResultTypesPropType from '../definitions/AddressSearchResultTypesPropType';
import MessagePropType from '../definitions/MessagePropType';
import AddressInputSearch from './AddressInputSearch';
import PageBehaviourButton from './PageBehaviourButton';


function AddressInputBasic({
    onChangeMode,
    isDisabled,

    ...otherProps 
}) {
    function handleShowForm(event) {
        event.preventDefault();     // prevent navigation
        onChangeMode?.();
    }

    return (
        <AddressInputBasic.Container>
            { /* Address Search Input */}
            <span className="fullSearch">
                <AddressInputSearch
                    {...otherProps}
                    isDisabled={isDisabled}
                />
            </span>

            { /* Swap Input Modes */}
            <If condition={onChangeMode}>
                <AddressInputBasic.ToggleModeContainer>
                    <AddressInputBasic.ToggleModeButton
                        onClick={handleShowForm}
                        size="small"
                        message={Messages.MODE_FORM_BUTTON}
                        data-name="addressInput-toggleMode"
                        disabled={isDisabled}
                    />
                </AddressInputBasic.ToggleModeContainer>
            </If>

        </AddressInputBasic.Container>
    );
}

AddressInputBasic.propTypes = {
    name: PropTypes.string.isRequired,
    value: AddressPropType,
    onChange:   PropTypes.func.isRequired,
    onChangeMode: PropTypes.func,
    isInvalid: PropTypes.bool,
    shouldShowInvalid: PropTypes.bool,
    isDisabled: PropTypes.bool,
    searchResultTypes: AddressSearchResultTypesPropType,
    placeholder: MessagePropType,
};

AddressInputBasic.defaultProps = {
    value: undefined,
    onChangeMode: undefined,
    isInvalid: false,
    shouldShowInvalid: false,
    isDisabled: undefined,
    searchResultTypes: undefined,
    placeholder: undefined,
};

AddressInputBasic.Container = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: auto;
    margin: 0 -${Sizes.SPACING.ONE};

    justify-content: space-between;
    & .fullSearch {
        width: 100%;
        margin-left: ${Sizes.SPACING.ONE};
        margin-right: ${Sizes.SPACING.ONE};

        min-width: 250px;
    }
`;

AddressInputBasic.ToggleModeContainer = styled.div`
    width: 100%;
    padding: ${Sizes.SPACING.QUARTER} 0;
    margin: 0 ${Sizes.SPACING.ONE};
`;

AddressInputBasic.ToggleModeButton = styled(PageBehaviourButton)`
    margin-left: auto;
`;

export default AddressInputBasic;
