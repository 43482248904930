import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';
import classNames from 'classnames';

import {
    Colours,
    Sizes,
    getOrderedColour,
    IconChooser,
} from '@ratehub/base-ui';
import { Spacing, ZIndex } from '@ratehub/base-ui/src/styles';

// Map stroke colours from getOrderedColour() to background colours (for icons).
// eg. [stroke colour]: fill colour
const THEME_COLOUR_MAP = {
    [Colours.BLUEBERRY_DARK]: Colours.BLUEBERRY_LIGHTEST,
    [Colours.LIME_DARK]: Colours.MINT_LIGHT,
    [Colours.YUZU_DARKEST]: Colours.YUZU_LIGHTEST,
    [Colours.STRAWBERRY]: Colours.WATERMELON_LIGHTEST,
    [Colours.TANGERINE_DARK]: Colours.TANGERINE_LIGHTEST,
    [Colours.GRAPE_DARK]: Colours.GRAPE_LIGHTEST,
    [Colours.WATERMELON]: Colours.WATERMELON_LIGHTEST,
};

const LIST_BREAKPOINT_WIDTH = '43.75rem'; // matches breakpoint in StoreFrontContainer

const MAX_VIEWPORT_WIDTH = 120; // rem
const MIN_FONT_SIZE = Sizes.FONTS.M;
// Needs to be unitless for calc function
const MAX_FONT_SIZE_INCREASE = stripRemUnit(Sizes.FONTS.XL) - stripRemUnit(Sizes.FONTS.M);
const MAX_AVAILABLE_VIEWPORT_WIDTH = MAX_VIEWPORT_WIDTH - stripRemUnit(LIST_BREAKPOINT_WIDTH);

function StoreFrontItem({ title, url, iconKey, styleIndex, className, ...otherProps }) {
    return (
        <Container
            className={classNames('rh-mb-1 rh-mx-0', className)}
            {...otherProps}
        >
            <a
                className="anchor rh-bg-coconut rh-fg-blackberry hover--rh-fg-blackberry active--rh-fg-blackberry focus--rh-fg-blackberry rh-position-relative rh-display-flex rh-outline-none rh-box-sizing-border-box rh-height-100p rh-border-radius-7px"
                href={url}
                data-name="storeFrontItem-visitItemLink"
            >
                <IconChooser
                    className="icon rh-ml-1 rh-mr-0_5 rh-display-block rh-icon-m"
                    iconKey={iconKey}
                    stroke={getStroke(styleIndex)}
                    strokeWidth="1.5px"
                    fill={getFill(styleIndex)}
                    outlineWidth="0"
                    size="L"
                />

                <p
                    className="label rh-pr-0_5 rh-pl-0_5 rh-text-xl leading-s rh-ml-0"
                >
                    {title}
                </p>
            </a>
        </Container>
    );
}

function getStroke(styleIndex) {
    return getOrderedColour(styleIndex);
}

function getFill(styleIndex) {
    return THEME_COLOUR_MAP[getOrderedColour(styleIndex)];
}

StoreFrontItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    iconKey: PropTypes.string.isRequired,
    styleIndex: PropTypes.number,
    className: PropTypes.string,
};

StoreFrontItem.defaultProps = {
    styleIndex: 0,
    className: undefined,
};

const EASING_CURVE = 'cubic-bezier(0.19, 1, 0.22, 1)';

const Container = styled.li`
    flex-basis: 100%;

    > .anchor {
        align-items: center;
        text-decoration: none;

        /* Including the shadow in its own ::before allows us to apply a
        mix-blend-mode to JUST the shadow WITHOUT neeed to apply that blend
        mode to all of this element's children. */
        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            box-shadow: 0 2px 8px 0 ${transparentize(.3, Colours.STONE)};
            mix-blend-mode: multiply;

            z-index: 0;
            border-radius: ${Spacing.HALF};
        }

        backface-visibility: hidden;
        transition:
            transform 0.4s ${EASING_CURVE},
            background-color 0.4s ${EASING_CURVE},
            box-shadow 0.4s ${EASING_CURVE};

        &:hover,
        &:focus,
        &:active {
            z-index: ${ZIndex.ELEMENTS};
        }

        &:hover,
        &:focus {
            /* Note: transforms break the ::before's ability to blend with
            backgrounds below the Store Front. Truly maddening, but considered
            an acceptable loss since it happens on hover only */
            transform: scale(1.1);

            box-shadow: 0 4px 10px 0 ${Colours.STONE};
        }

        &:active {
            background-color: ${Colours.STONE_LIGHTEST};
            transform: scale(1.05);

            box-shadow: 0 3px 7.5px 0 ${Colours.STONE};
        }

        > .icon {
            flex-shrink: 0;
        }
    }

    @media (min-width: ${LIST_BREAKPOINT_WIDTH}) {
        flex-basis: 336px;
        flex-shrink: 1;

        margin-bottom: 0;
        margin-left: ${Spacing.HALF};
        margin-right: ${Spacing.HALF};

        > .anchor {
            flex-direction: column;
            justify-content: space-evenly;

            padding-top: ${Spacing.ONE_AND_A_HALF};
            padding-bottom: ${Spacing.ONE_AND_A_HALF};

            > .icon {
                margin-bottom: ${Spacing.HALF};
                margin-left: auto;
                margin-right: auto;
            }

            > .label {
                font-size: calc(${MIN_FONT_SIZE} + ${MAX_FONT_SIZE_INCREASE}
                            * (100vw - ${LIST_BREAKPOINT_WIDTH}) / ${MAX_AVAILABLE_VIEWPORT_WIDTH});

                margin: 0 auto;
                text-align: center;
            }
        }
    }
`;

function stripRemUnit(string) {
    return parseFloat(string.slice(0, -3));
}

StoreFrontItem.blockKey = 'rh/store-front-item';

export default StoreFrontItem;
