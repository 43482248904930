import React from 'react';

import { RateHistoryLineChart as BaseUIRateHistoryLineChart } from '@ratehub/base-ui';


/**
 * ATTENTION: The core of RateHistoryLineChart component has been moved to @ratehub/base-ui
 *
 * This is to prevent cyclical dependencies between web-components and other packages, but
 * still preserve the RateHistoryLineChart blockKey used within WordPress.
*/
function RateHistoryLineChart(props) {
    return <BaseUIRateHistoryLineChart {...props} />;
}

RateHistoryLineChart.blockKey = 'rh/rate-history-line-chart';
RateHistoryLineChart.requiresLayoutRow = true;

export default RateHistoryLineChart;
