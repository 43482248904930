import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ANALYTICS_KEY, ANALYTICS_ATTRIBUTE_NAME } from '../definitions/Analytics';
import Colours from '../definitions/Colours';
import Products from '../definitions/Products';
import Sizes from '../definitions/Sizes';
import ImageSizesByType from '../definitions/ImageSizesByType';
import formatPercentage from '../functions/formatPercentage';
import ProviderDetails from './ProviderDetails';
import PrimaryButton from './PrimaryButton';
import CardBanner from './CardBanner';


// despite the name, this is _not_ related to ProductCardLayout and its collection of components
function ProductCardBrief({
    className,
    CTAComponentWithProps,
    applyText,
    imageSrc,
    imageAlt,
    productType,
    title,
    description,
    isSponsored,
    sponsoredText,
    isARatehubCompany,
    providerName,
    rate,
    hasPromo,
    analyticsObject,
    hasMinWidth,
    hasMaxWidth,
    isCompact,

    ...otherProps
}) {
    const intl = useIntl();

    const [
        CTAComponent,
        propsForCTAComponent,
    ] = CTAComponentWithProps;

    const giftCardOffer = propsForCTAComponent?.giftCardOffer;

    const isCreditCard = productType === Products.CREDIT_CARDS;

    const propsForProductCardItem = {
        ...otherProps,
        ref: self => self && (self[ANALYTICS_KEY] = analyticsObject),
        className: classNames(className, 'rh-box-sizing-border-box rh-width-100p rh-bg-coconut rh-border-radius-7px', {
            [ANALYTICS_ATTRIBUTE_NAME]: !!analyticsObject,
        }),
    };

    const cardBannerText = getCardBannerText(hasPromo, giftCardOffer, intl);
    const cardLabelText = getCardLabelText({ isSponsored, sponsoredText, hasPromo, intl });
    const shouldShowBanner = !!cardBannerText;

    const productPercentage = formatPercentage(typeof rate === 'object' ? rate.value : rate, intl);
    const primaryButtonProps = productType === Products.MORTGAGES ? {} : { forwardedAs: 'div' };

    const dataNamePrefix = `${productType}.featured`;

    return (
        <ProductCardItem
            {...propsForProductCardItem}
            variant="dark"
            size="large"
            shouldLowerCaseMessage={isSponsored || !giftCardOffer}
            hasMinWidth={hasMinWidth}
            hasMaxWidth={hasMaxWidth}
            isCompact={isCompact}
        >
            <CTAComponent
                {...propsForCTAComponent}
                data-name={`${dataNamePrefix}.card`}
                className={classNames(
                    'cta-target rh-display-grid rh-box-sizing-border-box rh-height-100p rh-cursor-pointer rh-text-decoration-none',
                    'rh-border-width-1px rh-border-style-solid rh-border-radius-7px rh-outline-none',
                    'rh-border-color-stone',
                    propsForCTAComponent?.className,
                    {
                        'rh-pt-0 rh-px-1 rh-pb-1': isCompact,
                        'rh-pt-0 rh-px-1_75 rh-pb-1_75': !isCompact,

                        'hover--rh-border-color-blueberry-dark active--rh-border-color-blueberry-darkest': !shouldShowBanner,
                        'hover--rh-border-color-grape active--rh-border-color-grape-darkest': shouldShowBanner,
                    },
                )}
            >
                {/* Only show ONE of EITHER card banner or card label. */}
                {/* CARD BANNER */}
                <If condition={shouldShowBanner}>
                    <CardBanner
                        className="banner"
                        text={cardBannerText}
                        borderRadius="7px"
                    />
                </If>

                {/* CARD LABEL */}
                <If condition={!shouldShowBanner}>
                    <p className="label rh-display-flex rh-text-s rh-my-0 leading-xs rh-fg-blackberry rh-text-lowercase">
                        {cardLabelText}
                    </p>
                </If>

                {/* PRODUCT TITLE */}
                <Choose>
                    {/* 'rate' type is inconsistent and has to support empty string and string '0' from CMS */}
                    <When condition={rate || rate == 0}>
                        <ProviderDetails
                            isRatehubCompany={isARatehubCompany}
                            providerName={providerName}
                            providerIcon={imageSrc}
                            className={classNames(
                                'provider-details rh-display-flex rh-px-0',
                                isCompact ? 'rh-pb-1' : 'rh-pb-2',
                            )}
                            alwaysShowReviews
                            isLogoWrapped={false}
                        />
                    </When>
                    <Otherwise>
                        <p className="product-title rh-text-align-center rh-title-s rh-pt-0 rh-pb-1 rh-my-0 rh-fg-blackberry">
                            {title}
                        </p>
                    </Otherwise>
                </Choose>

                {/* PRODUCT CONTENT */}
                <div className="product-content rh-display-flex rh-align-items-center rh-fg-blackberry">
                    <Choose>
                        <When condition={rate == null}>
                            <LazyLoadImage
                                className="large-badge rh-display-block rh-my-0_5 rh-width-auto"
                                src={imageSrc}
                                alt={getAltText(isCreditCard, imageAlt, intl)}
                                width={
                                    isCreditCard
                                        ? ImageSizesByType.CREDIT_CARDS.CARD_FEATURED_PRODUCT.WIDTH
                                        : ImageSizesByType.PROVIDERS.BADGE_MEDIUM.WIDTH
                                }
                                height={
                                    isCreditCard
                                        ? ImageSizesByType.CREDIT_CARDS.CARD_FEATURED_PRODUCT.HEIGHT
                                        : ImageSizesByType.PROVIDERS.BADGE_MEDIUM.HEIGHT
                                }
                            />
                        </When>

                        <Otherwise>
                            <span className="rh-title-3xl rh-my-0 rate leading-xs">
                                {/* Mortgage products receive whole rate object */}
                                {productPercentage}
                            </span>
                        </Otherwise>
                    </Choose>

                    <If condition={description}>
                        <p className="description rh-text-s rh-text-align-center leading-s rh-mt-1 rh-mb-1">
                            {description}
                        </p>
                    </If>
                </div>

                {/* CALL-TO-ACTION BUTTON */}
                {/* credit cards will provide its own PrimaryButton */}
                <If condition={productType !== Products.CREDIT_CARDS}>
                    <PrimaryButton
                        {...primaryButtonProps}
                        data-name={`${dataNamePrefix}.apply.cta`}
                        className={classNames(
                            'cta rh-text-lowercase rh-cursor-pointer',
                            {
                                'rh-display-flex': primaryButtonProps?.forwardedAs === 'div',
                            },
                        )}
                    >
                        {applyText}
                    </PrimaryButton>
                </If>
            </CTAComponent>
        </ProductCardItem>
    );
}

ProductCardBrief.propTypes = {
    applyText: PropTypes.string.isRequired,
    CTAComponentWithProps: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.func,
            PropTypes.shape({
                className: PropTypes.string,
                onClick: PropTypes.func,
                cardInfo: PropTypes.object, // CreditCardSynopsisShape from '@ratehub/cc-common'
                subtextOptions: PropTypes.shape({
                    reducePaddingAbove: PropTypes.bool,
                }),
                buttonSize: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
                rel: PropTypes.string,
            }),
        ]),
    ).isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    productType: PropTypes.oneOf([ ...Object.values(Products) ]).isRequired,
    title: PropTypes.string.isRequired,
    bannerText: PropTypes.string,
    className: PropTypes.string,
    description: PropTypes.string,
    hasPromo: PropTypes.bool,
    isARatehubCompany: PropTypes.bool,
    isSponsored: PropTypes.bool,
    sponsoredText: PropTypes.string,
    analyticsObject: PropTypes.object,
    providerName: PropTypes.string,
    hasMinWidth: PropTypes.bool,
    hasMaxWidth: PropTypes.bool,
    isCompact: PropTypes.bool,
    rate: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, PropTypes.object ]),
};
ProductCardBrief.defaultProps = {
    analyticsObject: undefined,
    bannerText: undefined,
    className: undefined,
    description: undefined,
    isARatehubCompany: false,
    isSponsored: false,
    sponsoredText: undefined,
    rate: undefined,
    providerName: undefined,
    hasMinWidth: true,
    hasMaxWidth: true,
    isCompact: false,
    hasPromo: false,
};

const MESSAGES = defineMessages({
    RATEHUB_COMPANY_BYLINE: {
        id: 'FeaturedProduct.ratehubCompanyByline',
        defaultMessage: 'A Ratehub Company',
    },
    FEATURED: {
        id: 'FeaturedProduct.banner.featured',
        defaultMessage: 'Featured',
    },
    PRODUCT_ALT_TEXT_CARD: {
        id: 'FeaturedProduct.product.altTextCard',
        defaultMessage: '{productDescription}',
    },
    PRODUCT_ALT_TEXT_LOGO: {
        id: 'FeaturedProduct.product.altTextLogo',
        defaultMessage: '{productDescription} logo',
    },
    SPONSORED: {
        id: 'FeaturedProduct.banner.sponsored',
        defaultMessage: 'Sponsored',
    },
    PROMO_OFFER: {
        id: 'FeaturedProduct.banner.promoOffer',
        defaultMessage: 'Limited promo offer',
    },
    GIFT_CARD_OFFER: {
        id: 'FeaturedProduct.edb.cc.ribbon-message.gift-card-offer',
        defaultMessage: '{offerType} offer',
    },
});

function getCardBannerText(hasPromo, giftCardOffer, intl) {
    return hasPromo && giftCardOffer
        ? intl.formatMessage(MESSAGES.GIFT_CARD_OFFER, { offerType: giftCardOffer.offerType })
        : undefined;
}

function getCardLabelText({ isSponsored, sponsoredText, hasPromo, intl }) {
    return isSponsored
        ? sponsoredText || intl.formatMessage(MESSAGES.SPONSORED) // SPONSORED always has priority over PROMO_OFFER / FEATURED
        : intl.formatMessage(hasPromo ? MESSAGES.PROMO_OFFER : MESSAGES.FEATURED);
}

function getAltText(productType, imageAlt, intl) {
    return intl.formatMessage(productType === Products.CREDIT_CARDS
        ? MESSAGES.PRODUCT_ALT_TEXT_CARD
        : MESSAGES.PRODUCT_ALT_TEXT_LOGO, {
        productDescription: imageAlt,
    });
}


const ProductCardItem = styled.div`
    ${props => props.hasMinWidth && `
        min-width: 19em;
    `}
    ${props => props.hasMaxWidth && `
        max-width: 22em;
    `}

    /* Typically set in FeaturedProductGroup but also here in case we ever want
    to render a FeaturedProduct on it's own. Please use as="div" at the very
    least if you go that route though. */
    list-style: none;

    &:last-child {
        margin-right: 0;
    }

    > .cta-target {
        /* Grid notes: Each card's grid context takes up the full height of each
        card and most grid rows have a height (and many are auto) so grid lines
        aren't guaranteed to line up card-to-card. There may be leftover space
        in a given grid though, so assigning 1fr to the product content tells
        that section to "pick up the leftover slack" and thereby ensures buttons
        get aligned to the bottom of the card. */

        /* [ banner | label ] | title | product content | button */
        grid-template-rows: ${Sizes.SPACING.FOUR} auto 1fr auto;

        > .banner {
            align-self: self-start;

            margin-left: ${props => props.isCompact ? `-${Sizes.SPACING.ONE}` : `-${Sizes.SPACING.ONE_AND_THREE_QUARTERS}`};
            margin-right: ${props => props.isCompact ? `-${Sizes.SPACING.ONE}` : `-${Sizes.SPACING.ONE_AND_THREE_QUARTERS}`};
        }

        > .label {
            grid-row: 1;
            align-self: center;

            align-items: center;
            justify-content: center;

            &::before,
            &::after {
                content: '';
                display: inline-block;

                width: ${Sizes.SPACING.QUARTER};
                height: 1px;

                background-color: ${Colours.BLACKBERRY};
            }

            &::before {
                margin-right: ${Sizes.SPACING.HALF};
            }

            &::after {
                margin-left: ${Sizes.SPACING.HALF};
            }
        }

        > .product-title {
            grid-row: 2;
            align-self: center;
        }

        > .provider-details {
            flex: 1;
            justify-content: center;
            grid-row: 2;

            min-height: 4.125rem;
        }

        > .product-content {
            grid-row: 3;

            flex-direction: column;
            row-gap: ${Sizes.SPACING.QUARTER};

            // establish a min-height for times when no cards in the carousel
            // have a description so the card image/rate isn't squished up
            // against the button.
            min-height: ${Sizes.SPACING.SEVEN};

            > .large-badge {
                min-width: 6rem;
            }

            > .description {
                min-height: 3.5rem; // reserves space for max 3 lines of text
            }
        }

        > .cta {
            /* REQUIREMENTS:
                - all buttons should be horizontally aligned with one another
                - buttons should be aligned to very bottom of card */
            grid-row: 4 / 5;
            align-self: end;

            &:is(div) {
                align-items: center;
                justify-content: center;
            }
        }
    }
`;


export default ProductCardBrief;
