import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';


function RichTextContent({ className, variant, content, hasVerticalMargins }) {
    // Wrap all tables in divs (for horizontal scrollbars)
    const regexTable = new RegExp(/<table.*?>([\s\S]*?).*<\/table>/gi);
    const tableWithWrapper = content.replace(regexTable, '<div class="overflow">$&</div>');

    // find all overflow containers with tables containing the is-sticky-row class.
    const regexIsStickyRow = new RegExp(/<div class="overflow"><table.*?class=".*?is-sticky-row.*?".*?>([\s\S]*?).*<\/table><\/div>/gi);

    // inject has-max-height class on overflow container.
    const newContent = tableWithWrapper.replace(regexIsStickyRow, (match) => {
        return match.replace('overflow', 'overflow has-max-height');
    });

    const containerRef = useRef(null);

    // Add scroll listeners to rh-tables. Allows CSS to know when a table
    // has been scrolled.
    useEffect(() => {
        const richTextContainer = containerRef?.current;

        if (!richTextContainer) {
            return;
        }

        const overflowContainers = richTextContainer.querySelectorAll('.overflow');

        if (!overflowContainers.length) {
            return;
        }

        function onScroll(e) {
            const table = e.target.querySelector('.rh-table');

            if (!table) {
                return;
            }

            if (e.target.scrollLeft === 0) {
                table.classList.remove('is-scrolled');
            } else {
                table.classList.add('is-scrolled');
            }
        }

        overflowContainers.forEach(overflowContainer => {
            overflowContainer.addEventListener('scroll', onScroll);
        });

        return () => {
            overflowContainers.forEach(overflowContainer => {
                overflowContainer.removeEventListener('scroll', onScroll);
            });
        };
    }, [ content, containerRef?.current ]);

    return (
        <div
            ref={containerRef}
            className={classNames(
                'rich-text',
                { 'no-vertical-margins': !hasVerticalMargins },
                { 'light-variant': variant === 'light' },
                className,
            )}

            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: newContent,
            }}
        />
    );
}

RichTextContent.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([ 'dark', 'light' ]),
    content: PropTypes.string,
    hasVerticalMargins: PropTypes.bool,
};

RichTextContent.defaultProps = {
    className: undefined,
    variant: 'dark',
    content: undefined,
    hasVerticalMargins: true,
};

export default RichTextContent;
