import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

import {
    IconCheckmark,
    Colours,
    Sizes,
} from '@ratehub/base-ui';


const VARIANTS = {
    LARGE: 'large',
    SMALL: 'small',
};

const ICON_SIZE_LARGE = '3em';
const ICON_SIZE_SMALL = '2em';

function Checklist({ title, checkListMessages, variant, ...otherProps }) {
    const isLarge = variant === VARIANTS.LARGE;

    return (
        <Container {...otherProps}>
            <If condition={title}>
                <h3 className={
                    classNames('rh-text-align-left rh-mt-0 rh-mb-0_75', {
                        'rh-title-2xs': isLarge,
                        'rh-title-3xs': !isLarge,
                    })}
                >
                    {title}
                </h3>
            </If>

            <If condition={checkListMessages.length}>
                <ul className="list rh-text-align-left rh-m-0 rh-p-0">
                    <For
                        each="checkListMessage"
                        of={checkListMessages}
                    >
                        <li
                            className={classNames('listItem rh-mb-0_75', {
                                'isLarge': isLarge,
                            })}
                            key={checkListMessage}
                        >
                            <IconCheckmark
                                className="checkmark"
                                outlineWidth="0px"
                                stroke={Colours.BLUEBERRY_DARK}
                                strokeWidth="4px"
                                width={isLarge ? ICON_SIZE_LARGE : ICON_SIZE_SMALL}
                            />

                            <p className={
                                classNames('rh-my-0', {
                                    'rh-text-m': isLarge,
                                    'rh-text-s': !isLarge,
                                })}
                            >
                                {checkListMessage}
                            </p>
                        </li>
                    </For>
                </ul>
            </If>
        </Container>
    );
}

Checklist.propTypes = {
    title: PropTypes.node,
    checkListMessages: PropTypes.arrayOf(PropTypes.node).isRequired,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

Checklist.defaultProps = {
    title: undefined,
    variant: VARIANTS.LARGE,
};

const Container = styled.div`
    > .list {
        list-style-type: none;

        > .listItem {
            position: relative;

            display: flex;
            align-items: center;

            > .checkmark {
                position: absolute;

                /**
                * Offset the icon so the symbol is:
                *   - left-aligned with left of heading
                *   - vertically aligned with first line of text
                */
                top: -${Sizes.SPACING.HALF};
                left: -${Sizes.SPACING.HALF};

                flex-shrink: 0;
            }

            /* icon is position absolute so let's bump the text over by however
            much the icon is taking up. */
            padding-left: calc(${ICON_SIZE_SMALL} - ${Sizes.SPACING.HALF});
            &.isLarge {
                padding-left: calc(${ICON_SIZE_LARGE} - ${Sizes.SPACING.THREE_QUARTERS});

                > .checkmark {
                    /* different offset when isLarge due to different icon artwork dimensions */
                    top: -${Sizes.SPACING.SEVEN_EIGHTHS};
                    left: -${Sizes.SPACING.THREE_QUARTERS};
                }
            }
        }
    }
`;

export default Checklist;
