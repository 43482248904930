import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import { 
    usePageSettings, 
    Config, 
    CookieSettings, 
    Products, 
    ProductsFR, 
    trackHeapEvent, 
    useMessageBannerContext, 
    useSession,
} from '@ratehub/base-ui';

import getRevisitBannerPropsInsuranceAuto from '../functions/getRevisitBannerPropsInsuranceAuto';
import getRevisitBannerPropsInsuranceHome from '../functions/getRevisitBannerPropsInsuranceHome';
import getRevisitBannerPropsCreditCards from '../functions/getRevisitBannerPropsCreditCards';
import getRevisitBannerPropsMortgages from '../functions/getRevisitBannerPropsMortgages';


// Set up RevisitBanner for the BU
// MessageBanner's props & visibility is to be provided by the BU
function useRevisitBannerEffect() {
    const intl = useIntl();
    const sessionStore = useSession();
    const pageSettings = usePageSettings();
    const messageBannerState = useMessageBannerContext();

    // Requirement: Revisit banner can only become visible if
    //  the feature flag is enabled, no other banners are present,
    //  and the user's session has been fetched
    // NOTE: messageBannerState.areAnyBannersVisible is not part of the
    //  dependency array since we only want this banner to be visible
    //  upon landing on the page
    useEffect(() => {
        if (!Config.ENABLE_REVISIT_BANNER
            || !messageBannerState
            || messageBannerState.areAnyBannersVisible
            || !pageSettings
            || !sessionStore?.hasFetchedSession
        ) {
            return;
        }

        if (pageSettings.businessUnit === Products.AUTO_INSURANCE
            || pageSettings.businessUnit === ProductsFR.AUTO_INSURANCE
        ) {
            const bannerPropsAuto = getRevisitBannerPropsInsuranceAuto(sessionStore);

            if (bannerPropsAuto) {
                const { documentId, documentType, ...otherBannerProps } = bannerPropsAuto;

                messageBannerState.show({
                    ...otherBannerProps,
                    isSticky: true,
                    isCloseVisible: true,
                    onHide: () => saveBannerCloseStateToCookie(documentId, documentType),
                });
                trackHeapEvent('RevisitBanner Shown');
            }
        }
        else if (pageSettings.businessUnit === Products.HOME_INSURANCE
            || pageSettings.businessUnit === ProductsFR.HOME_INSURANCE
        ) {
            const bannerPropsHome = getRevisitBannerPropsInsuranceHome(sessionStore);

            if (bannerPropsHome) {
                const { documentId, documentType, ...otherBannerProps } = bannerPropsHome;

                messageBannerState.show({
                    ...otherBannerProps,
                    isSticky: true,
                    isCloseVisible: true,
                    onHide: () => saveBannerCloseStateToCookie(documentId, documentType),
                });
                trackHeapEvent('RevisitBanner Shown');
            }
        }
        else if (pageSettings.businessUnit === Products.CREDIT_CARDS
            || pageSettings.businessUnit === ProductsFR.CREDIT_CARDS
        ) {
            const bannerPropsCC = getRevisitBannerPropsCreditCards(sessionStore, intl.locale);

            if (bannerPropsCC) {
                const { documentId, documentType, ...otherBannerProps } = bannerPropsCC;

                messageBannerState.show({
                    ...otherBannerProps,
                    isSticky: true,
                    isCloseVisible: true,
                    onHide: () => saveBannerCloseStateToCookie(documentId, documentType),
                });
                trackHeapEvent('RevisitBanner Shown');
            }
        }
        // do not want to overwhelm users with 4+ CTAs, so do not show banner on pages with sidebar
        else if ((pageSettings.businessUnit === Products.MORTGAGES || pageSettings.businessUnit === ProductsFR.MORTGAGES)
            && !pageSettings.hasSidebar
        ) {
            const bannerPropsMortgages = getRevisitBannerPropsMortgages(sessionStore, intl.locale);

            if (bannerPropsMortgages) {
                const { documentId, documentType, ...otherBannerProps } = bannerPropsMortgages;

                messageBannerState.show({
                    ...otherBannerProps,
                    isSticky: true,
                    isCloseVisible: true,
                    onHide: () => saveBannerCloseStateToCookie(documentId, documentType),
                });
                trackHeapEvent('RevisitBanner Shown');
            }
        }
    }, [
        pageSettings,
        sessionStore?.hasFetchedSession,
        messageBannerState,
        Config.ENABLE_REVISIT_BANNER,
        intl?.locale,
    ]);
}

function saveBannerCloseStateToCookie(documentId, documentType) {
    const cookieName = `revisit-banner-${documentType}`;
    const base64Value = btoa(JSON.stringify({
        documentId,
        closeAt: new Date().toISOString(),
    }));

    Cookies.set(cookieName, base64Value, CookieSettings);
}

export default useRevisitBannerEffect;
