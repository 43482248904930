import { defineMessages } from 'react-intl';


const MESSAGES = defineMessages({
    ctaButtonText: {
        id: 'cc.card.apply-now',
        defaultMessage: 'Go to site',       // originally: Get more details
    },

    pointsPerDollar: {
        id: 'cc.card.pointsPerDollar',
        defaultMessage: `{multiplier, plural,
            one {# point}
            other {# points}
        }/{oneDollar, number, CAD0}`,
    },

    // common calculation dialogue messages (travel and cash back)
    calculateHowMonthlySpending: {
        id: 'cc.results.calculate-how.monthly-spending',
        defaultMessage: 'If you spend',
    },
    calculateHowMonthlySpendingPerMonth: {
        id: 'cc.results.calculate-how.monthly-spending-per-month',
        defaultMessage: '{monthlySpending}/mo',
    },
    calculateHowYouWillEarn: {
        id: 'cc.results.calculate-how.you-will-earn',
        defaultMessage: 'You’ll earn',
    },
    calculateHowSubtractAnnualFee: {
        id: 'cc.results.calculate-how.subtract-annual-fee',
        defaultMessage: 'Subtract annual fee',
    },

    // card footer messages
    cardFooterSignUpBonus: {
        id: 'cc.rt.cardFooter.signUpBonus',
        defaultMessage: 'Welcome bonus{newline}<green>{signUpBonusPoints, number} pts</green>{newline}{signUpBonusDollars, number, CAD0} value',
    },

    cardFooterAnniversaryBonus: {
        id: 'cc.rt.cardFooter.anniversaryBonus',
        defaultMessage: 'Anniversary bonus{newline}<green>{anniversaryBonusPoints, number} pts</green>{newline}{anniversaryBonusValue, number, CAD0} value',
    },

    cardFooterCashBackWelcomeBonus: {
        id: 'cc.rt.cardFooter.cash-back.welcomeBonus',
        defaultMessage: 'Welcome bonus{newline}<green>+{cashBackWelcomeBonus, number, CAD0}</green>{newline}based on monthly spending',
    },

    cardFooterAnnualFee: {
        id: 'cc.rt.cardFooter.annualFee-same-size',
        defaultMessage: `Annual fee{newline}{isFirstYearFeeWaived, select,
            true {<b><line-through>{annualFee, number, CAD0}</line-through> <green>{zero, number, CAD0}</green></b>{newline}1st year waived}
            other {<b>{annualFee, number, CAD0}</b>}
        }`,
    },

    cardFooterMinimumCreditIncome: {
        id: 'cc.rt.cardFooter.minimumCreditIncome',
        defaultMessage: `{minimumCreditScore, select,
            null {}
            other {Credit recommended: <b>{minimumCreditScore, select,
                any {Any}
                other {{minimumCreditScore}+}}</b>{newline}}
        }Min. income: <b>{minimumIncome, number, CAD0}{minimumIncome, select,
            0 {}
            other {/yr}
        }</b>`,
    },

    cardFooterBalanceTransferOffer: {
        id: 'cc.rt.cardFooter.balanceTransferOffer-same-size',
        defaultMessage: `Balance transfer offer{newline}<b>{balanceTransferPromoRateFormatted}</b>{newline}for {balanceTransferPromoMonths, plural,
            one {# month}
            other {# months}
        }`,
    },

    redemptionFlexibilityTitle: {
        id: 'cc.card.redemptionFlexibility.title',
        defaultMessage: 'Redemption flexibility',
    },

    featuresThatMatchTitle: {
        id: 'cc.card.featuresThatMatch.title',
        defaultMessage: 'Features that match your choices',
    },
    earnRewardsTitle: {
        id: 'cc.card.earnRewards.title',
        defaultMessage: 'Earn rewards',
    },

    readMoreButton: {
        id: 'cc.card.readMore.button',
        defaultMessage: 'Read more',
    },
    readLessButton: {
        id: 'cc.card.readLess.button',
        defaultMessage: 'Read less',
    },

    seeMoreDetails: {
        id: 'cc.card.seeMoreDetails',
        defaultMessage: 'See more',
    },

    cardDetailsSubTitle: {
        id: 'cc.card.card-details',
        defaultMessage: 'Card details',
    },

    cardSponsoredLabel: {
        id: 'cc.card.card-sponsored-label',
        defaultMessage: 'Sponsored',
    },

    securedMinimumDepositTooltip: {
        id: 'ccrt.card.secured-minimum-deposit-tooltip',
        defaultMessage: `<p><strong>What does this mean?</strong></p>
<p>Secure credit cards are backed by a security deposit.
Before receiving your card, you must pay the minimum security deposit to the card provider.
The more you deposit, the more you can spend.
</p>`,
    },

    // This was previously used only within CreditCardCTA, but an experiment has required it to be needed elsewhere.
    // If `cc-mobile-cta` variant 1 wins, this can leave here and return to CreditCardCTA.
    checkEligibility: {
        id: 'edb.cc.check-eligibility-cta',
        defaultMessage: 'check eligibility',
    },

    perYear: {
        id: 'edb.cc.calculation.per-year',
        defaultMessage: '/yr',
    },

    annualFeeSummary: {
        id: 'edb.credit-card.annual-fee.summary',
        defaultMessage:
            `{isFirstYearFeeWaived, select,
                true {<line-through>{annualFee, number, CAD0}</line-through> <green>{zero, number, CAD0} first year waived</green>}
                other {{annualFee, number, CAD0}}}`,
    },

    // Monthly spending categories
    spendingMonthly: {
        id: 'edb.cc.results.filters.monthly-expenses.prompt',
        defaultMessage: 'Monthly expenses',
    },

    spendingRestaurants: {
        id: 'edb.cc.results.filters.restaurants.prompt',
        defaultMessage: 'Restaurants',
    },

    spendingGroceries: {
        id: 'edb.cc.results.filters.groceries.prompt',
        defaultMessage: 'Groceries',
    },

    spendingBills: {
        id: 'edb.cc.results.filters.bills.prompt',
        defaultMessage: 'Bills',
    },

    spendingEntertainment: {
        id: 'edb.cc.results.filters.entertainment.prompt',
        defaultMessage: 'Entertainment',
    },

    spendingGas: {
        id: 'edb.cc.results.filters.gas.prompt',
        defaultMessage: 'Gas',
    },

    spendingTravel: {
        id: 'edb.cc.results.filters.travel.prompt',
        defaultMessage: 'Travel',
    },

    spendingPharmacy: {
        id: 'edb.cc.results.filters.drugspharmacy.prompt',
        defaultMessage: 'Pharmacy',
    },

    spendingOther: {
        id: 'edb.cc.results.filters.other.prompt',
        defaultMessage: 'Other',
    },
});

export default MESSAGES;
