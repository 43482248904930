import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import {
    AnimatedFadeContainer,
    Colours,
    RemoveDefaultButtonStyles,
    Sizes,
    Config,
    usePageSettings,
} from '@ratehub/base-ui';
import { LayoutGlobals } from '@ratehub/base-ui/src/styles';

import SiteSettings from '../definitions/SiteSettings';
import AdBigbox from './AdBigbox';
import BestRatesMortgages from './BestRatesMortgages';
import CollapsibleContainer from './CollapsibleContainer';
import TableOfContents from './TableOfContents';
import RelatedLinks from './RelatedLinks';
import SidebarNewsletterSignUpLarge from './SidebarNewsletterSignUpLarge';
import SidebarNewsletterSignUpSmall from './SidebarNewsletterSignUpSmall';
import CollapsibleContainerButton from './CollapsibleContainerButton';
import SidebarPopularBlogPosts from './SidebarPopularBlogPosts';


function Sidebar({ className, ...otherProps }) {
    const {
        hideSidebarOnMobile,
        relatedLinksProps,
        popularPostsProps,
        isTocInitiallyExpanded,
        isRelatedLinksInitiallyExpanded,
        isPopularPostsInitiallyExpanded,
        sidebarSections,
    } = usePageSettings();
    const intl = useIntl();

    const [ isSidebarExpandedOnMobile, setIsSidebarExpandedOnMobile ] = useState(false);

    const componentMap = {
        wizardFlowLauncher: {
            component: SectionWizardFlowLauncher,
            props: {},
            hasBackground: true,
            isStandalone: true,
        },
        advertisement: {
            component: SectionAdvertisement,
            props: {
                shouldShowAd: Config.ENABLE_ADS && Config.ENABLE_SIDEBAR_ADS,
            },
            hasBackground: false,
            isStandalone: true,
        },
        'advertisement-two': {
            component: SectionAdvertisement,
            props: {
                shouldShowAd: Config.ENABLE_ADS && Config.ENABLE_SIDEBAR_ADS,
            },
            hasBackground: false,
            isStandalone: true,
        },
        tableOfContents: {
            component: SectionTableOfContents,
            props: {
                isInitiallyExpanded: isTocInitiallyExpanded,
                onToggleTableOfContents: () => setIsSidebarExpandedOnMobile(false),
                onTableOfContentsClick: () => setIsSidebarExpandedOnMobile(false),
            },
            hasBackground: true,
            isStandalone: false,
            // For now, only TOC is visible on Mobile
            isVisibleOnMobile: !hideSidebarOnMobile,
        },
        relatedLinks: {
            component: SectionRelatedLinks,
            props: {
                relatedLinksProps: relatedLinksProps,
                isInitiallyExpanded: isRelatedLinksInitiallyExpanded,
            },
            hasBackground: true,
            isStandalone: false,
        },
        popularLinks: {
            component: SectionPopularBlogPosts,
            props: {
                popularPostsProps: popularPostsProps,
                isInitiallyExpanded: isPopularPostsInitiallyExpanded,
            },
            hasBackground: true,
            isStandalone: false,
        },
        newsletterLarge: {
            component: SectionNewsletterLarge,
            props: {},
            hasBackground: true,
            isStandalone: true,
        },
        newsletterSmall: {
            component: SectionNewsletterSmall,
            props: {},
            hasBackground: true,
            isStandalone: true,
        },
    };

    // Build the sidebar section markup out of sidebarSections & componentMap.
    // Each rendered section get stored in sidebarGroups. Allows us to render
    // sections in any order as dictated by sidebarSections array.

    // sidebarGroups will be an array that contains a component that is either
    // just the plain component (when hasBackground=false) or a *wrapped* component
    // (when hasBackground=true). The wrapper provides the background, border,
    // rounded corners, etc. for the section.

    const sidebarGroups = [];
    const sidebarSectionsOnPage = sidebarSections
        // Filter out components that are not supported by the component map above
        //  This can occur when Prod & the CMS are on different release versions
        .filter((sectionKey) => componentMap[sectionKey] != null);

    sidebarSectionsOnPage
        .forEach((sectionKey, sectionIndex) => {
            const prevSection = componentMap[sidebarSections[sectionIndex - 1 ]]
                ? componentMap[sidebarSections[sectionIndex - 1]]
                : undefined;
            const CurrentSection = componentMap[sectionKey];
            const nextSection = componentMap[sidebarSections[sectionIndex + 1]]
                ? componentMap[sidebarSections[sectionIndex + 1]]
                : undefined;

            if (!CurrentSection) {
                return; // Bail on this component if we can't find it
            }

            // useful if we *really* need to identify one section specifically *cough* advertisement
            const sectionId = `sidebar-section-${sectionKey}`;

            const containerClasses = [ 'section-container' ];
            const standaloneClasses = [];

            // If not visible on mobile, add a special class
            if (!CurrentSection.isVisibleOnMobile) {
                containerClasses.push('hide-on-mobile');
                standaloneClasses.push('hide-on-mobile');
            }

            // *** Render WITH background container ***
            if (CurrentSection.hasBackground) {
                containerClasses.push('has-background');

                // If first section, is starting a new group OR is in a standalone group
                if (sectionIndex === 0
                    || (prevSection?.hasBackground === false || prevSection?.isStandalone === true)
                    || CurrentSection.isStandalone) {
                    containerClasses.push('rounded-top');
                }

                // If last section, is ending an existing group OR is in a standalone group
                if (sectionIndex === sidebarSections.length - 1
                    || (nextSection?.hasBackground === false || nextSection?.isStandalone === true)
                    || CurrentSection.isStandalone) {
                    containerClasses.push('rounded-bottom');
                }

                // mark any standalone sections
                if (CurrentSection.isStandalone) {
                    containerClasses.push('standalone');
                }

                // Put component in sidebarGroups *with* wrapper
                sidebarGroups.push(
                    <div
                        id={sectionId}
                        className={containerClasses.join(' ')}
                        key={sectionKey}
                    >
                        <CurrentSection.component {...CurrentSection.props} />
                    </div>,
                );
            }

            // *** Render WITHOUT background container ***
            if (!CurrentSection.hasBackground) {
                if (CurrentSection.isStandalone) {
                    standaloneClasses.push('standalone');
                }

                // Put component in sidebarGroups *without* wrapper
                sidebarGroups.push(
                    <CurrentSection.component
                        id={sectionId}
                        className={standaloneClasses.join(' ')}
                        key={sectionKey}
                        {...CurrentSection.props}
                    />,
                );
            }
        });

    const hasSectionsVisibleOnMobile = sidebarSectionsOnPage.some(section => componentMap[section].isVisibleOnMobile);

    return (
        <Container
            id={SiteSettings.SIDEBAR_ID}
            className={classNames(className, {
                'sidebar-visible-mobile': isSidebarExpandedOnMobile,
            })}
            {...otherProps}
        >
            <For
                each="sidebarSection"
                of={sidebarGroups}
            >
                {sidebarSection}
            </For>

            <If condition={hasSectionsVisibleOnMobile}>
                <div className="mobile-sidebar-trigger rh-bg-coconut rh-display-none rh-px-1_25">
                    <CollapsibleContainerButton
                        isExpanded={false}
                        onClick={() => setIsSidebarExpandedOnMobile(true)}
                        data-name="sidebar-toggle-mobile"
                    >
                        <FormattedMessage
                            {...MESSAGES.TITLE_TABLE_OF_CONTENTS}
                        />
                    </CollapsibleContainerButton>
                </div>
                {/* Animation for stone light clickable overlay so the sidebar opening isn't janky/abrupt */}
                <AnimatedFadeContainer
                    className="mobile-overlay-container rh-position-fixed rh-top-0 rh-bottom-0 rh-right-0 rh-left-0 rh-display-none"
                    isVisible={isSidebarExpandedOnMobile}
                    withColour={false}
                    duration={300}
                >
                    <button
                        type="button"
                        className="mobile-overlay rh-opacity-0_9 rh-width-100p rh-height-100p"
                        onClick={() => setIsSidebarExpandedOnMobile(false)}
                        data-name="sidebar-close-overlay"
                        aria-label={intl.formatMessage(MESSAGES.CLOSE_BUTTON)}
                    />
                </AnimatedFadeContainer>
            </If>
        </Container>
    );
}

Sidebar.propTypes = {
    className: PropTypes.string,
};

Sidebar.defaultProps = {
    className: undefined,
};

const Container = styled.aside`
    padding-top: ${Sizes.SPACING.HALF};
    padding-bottom: ${Sizes.SPACING.HALF};

    background-color: ${Colours.TRANSPARENT};

    > .section-container {
        &.has-background {
            background-color: ${Colours.COCONUT};
            border: 1px solid ${Colours.STONE};

            --corner-radius: 4px;

            &.rounded-top {
                border-top-left-radius: var(--corner-radius);
                border-top-right-radius: var(--corner-radius);
            }
            &.rounded-bottom {
                border-bottom-left-radius: var(--corner-radius);
                border-bottom-right-radius: var(--corner-radius);
            }
        }

        /* allows putting multiple collapsible containers in one sidebar
        section... not really advised though. */
        > .collapsible-container {
            border-bottom: 1px solid ${Colours.STONE};

            &:last-child {
                border-bottom: none;
            }
        }
    }

    /* prevent double border between grouped sections */
    > .section-container:not(.standalone) + .section-container:not(.standalone) {
        border-top: 0;
    }

    /* space out grouped sections and standalone sections */
    > .rounded-bottom,
    > .standalone {
        margin-bottom: ${Sizes.SPACING.HALF};

        /* REQUIREMENT: we want space between the last section in the sidebar
        and the footer when we are scrolled all the way down the page. The
        sidebar container provides that for us however, so we don't want it
        here */
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
        padding-top: 0;
        padding-bottom: 0;

        box-shadow: 0px 2px 18px ${Colours.STONE};

        /* Hide specified sections on mobile, only TOC is enabled for now */
        > .hide-on-mobile {
            display: none;
        }

        > .section-container {
            display: none;

            /* Mobile requires square corners and no borders */
            &.has-background {
                border: none;

                &.rounded-top,
                &.rounded-bottom {
                    border-radius: 0;
                }
            }
        }

        /* Sections have no space between them on mobile */
        > .rounded-bottom,
        > .standalone {
            margin-bottom: 0;
        }

        > .mobile-sidebar-trigger {
            display: block;
        }

        > .mobile-overlay-container {
            display: block;
            z-index: -1;

            /* This is a hack for iPhones specifically - with
                fixed elements while scrolling the very top of the element
                where the header changes on the phone will not have the
                background colour applied from the .mobile-overlay class for
                a few renders. By setting the top here to an extremely
                large number this issue doesn't occur. */
            top: -100vh;

            > .mobile-overlay {
                ${RemoveDefaultButtonStyles};

                /* This is a hack for Safari iOS on older devices (I mostly noticed
                    this on iPads). With fixed elements sometimes the elements
                    flicker or disappear. This translate forces GPU acceleration
                    so the element remains visible as the user scrolls.
                    Source: https://muffinman.io/blog/ios-safari-scroll-position-fixed/ */
                transform: translate3d(0, 0, 0);

                /* RemoveDefaultButtonStyles sets BG colour to transparent
                    so we need to override instead of using atomic class */
                background-color: ${Colours.STONE_LIGHT};
            }
        }

        &.sidebar-visible-mobile {
            > .section-container:not(.hide-on-mobile) {
                display: block;
            }

            > .mobile-sidebar-trigger {
                display: none;
            }
        }
    }
`;

function SectionWizardFlowLauncher({ ...otherProps }) {
    return (
        <BestRatesMortgages {...otherProps} />
    );
}

function SectionAdvertisement({ className, shouldShowAd, ...otherProps }) {
    return (
        <If condition={shouldShowAd}>
            <AdBigbox
                className={classNames(className)}
                {...otherProps}
            />
        </If>
    );
}

SectionAdvertisement.propTypes = {
    className: PropTypes.string,
    shouldShowAd: PropTypes.bool,
};

SectionAdvertisement.defaultProps = {
    className: undefined,
    shouldShowAd: true,
};

function SectionTableOfContents({
    isInitiallyExpanded,
    onToggleTableOfContents,
    onTableOfContentsClick,
    ...otherProps
}) {
    const intl = useIntl();

    const [ isExpanded, setIsExpanded ] = useState(isInitiallyExpanded);

    // On Mobile, we want to be always expanded since we have a button on Mobile that toggles display: none on this
    function handleToggleIsExpanded() {
        if (typeof window !== 'undefined' && window.matchMedia(`(max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH})`)?.matches) {
            setIsExpanded(true);
        } else {
            setIsExpanded(prevIsExpanded => !prevIsExpanded);
        }

        onToggleTableOfContents?.();
    }


    return (
        <CollapsibleContainer
            className="collapsible-container"
            title={intl.formatMessage(MESSAGES.TITLE_TABLE_OF_CONTENTS)}

            isInitiallyExpanded={isInitiallyExpanded}
            isExpanded={isExpanded}
            onClick={handleToggleIsExpanded}

            as="nav"
            {...otherProps}
        >
            <TableOfContents onAnchorClick={onTableOfContentsClick} />
        </CollapsibleContainer>
    );
}

SectionTableOfContents.propTypes = {
    isInitiallyExpanded: PropTypes.bool.isRequired,
    onToggleTableOfContents: PropTypes.func,
    onTableOfContentsClick: PropTypes.func,
};

SectionTableOfContents.defaultProps = {
    onToggleTableOfContents: undefined,
    onTableOfContentsClick: undefined,
};

function SectionRelatedLinks({ relatedLinksProps, isInitiallyExpanded, ...otherProps }) {
    const intl = useIntl();

    return (
        <If condition={relatedLinksProps?.length}>
            <CollapsibleContainer
                className="collapsible-container"
                title={intl.formatMessage(MESSAGES.TITLE_RELATED_LINKS)}
                isInitiallyExpanded={isInitiallyExpanded}
                as="nav"
                {...otherProps}
            >
                <RelatedLinks links={relatedLinksProps} />
            </CollapsibleContainer>
        </If>
    );
}

SectionRelatedLinks.propTypes = {
    relatedLinksProps: PropTypes.array,
    isInitiallyExpanded: PropTypes.bool.isRequired,
};
SectionRelatedLinks.defaultProps = {
    relatedLinksProps: [],
};

function SectionNewsletterLarge() {
    return (
        <SidebarNewsletterSignUpLarge />
    );
}

function SectionNewsletterSmall() {
    return (
        <SidebarNewsletterSignUpSmall />
    );
}

function SectionPopularBlogPosts({ popularPostsProps, isInitiallyExpanded, ...otherProps }) {
    const { menuTitle, posts } = popularPostsProps;

    return (
        <CollapsibleContainer
            className="collapsible-container"
            title={menuTitle}
            isInitiallyExpanded={isInitiallyExpanded}
            as="nav"
            {...otherProps}
        >
            <SidebarPopularBlogPosts
                posts={posts}
                isPopularPostsInitiallyExpanded={isInitiallyExpanded}
                {...otherProps}
            />
        </CollapsibleContainer>
    );
}

SectionPopularBlogPosts.propTypes = {
    popularPostsProps: PropTypes.shape({
        menuTitle: PropTypes.string.isRequired,
        posts: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    isInitiallyExpanded: PropTypes.bool.isRequired,
};


const MESSAGES = defineMessages({
    TITLE_TABLE_OF_CONTENTS: {
        id: 'web-components.Sidebar.TitleTableOfContents',
        defaultMessage: 'What’s on the page',
    },
    TITLE_RELATED_LINKS: {
        id: 'web-components.Sidebar.TitleRelatedLinks',
        defaultMessage: 'Related Links',
    },
    CLOSE_BUTTON: {
        id: 'web-components.Sidebar.CloseButtonLabel',
        defaultMessage: 'Close',
    },
});

export default Sidebar;
