import createBaseConversionPayload from './createBaseConversionPayload';


/**
 *
 * @param {object} params
 * @property {object} params.rate - the mortgage rate which was clicked
 * @property {object} params.lead - lead details to include
 * @property {object} params.experiments - all FE experiments
 * @property {string} params.locale - the locate they're viewing the page in
 */
function createConversionServicePayload({ rate, lead, locale, experiments }) {
    const { city, firstName, lastName, email, phone, details } = lead;
    const provinceCode = city.province.code;

    return {
        ...createBaseConversionPayload({ 
            rate,
            cityName: city.name, 
            provinceCode,
            locale,
            experiments,
        }),
        type: 'SUBMITTED_LEAD',
        brokerage: rate.provider.brokerage?.id ?? null,
        lead: {
            city: `${city.id}`,
            province: provinceCode,
            firstName,
            lastName,
            email,
            phone,
            notes: '',
            subscribe: false,
            contacted: false,
            details: getFormattedLeadDetails(details),
        },
    };
}

/**
 * Format lead payload into a format that OMP can process.
 * Removes null and undefined properties, and empty objects
 * 
 * @param {Object} payload 
 * 
 * @returns {Object} result
 */
function getFormattedLeadDetails(payload) {
    return Object.keys(payload).reduce((result, property) => {
        const value = payload[property];

        // Omit null or undefined properties
        if (typeof value === 'undefined' || value === null) {
            return result;
        }

        // Process non-null non-Date objects
        if (typeof value === 'object' && value instanceof Date === false) {
            // Recursively process each sub-object
            const subObject = getFormattedLeadDetails(value);

            // Omit empty objects
            if (Object.keys(subObject).length > 0) {
                result[property] = subObject;
            }
        } else {
            result[property] = value;
        }

        return result;
    }, {});
}

export default createConversionServicePayload;