import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import {
    FullScreenTakeover,
    ModalFullScreenTakeoverStyles,
    Sizes,

    getTermsURL,
    getPrivacyPolicyURL,
    renderMessage,
} from '@ratehub/base-ui';


const ID_MAIN_HEADING = 'advertising-disclosure-MainHeading';
const ID_DESCRIPTION = 'advertising-disclosure-full-description';

function AdvertisingDisclosure({ size, className, ...otherProps }) {
    const intl = useIntl();
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const lastUpdatedMessage = renderMessage(
        <FormattedMessage
            id="web-components.advertisingDisclosure.lastUpdated"
            defaultMessage="Last Updated: <strong>November 4th, 2020</strong>."
        />, intl,
    );

    const disclosureMessage = renderMessage(
        <FormattedMessage
            id="web-components.advertisingDisclosure.body"
            defaultMessage={`
                <p>
                    Most of the products listed on our website are from partners who compensate us. Products marked as "featured" or "sponsored" in our comparison tables are compensated, and are ranked above those that are not compensated.
                </p>
                <p>
                    Ratehub.ca receives compensation when our users connect with providers and/ or are approved for products offered on our website. We also own and operate a mortgage brokerage, Ratehub.ca (formerly known as CanWise Financial), and are compensated for mortgages funded through the brokerage. Ratehub Inc. o/a Ratehub.ca & CanWise is a licensed mortgage brokerage and CMHC-approved lender.
                </p>
                <p>
                    We strive to offer our users a variety of the best products available in the market to compare but you will not find all offers listed on our website.
                </p>

                <heading>Mortgage Statement</heading>

                <p>
                    The mortgage rates shown are dependent on the search criteria you have provided. A lender may offer different rates when you apply for your mortgage if any of these details differ, or if rates have changed. In some instances, rates may also vary based on your credit or payment history. Additional terms and conditions may apply.
                </p>
                <p>
                    The default mortgage rates advertised on our website are based on the following assumptions: an excellent credit borrower looking for a home purchase loan for a single family home for personal occupancy priced at under $1,000,000 with a down payment of less than 20 per cent (i.e. a high-ratio, insured mortgage).
                </p>
                <p>
                    All mortgages have specific qualification criteria including debt servicing ratios, credit, property value and property details. Rates and Monthly Payment Amounts shown in the Mortgage Comparison Chart are examples only and do not take into consideration homeowners insurance or property taxes.
                    For more details <terms>click here</terms> for Ratehub.ca’s Terms of Use. Further, data displayed in Ratehub.ca’s Mortgage Comparison Chart does not take into consideration your personal financial situation, appraised value of your home, actual credit score, income, existing debt, or other circumstances particular to an individual.
                </p>

                <heading>Privacy Policy</heading>

                <p>
                    Once you you submit a request to connect with a provider, you have agreed to be contacted by the 3rd party provider and or Ratehub.ca. Please <privacy>click here</privacy> if you would like more details on our Privacy Policy and <terms>click here</terms> for our Terms of Use.
                </p>
            `}
            values={{
                /* eslint-disable react/no-multi-comp */
                heading: chunks => (
                    <h2 className="rh-mt-3">
                        {chunks}
                    </h2>
                ),
                terms: chunks => (
                    <a
                        key="terms"
                        href={getTermsURL(intl.locale)}
                        target="_blank"
                        rel="noopener"
                    >
                        {chunks}
                    </a>
                ),
                privacy: chunks => (
                    <a
                        key="privacy"
                        href={getPrivacyPolicyURL(intl.locale)}
                        target="_blank"
                        rel="noopener"
                    >
                        {chunks}
                    </a>
                ),
            }}
        />, intl,
    );

    return (
        <>
            <button
                type="button"
                className={
                    classNames(
                        'rh-box-sizing-border-box rh-border-width-0 rh-p-0 rh-outline-none rh-fg-blueberry-dark hover--rh-fg-blueberry focus--rh-fg-blueberry active--rh-fg-blueberry-darkest',
                        {
                            'rh-text-xl weight-regular': size === BUTTON_SIZES.LARGE,
                            'rh-text-l weight-regular': size === BUTTON_SIZES.MEDIUM,
                            'rh-text-m weight-regular': size === BUTTON_SIZES.SMALL,
                            'rh-text-s weight-regular': size === BUTTON_SIZES.EXTRA_SMALL,
                        },
                        className,
                    )
                }
                onClick={() => setIsModalOpen(true)}
                {...otherProps}
            >
                <FormattedMessage
                    id="web-components.advertisingDisclosure.openModalButton"
                    defaultMessage="Advertising disclosure"
                />
            </button>

            <FullScreenTakeover
                className="content-takeover"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                showBranding={false}
                aria={{
                    labelledby: ID_MAIN_HEADING,
                    describedby: ID_DESCRIPTION,
                }}
                style={{
                    ...ModalFullScreenTakeoverStyles,
                    content: {
                        ...ModalFullScreenTakeoverStyles.content,
                        overflow: 'hidden',
                    },
                }}
                verticalAlignment="flex-start"
                screenReaderLabel={(
                    <FormattedMessage
                        id="web-components.advertisingDisclosure.screenReaderLabel"
                        defaultMessage="Advertising disclosure modal"
                    />
                )}
            >
                <ModalContent
                    className="rich-text rh-mx-auto rh-my-3 rh-overflow-scroll"
                >
                    <h2
                        id={ID_MAIN_HEADING}
                        className={classNames('rh-title-2xl', 'rh-my-0')}
                    >
                        <FormattedMessage
                            id="web-components.advertisingDisclosure.heading"
                            defaultMessage="Advertising disclosure"
                        />
                    </h2>

                    <p className="content-body rh-max-width-50">
                        {lastUpdatedMessage.children}
                    </p>

                    <article
                        id={ID_DESCRIPTION}
                        className="content-body rh-max-width-50"
                    >
                        {disclosureMessage.children}
                    </article>
                </ModalContent>
            </FullScreenTakeover>
        </>
    );
}

const BUTTON_SIZES = {
    EXTRA_SMALL: 'extra-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

AdvertisingDisclosure.propTypes = {
    size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
    className: PropTypes.string,
};

AdvertisingDisclosure.defaultProps = {
    size: `${BUTTON_SIZES.MEDIUM}`,
    className: undefined,
};

const ModalContent = styled.div`
    max-height: calc(100vh - ${Sizes.SPACING.SIX}); // Should be double HEADER_MARGIN_OFFSET (which is THREE - and now applied via utility class)
`;

AdvertisingDisclosure.BUTTON_SIZES = BUTTON_SIZES;

export default AdvertisingDisclosure;
