import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import {
    ElementAttributes,
    Portal,
    BannerProviderDisplay,
    getDashboardBasePath,
    MAGIC_LINK_VARIANTS,
    SaveToProfileButton,
    usePageSettings,
    Config,
} from '@ratehub/base-ui';
import { LayoutGlobals, ZIndex } from '@ratehub/base-ui/src/styles';
import { DASHBOARD_PATHS } from '@ratehub/dashboards';
import {
    BLOCK_LOCATIONS,
    AdLeaderboard,
    Sidebar,
    SiteSettings,
    StickyBanner,
} from '@ratehub/web-components';

import useRevisitBannerEffect from '../hooks/useRevisitBannerEffect';
import filterBlocksByLocation from '../functions/filterBlocksByLocation';
import getBlockOfTypeRecursively from '../functions/getBlockOfTypeRecursively';
import MenuTopicsPropType from '../definitions/MenuTopicsPropType';
import isBodyBlock from '../functions/isBodyBlock';
import CMSComponentSelector from './CMSComponentSelector';
import HeadTag from './HeadTag';
import HeaderUserAccountMenu from './HeaderUserAccountMenu';
import BlogFooter from './BlogFooter';
import BlogTaxonomyList from './BlogTaxonomyList';
import BlogComments from './BlogComments';


const GRID_CLASSES = 'content-layout rh-display-grid rh-m-auto';
let ROW_COUNT = 0;

function Layout({
    metaTitle,
    metaDescription,
    disableAccounts,
    hideBackgroundImage,
    requiresLogin,
    magicLinkVariant,
    resumeApplicationLink,
    loginDocumentOptions,
    blocks,
    blockMap,
    blogRelatedPosts,
    blogTopics,
    blogDisqus,
    isGridDisabled,
    canSaveToProfile,
    hasStickyBanner,
    children,
    ...otherProps
}) {
    const intl = useIntl();
    const pageSettings = usePageSettings();

    const isBlogPage = !!blogRelatedPosts || !!blogTopics || blogDisqus;
    const hasTagsOrCategories = !!(pageSettings?.categories || pageSettings?.tags);

    // By default, sidebar is only rendered when we're not in preview mode and we have a sidebar
    const [ shouldRenderSidebar, setShouldRenderSidebar ] = useState(!pageSettings.isPreview && pageSettings.hasSidebar);

    /**
     * This useEffect and shouldRenderSidebar were added to support the sidebar in preview mode.
     * We need to delay the rendering of the sidebar until the DOM is populated by the rest of the content,
     * so that we can generate the table of contents links correctly.
     * The setTimeout is required, even with this hilarious ms delay, or the useEffect still runs before the DOM is there.
     */
    useEffect(() => {
        // If we're in preview mode and we have a sidebar we can proceed
        if (!pageSettings.isPreview || !pageSettings.hasSidebar) {
            return;
        }

        const timer = setTimeout(() => {
            setShouldRenderSidebar(true);
        }, 0);

        return () => {
            clearTimeout(timer);
        };
    }, [ pageSettings.isPreview, pageSettings.hasSidebar ]);

    // Pull out header/footer blocks, if any
    const headerBlocks = filterBlocksByLocation(blocks, BLOCK_LOCATIONS.HEADER);
    const footerBlocks = filterBlocksByLocation(blocks, BLOCK_LOCATIONS.FOOTER);

    // Check for sticky banner recursively, using useMemo here so
    //  we don't look up all blocks on any re-renders.
    const computedHasStickyBanner = useMemo(() =>
        hasStickyBanner || !!blocks?.some(block => !!getBlockOfTypeRecursively(block, [ StickyBanner.blockKey ]))
    , [ hasStickyBanner, blocks ]);

    const shouldRenderTransparentOverlay = shouldRenderSidebar || computedHasStickyBanner;

    // Get the number of blocks within the main content
    ROW_COUNT = blocks?.filter(isBodyBlock).length;


    // Internally defines a useEffect, which will cause the revisit banner to be shown (by way of our message banner context).
    useRevisitBannerEffect();

    return (
        <PageWrapper
            hideBackgroundImage={hideBackgroundImage || pageSettings.hideBackgroundImage || false}
            sidebarDesktopOffset={pageSettings.sidebarDesktopOffset ?? 0}
            data-test-name="layout-page-wrapper"
            {...otherProps}
        >
            <HeadTag
                metaTitle={metaTitle}
                metaDescription={metaDescription}
                seoMeta={pageSettings.seoMeta}
            />

            <If condition={pageSettings.hasAdAboveMenu}>
                <Portal id={ElementAttributes.PORTAL_ID_LEADERBOARD_AD}>
                    <AdLeaderboard
                        className="ad-above-main-menu"
                        overrideAdIndex={0}
                    />
                </Portal>
            </If>

            <If condition={!disableAccounts}>
                <Portal id={ElementAttributes.HEADER_PORTAL_ID_RIGHT}>
                    <HeaderUserAccountMenu
                        requiresLogin={requiresLogin}
                        magicLinkVariant={magicLinkVariant}
                        resumeApplicationLink={resumeApplicationLink}
                        loginDocumentOptions={loginDocumentOptions}
                        items={getAccountNavigationItems(intl.locale)}
                        data-test-name="layout-header-user-account"
                    />
                </Portal>
            </If>

            {/* Contains styles and renders the banners for useBannerContext and BannerProvider */}
            <BannerProviderDisplay />

            <main id={SiteSettings.MAIN_CONTENT_ID}>
                <If condition={headerBlocks.length}>
                    <header
                        data-test-name="layout-header"
                        className={classNames({
                            [GRID_CLASSES]: !isGridDisabled,
                        })}
                    >
                        <For
                            each="block"
                            of={headerBlocks}
                            index="i"
                        >
                            <CMSComponentSelector
                                key={`${block.blockName}-${i}`}
                                blockMap={blockMap}
                                rowIndex={i}
                                {...block}
                            />
                        </For>
                    </header>
                </If>

                <div
                    id={SiteSettings.LAYOUT_SELECTOR_ID}
                    className={classNames({
                        [GRID_CLASSES]: !isGridDisabled,
                        'rh-position-relative': shouldRenderTransparentOverlay,
                        'with-sidebar': shouldRenderSidebar,
                    })}
                >
                    {children}

                    <If condition={hasTagsOrCategories}>
                        <BlogTaxonomyList
                            className="rh-mb-2 rh-layout-default"
                            categories={pageSettings?.categories}
                            tags={pageSettings?.tags}
                            style={{ gridRow: getGridRow() }}
                            data-test-name="layout-blog-taxonomy-list"
                        />
                    </If>

                    <If condition={blogDisqus}>
                        <BlogComments
                            className="rh-mb-2 rh-layout-default"
                            postData={blogDisqus}
                            style={{ gridRow: getGridRow() }}
                        />
                    </If>

                    {/* This doesn't need a row assignment because it's got a fixed position */}
                    <If condition={Config.ENABLE_SAVE_TO_PROFILE_BUTTON && canSaveToProfile}>
                        <SaveToProfileButton
                            dataName="blog-post-SaveToProfile"
                            variant="light"
                            isFixedToBottom
                        />
                    </If>

                    {/* Transparent overlay over the layout section to ensure our sticky behaviour
                        is contained within this layout div and can have the required height it needs
                        so we don't need any JS solutions to mimic real sticky behaviour. */}
                    <If condition={shouldRenderTransparentOverlay}>
                        <div
                            className="transparent-overlay rh-display-flex rh-position-absolute rh-top-0 rh-bottom-0 rh-left-0 rh-right-0 rh-layout-full"
                            {...ROW_COUNT
                                ? {
                                    style: { gridRow: `1 / ${ROW_COUNT + 1}` },
                                }
                                : {}
                            }
                        >
                            <div className={classNames('sticky-container rh-display-flex rh-align-items-flex-end rh-top-0 rh-left-0 rh-right-0 rh-zindex-secondary-navigation',
                                { 'rh-position-sticky': !pageSettings.isSidebarFixed },
                            )}
                            >
                                <div
                                    id={ElementAttributes.STICKY_BANNER_PORTAL_ID}
                                    /* Position relative needed for z-index to take effect. Z index is required
                                        here because in the Sidebar component on mobile there's a potential overlay */
                                    className="sticky-banner-container rh-zindex-secondary-navigation rh-position-relative"
                                />

                                <If condition={shouldRenderSidebar}>
                                    <Sidebar
                                        className={classNames('sidebar-content', {
                                            'has-vertical-scroll': !pageSettings.isSidebarFixed,
                                        })}
                                    />
                                </If>
                            </div>
                        </div>
                    </If>
                </div>

                <Choose>
                    <When condition={isBlogPage}>
                        <BlogFooter
                            data-test-name="layout-blog-footer"
                            blogRelatedPosts={blogRelatedPosts}
                            blogTopics={blogTopics}
                            className={classNames({
                                [GRID_CLASSES]: !isGridDisabled,
                            })}
                        />
                    </When>
                    <When condition={footerBlocks.length}>
                        <footer
                            data-test-name="layout-footer"
                            className={classNames({
                                [GRID_CLASSES]: !isGridDisabled,
                            })}
                        >
                            <If condition={footerBlocks.length}>
                                <For
                                    each="block"
                                    of={footerBlocks}
                                    index="i"
                                >
                                    <CMSComponentSelector
                                        key={block.attrs?.rhId || `${block.blockName}-${i}`}
                                        blockMap={blockMap}
                                        rowIndex={i}
                                        {...block}
                                    />
                                </For>
                            </If>
                        </footer>
                    </When>
                </Choose>
            </main>
        </PageWrapper>
    );
}

Layout.propTypes = {
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,

    disableAccounts: PropTypes.bool,

    hideBackgroundImage: PropTypes.bool,

    requiresLogin: PropTypes.bool,

    magicLinkVariant: PropTypes.oneOf(Object.values(MAGIC_LINK_VARIANTS)),
    resumeApplicationLink: PropTypes.string,
    loginDocumentOptions: PropTypes.shape({
        document: PropTypes.object,
        documentType: PropTypes.string,
        documentToken: PropTypes.string,
        documentRedirect: PropTypes.string,
    }),

    blocks: PropTypes.array,
    blockMap: PropTypes.object,

    blogTopics: MenuTopicsPropType,
    blogRelatedPosts: PropTypes.array,
    blogDisqus: PropTypes.object,

    isGridDisabled: PropTypes.bool,
    canSaveToProfile: PropTypes.bool,
    hasStickyBanner: PropTypes.bool,

    children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]),
};

Layout.defaultProps = {
    metaTitle: undefined,
    metaDescription: undefined,

    disableAccounts: false,

    hideBackgroundImage: undefined,

    requiresLogin: false,

    magicLinkVariant: undefined,
    resumeApplicationLink: undefined,
    loginDocumentOptions: undefined,

    blocks: undefined,
    blockMap: {},

    blogTopics: undefined,
    blogRelatedPosts: undefined,
    blogDisqus: undefined,

    isGridDisabled: false,
    canSaveToProfile: false,
    hasStickyBanner: false,

    children: undefined,
};

function getGridRow() {
    ROW_COUNT += 1;

    return ROW_COUNT;
}


const PageWrapper = styled.div`
    width: 100%;

    background-image: ${props => (props.hideBackgroundImage ? 'none' : 'url(\'/static/background-circle.svg\')')};
    background-position: 20% -125rem; /* Value has to do with size of the background-circle.svg */
    background-repeat: no-repeat;

    .content-layout {
        grid-template-columns:
            1fr
            ${LayoutGlobals.ADAPTIVE_PADDING}
            minmax(auto, ${LayoutGlobals.CONTENT_MAX_WIDTH})
            ${LayoutGlobals.ADAPTIVE_PADDING}
            1fr;

        @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
            display: block;
        }

        &.with-sidebar {
            grid-template-columns:
                1fr
                ${LayoutGlobals.ADAPTIVE_PADDING}
                minmax(auto, ${LayoutGlobals.CONTENT_AREA_WIDTH})
                minmax(0, calc(${LayoutGlobals.SIDEBAR_WIDTH} + ${LayoutGlobals.SIDEBAR_OFFSET}))
                ${LayoutGlobals.ADAPTIVE_PADDING}
                1fr;

            > .transparent-overlay {
                grid-column: 4 / 7;
            }
        }

        > .transparent-overlay {
            flex-direction: column;
            pointer-events: none;

            > .sticky-container {
                top: 0;
                left: 0;
                right: 0;

                flex-direction: column;

                ${props => props.sidebarDesktopOffset && `
                    margin-top: ${props.sidebarDesktopOffset}rem;
                `};

                .sticky-banner-container {
                    width: calc(100vw - var(--scrollbar-width));
                    pointer-events: auto;
                }

                > .sidebar-content {
                    align-self: flex-start;
                    pointer-events: auto;
                    width: ${LayoutGlobals.SIDEBAR_WIDTH};
                    margin-left: ${LayoutGlobals.SIDEBAR_OFFSET};

                    &.has-vertical-scroll {
                        max-height: 100vh;
                        overflow-y: auto;
                    }
                }
            }
        }

        @media (max-width: ${LayoutGlobals.SIDEBAR_SWITCH_WIDTH}) {
            > .transparent-overlay {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                z-index: ${ZIndex.SECONDARY_NAVIGATION};

                padding: 0;

                > .sticky-container {
                    bottom: 0;
                    height: 100%;
                    pointer-events: none;
                    margin-top: 0; // Reset margin-top, which is used for desktop offset but not on mobile

                    > .sidebar-content {
                        width: 100%;
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
`;

/**
 * @private
 * Get the list of Account Dropdown navigation items
 * @param {string} locale
 * @returns
 */
function getAccountNavigationItems(locale) {
    const basePath = getDashboardBasePath(locale);
    return [
        {
            href: `${basePath}${DASHBOARD_PATHS.mortgage.fullRoute}`,
            label: MESSAGES.ACCOUNTS_MENU_ITEM_MORTGAGE_APPLICATIONS,
        },
        {
            href: `${basePath}${DASHBOARD_PATHS.autoInsurance.fullRoute}`,
            label: MESSAGES.ACCOUNTS_MENU_AUTO_INS_QUOTES,
        },
        {
            href: `${basePath}${DASHBOARD_PATHS.homeInsurance.fullRoute}`,
            label: MESSAGES.ACCOUNTS_MENU_ITEM_HOME_INS_QUOTES,
        },
        {
            href: `${basePath}${DASHBOARD_PATHS.creditCard.fullRoute}`,
            label: MESSAGES.ACCOUNTS_MENU_ITEM_CREDIT_CARD_APPLICATIONS,
        },
        {
            href: `${basePath}${DASHBOARD_PATHS.profileDetails.fullRoute}`,
            label: MESSAGES.ACCOUNTS_MENU_ITEM_PROFILE,
        },
    ];
}

const MESSAGES = defineMessages({
    ACCOUNTS_MENU_ITEM_MORTGAGE_APPLICATIONS: {
        id: 'accounts.accountsMenuItem.mortgageApplications',
        defaultMessage: 'Mortgage applications',
    },
    ACCOUNTS_MENU_AUTO_INS_QUOTES: {
        id: 'accounts.accountsMenuItem.autoInsuranceQuotes',
        defaultMessage: 'Auto insurance quotes',
    },
    ACCOUNTS_MENU_ITEM_HOME_INS_QUOTES: {
        id: 'accounts.accountsMenuItem.homeInsuranceQuotes',
        defaultMessage: 'Home insurance quotes',
    },
    ACCOUNTS_MENU_ITEM_CREDIT_CARD_APPLICATIONS: {
        id: 'accounts.accountsMenuItem.creditCardApplications',
        defaultMessage: 'Credit card applications',
    },
    ACCOUNTS_MENU_ITEM_PROFILE: {
        id: 'accounts.accountsMenuItem.profile',
        defaultMessage: 'Profile',
    },
});

export default observer(Layout);
