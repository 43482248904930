import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

// TODO: we should unify <FullScreenTakeover> and <ModalContainer>.
import ModalContainer from './FullScreenTakeover';


const Context = createContext();

/**
 * A React context provider which makes it easy to show modals within callback handlers.
 * Designed as a building block for things like lead forms
 */
function ModalProvider({ children }) {
    const state = useLocalState();

    return (
        <Context.Provider value={state}>
            <If condition={state.isOpen}>
                <ModalContainer
                    onClose={state.contents.onClose}
                    {...state.contents.modalProps}
                >
                    {/* To preserve state of each layer, render all of them, but display: none all but the top-most */}
                    <For
                        each="contents"
                        of={state._layers}
                    >
                        <div
                            key={contents.id}
                            className={classNames({
                                'rh-display-none' : contents !== state.contents,
                            })}
                        >
                            {typeof contents.children === 'function'
                                ? contents.children()
                                : contents.children
                            }
                        </div>
                    </For>
                </ModalContainer>
            </If>

            {children}
        </Context.Provider>
    );
}

ModalProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useLocalState() {
    const [ self ] = useState(() => observable({

        /**
         * @private
         * LIFO stack of the modal layer to render. Allows for easy handling of nested modals.
         * @param {string} id unique identifier for this entry
         * @param {object} children what to render inside the modal
         * @param {function} onClose callback to invoke when the modal is closed
         * @param {object} modalProps all other props to configure the modal itself
         */
        _layers: [],

        /** Configuration we are displaying */
        get contents() {
            return self._layers.length > 0
                ? self._layers[self._layers.length - 1]
                : null;
        },

        /** If a modal is being displayed */
        get isOpen() {
            return self.contents != null;
        },

        /**
         * Begin to render a modal.
         * If a modal is already being shown, this will become the new top-most layer.
         * @param {object} params
         * @param {React.node|function} params.children contents of the modal
         * @param {function} params.onClose callback when this layer is closed
         * @param {boolean} [params.isCloseVisible=true] specify false to hide the close box
         * @param {object} params.modalProps everything else which will be passed to the <FullScreenTakeover>
         * @returns {string} the unique ID of the modal layer which was created
         */
        open({ children, onClose, isCloseVisible = true, ...modalProps }) {
            const id = uuid();

            self._layers.push({
                id,
                children,
                onClose: isCloseVisible
                    ? (() => {
                        self.close(id);
                        onClose?.();
                    })
                    : undefined,
                modalProps,
            });

            return id;
        },

        /**
         * Stop rendering modal contents.
         * @param {string} id if passed, closes a specific modal layer. Otherwise closes the top-most layer.
         */
        close(id) {
            if (id === undefined) {
                this._layers.pop();
            } else {
                self._layers = self._layers.filter(e => e.id !== id);
            }
        },
    }));

    return self;
}

function useModal() {
    return useContext(Context);
}

export {
    useModal,
};
export default observer(ModalProvider);
