import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconGift({ outlineWidth, stroke, strokeWidth, fill, ...otherProps }) {
    return (
        <IconGift.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M37.23 22.85c2.08 0 5.5-1.76 5.5-4.95 0-2.77-2.24-2.75-3.3-2.75-1.65 0-5.06 1.32-8.25
                7.7M25.13 22.85c-2.08 0-5.5-1.76-5.5-4.95 0-2.77 2.24-2.75 3.3-2.75 1.65 0 5.06 1.32 8.25 7.7"
            />
            <path d="M46.87 28.9h-31.9v-5c0-.58.52-1.05 1.1-1.05h29.96c.58 0 .84.29.84.87v5.18zM44.25 44.84l-26.48.01c-.6
                0-1.16-.49-1.16-1.11V28.9h28.6v14.83c.01.62-.35 1.11-.96 1.11zM30.92 22.53v22.32"
            />
        </IconGift.Svg>
    );
}

IconGift.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconGift.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    strokeWidth: '2px',
    fill: Colours.TRANSPARENT,
};

IconGift.Svg = styled.svg`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconGift;
