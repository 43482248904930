import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import EASINGS from '../definitions/Easings';


// OpacityContainer forwards a ref to this component so it doesn't need to
// output its own DOM element.
const AnimatedOpacityContainer = forwardRef(({
    isOpaque,
    duration,
    minOpacity,
    delay,
    easing,
    children,
    ...otherProps
}, ref) => {
    return (
        <Container
            ref={ref}
            $isOpaque={isOpaque}
            $duration={duration}
            $minOpacity={minOpacity}
            $delay={delay}
            $easing={easing}
            {...otherProps}
        >
            {children}
        </Container>
    );
});

AnimatedOpacityContainer.propTypes = {
    isOpaque: PropTypes.bool,
    duration: PropTypes.number, // in milliseconds
    minOpacity: PropTypes.number,
    delay: PropTypes.number, // in milliseconds
    easing: PropTypes.oneOf(Object.values(EASINGS)),

    children: PropTypes.node.isRequired,
};

AnimatedOpacityContainer.defaultProps = {
    isOpaque: false,
    duration: 600,
    minOpacity: 0,
    delay: 0,
    easing: EASINGS.OUT,
};

const Container = styled.div`
    opacity: ${props => props.$isOpaque ? '1' : props.$minOpacity};
    transition: opacity ${props => props.$duration}ms ${props => props.$delay}ms ${props => props.$easing};

    &:focus-within {
        opacity: 1;
    }
`;

export default AnimatedOpacityContainer;
export { EASINGS };
