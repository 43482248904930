import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useIntl, defineMessages } from 'react-intl';
import styled from 'styled-components';

import Sizes from '../definitions/Sizes';
import ReviewStar from '../graphics/ReviewStar';


function StarRating({ ratingPercent, starSize, ...otherProps }) {
    const intl = useIntl();

    const approximateStars = Math.round(ratingPercent / (50 / NUMBER_OF_STARS)) * 0.5;
    const ariaLabel = intl.formatMessage(
        MESSAGES.starsARIALabel,
        { approximateStars: approximateStars, maxStars: NUMBER_OF_STARS },
    );

    return (
        <Container
            role="img"
            aria-label={ariaLabel}
            starSize={starSize}
            data-test-name="star-rating"
            {...otherProps}
        >
            <For
                each="starWidth"
                of={getStarWidths(NUMBER_OF_STARS, ratingPercent)}
                index="index"
            >
                <ReviewStar
                    className="star"
                    key={index}
                    percentWidth={starWidth}
                />
            </For>
        </Container>
    );
}

StarRating.propTypes = {
    ratingPercent: PropTypes.number.isRequired,
    starSize: PropTypes.string,
};

StarRating.defaultProps = {
    starSize: Sizes.ICONS.XS,
};


const NUMBER_OF_STARS = 5;


function getStarWidths(starCount, ratingPercent, roundToHalves = true) {
    const percentPerStar = 100 / starCount;

    let remainder = ratingPercent;
    let widths = [];

    for (let nIt = 0; nIt < starCount; ++nIt) {
        const starAmountStillToDisplay = remainder / percentPerStar;

        if (starAmountStillToDisplay >= 1) {
            widths.push(100);
        } else if (roundToHalves) {
            widths.push(Math.round(starAmountStillToDisplay * 2) * 50);   // 0% or 50% of a star
        } else {
            widths.push(starAmountStillToDisplay * 100);  // whatever %'age is left: 0 - 99%
        }

        remainder = Math.max(0, remainder - percentPerStar);
    }

    return widths;
}


const Container = styled.span`
    font-size: 0;
    white-space: nowrap;

    .star {
        ${({ starSize }) => `
            width: ${starSize};
            height: ${starSize};
        `};
        margin-right: ${Sizes.SPACING.EIGHTH};
    }
`;

const MESSAGES = defineMessages(
    {
        starsARIALabel: {
            id: 'base-ui.star-rating-some',
            defaultMessage: '{approximateStars} out of {maxStars} stars',
        },
    },
);


export default observer(StarRating);
