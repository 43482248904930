import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import MessagePropType from '../definitions/MessagePropType';
import FieldPropType from '../definitions/FieldPropType';
import { QUESTION_ANIMATION_DURATION_MS } from '../definitions/AnimationDurations';
import useForm from '../hooks/useForm';
import InputSelector from './InputSelector';
import AnimatedContainer, { AnimationTypePropType, ANIMATION_TYPES } from './AnimatedContainer';


function QuestionCompact({
    field,
    type,

    isVisible,
    animationType,

    labelMessage,

    className,
    inputClassName,

    shouldRemoveMargin,

    ...otherProps
}) {
    const form = useForm();

    // We want to handle the field state visibility ourselves, since we're going to do visibility
    // animation in this component. We don't want to worry about animation synchronization.
    useEffect(() => {
        form?.setFieldVisibility(field.name, isVisible);
    }, [ isVisible, form, field.name ]);

    return (
        <AnimatedContainer
            className={classNames(className, 'rh-text-align-left', {
                'rh-mt-2': !shouldRemoveMargin,
                'rh-mb-2_5': !shouldRemoveMargin,
            })}
            isVisible={isVisible}
            type={animationType}
            duration={QUESTION_ANIMATION_DURATION_MS}
            alwaysRenderChildMarkup
        >
            <InputSelector
                field={field}
                type={type}
                label={labelMessage}
                inputClassName={inputClassName}
                {...otherProps}
            />
        </AnimatedContainer>
    );
}

QuestionCompact.propTypes = {
    field: FieldPropType.isRequired,
    type: PropTypes.string,

    labelMessage: MessagePropType.isRequired,

    className: PropTypes.string,
    inputClassName: PropTypes.string,

    isVisible: PropTypes.bool,
    animationType: AnimationTypePropType,

    shouldRemoveMargin: PropTypes.bool,
};

QuestionCompact.defaultProps = {
    className: undefined,
    inputClassName: undefined,

    type: undefined,

    isVisible: true,
    animationType: ANIMATION_TYPES.FADE,

    shouldRemoveMargin: false,
};

export default observer(QuestionCompact);
