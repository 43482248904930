import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import DefaultIntlFormatValues from '../definitions/DefaultIntlFormatValues';
import CheckmarkBullet from './CheckmarkBullet';


function FormattedListWithCheckmarks({ message, className, values, liClassName, ...otherProps }) {
    return (
        <ListWithCheckmarks
            className={className}
            {...otherProps}
        >
            <FormattedMessage
                {...message}
                values={{
                    ...DefaultIntlFormatValues,
                    li: str => (
                        // use liClassName to establish vertical spacing between each list item
                        <li className={classNames('rh-display-flex rh-align-items-flex-start', liClassName)}>
                            <CheckmarkBullet />
                            <span>
                                {str}
                            </span>
                        </li>
                    ),
                    ...values,
                }}
            />
        </ListWithCheckmarks>
    );
}

FormattedListWithCheckmarks.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    values: PropTypes.any,
    liClassName: PropTypes.string,
};

FormattedListWithCheckmarks.defaultProps = {
    className: undefined,
    values: undefined,
    liClassName: undefined,
};


const ListWithCheckmarks = styled.ul`
    list-style-type: none;
`;

export default FormattedListWithCheckmarks;
