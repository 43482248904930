import React from 'react';
import PropTypes from 'prop-types';

import {
    PrimaryAnchor,
    AlternateAnchor,
    TextAnchor,
    AnchorWithIcon,
} from '@ratehub/base-ui';


const CTA_TYPES = {
    PRIMARY_ANCHOR: 'primary-anchor',
    ALTERNATE_ANCHOR: 'alternate-anchor',
    ANCHOR_WITH_ICON: 'anchor-with-icon',
    TEXT_ANCHOR: 'text-anchor',
};

const SUPPORTS_SIZE = [
    CTA_TYPES.PRIMARY_ANCHOR,
    CTA_TYPES.ALTERNATE_ANCHOR,
];

function CTAChooser({ type, minWidth, maxWidth, dataName, ...otherProps }) {
    const componentStyles = {
        minWidth,
        maxWidth,
    };

    return (
        <Choose>
            <When condition={type === CTA_TYPES.PRIMARY_ANCHOR}>
                <PrimaryAnchor
                    style={componentStyles}
                    data-name={dataName}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.ALTERNATE_ANCHOR}>
                <AlternateAnchor
                    style={componentStyles}
                    data-name={dataName}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.ANCHOR_WITH_ICON}>
                <AnchorWithIcon
                    style={componentStyles}
                    data-name={dataName}
                    {...otherProps}
                />
            </When>

            <When condition={type === CTA_TYPES.TEXT_ANCHOR}>
                <TextAnchor
                    style={componentStyles}
                    data-name={dataName}
                    {...otherProps}
                />
            </When>
        </Choose>
    );
}

CTAChooser.propTypes = {
    type: PropTypes.oneOf(Object.values(CTA_TYPES)),
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    dataName: PropTypes.string,
};

CTAChooser.defaultProps = {
    type: CTA_TYPES.PRIMARY_ANCHOR,
    minWidth: undefined,
    maxWidth: undefined,
    dataName: undefined,
};

CTAChooser.CTATypes = CTA_TYPES;
CTAChooser.SupportsSize = SUPPORTS_SIZE;

CTAChooser.blockKey = 'rh/cta-chooser';
CTAChooser.hasSpacingOptions = true;

export default CTAChooser;
