import { noticeError } from '@ratehub/base-ui';

import CARD_TYPES from '../definitions/CardTypes';
import REWARD_TYPES from '../definitions/RewardTypes';
import TRAVEL_FEATURES from '../definitions/TravelFeatures';
import DEFAULT_CARD_PREFERENCES from '../definitions/DefaultCardPreferences';
import getProductType from './getProductType';
import getCreditScoreRange from './getCreditScoreRange';
import replaceShortcodesInRatehubReview from './replaceShortcodesInRatehubReview';
import getApprovalLikelihoodFromAPIResponse from './getApprovalLikelihoodFromAPIResponse';


/**
 * Creates a CardInfoSynopsis object from the given credit card API response.
 *
 * @param {Object} card - credit card API response data.
 * @param {Object} [userInfo=DEFAULT_CARD_PREFERENCES] - contextual user information, typically as provided to CardFinder wizard.
 * @param {number} [userInfo.monthlySpendingAverage] - average monthly spending.
 * @param {number} [userInfo.lowInterestMonthlyBalance.lowInterestMonthlyBalance] - monthly balance for low interest.
 * @param {number} [userInfo.balanceTransferAmount] - amount for balance transfer.
 * @param {number} [userInfo.securedMonthlyBalance] - monthly balance for secured card.
 * @param {string[]} [userInfo.chosenFeatures] - user's chosen travel features.
 * @return {CreditCardSynopsisShape} - cardInfo synopsis object.
 */
function createCardSynopsisFromAPIResponse(
    card,
    {
        monthlySpendingAverage,
        lowInterestMonthlyBalance,
        balanceTransferAmount,
        securedMonthlyBalance,
        chosenFeatures,
    } = DEFAULT_CARD_PREFERENCES
) {
    const productType = getProductType(card.types.comparison);

    // >> • Remember to update extractRhAnalyticsFromCreditCard(), but only if the new field is relevant to analytics.

    const annualFee = card.fees.subsequent_year_annual;
    const firstYearFee = card.fees.first_year_annual;
    const purchaseInterest = card.purchase_interest?.rate ?? null;

    // new to synopsis for summer-2023
    // within the return, we will also add subsequentYearPointsInDollars, based on a property of rewardProperties
    const rewardProperties = getRewardsProperties({
        calculatedRewards: card.calculated_rewards,
        pointSystem: card.point_system,
        monthlySpendingAverage,
    });


    return {
        id: card.ids?.primary,
        primaryId: card.ids?.primary,
        name: card.name,
        slug: card.slug,        // localized by response
        bank: card.provider.ids.primary,    // sometimes referred to as 'provider'; slug
        providerName: card.provider.name,   // using updated terminology: provider, not bank, as historically above
        network: card.network,  // a slug
        productType: productType,
        displayType: card.types?.display ?? productType,

        rank: card.rank ?? 0,

        isMonetized: !!card.monetized,
        isSponsored: typeof(card.sponsored) === 'boolean'
            ? card.sponsored
            : !!card.sponsored.table,

        cardImageURL: card.urls?.image,
        detailsURL: card.urls?.details,
        applyRedirectURL: card.urls?.apply_redirect,

        // undefined or null each map to null (will show N/A in footer); 0% is now allowed
        purchaseInterest,
        cashAdvanceInterest: card.cash_advance_interest?.rate ?? null,
        balanceTransferInterest: card.balance_transfer_interest?.rate ?? null,

        approvalLikelihood: getApprovalLikelihoodFromAPIResponse(card.approval_likelihood),
        // raw approval likelihood should not exceed 100%, but we have seen mistakes where it has
        approvalLikelihoodScore: typeof card.approval_likelihood_raw === 'number'
            ? Math.min(card.approval_likelihood_raw, 100)
            : null, // if NaN approvalLikelihoodScore is assumed to be unknown

        ...getGiftCardOffer(card.gift_cards),

        earningsDescription: Array.isArray(card.descriptions?.rewards)
            ? card.descriptions.rewards.map(rewardsDesc => {
                return {
                    rate: rewardsDesc.rate,
                    types: rewardsDesc.categories,
                    description: rewardsDesc.copy,
                };
            })
            : [],

        ...(card?.eligibility && (card?.eligibility?.deposit >= 0)
            && { minimumDeposit: parseFloat(card.eligibility.deposit) }),   // secured cards

        annualFee: annualFee,
        firstYearFee: firstYearFee,
        isFirstYearFeeWaived: !!(annualFee && !firstYearFee),   // Note: will be false if first and subsequent are both 0

        bestUsedFor: card.descriptions?.best_used_for?.trim(),
        description: card.descriptions?.badge?.trim(),
        welcomeBonusDescription: card.descriptions?.badge_bonus?.trim(),
        anniversaryBonusDescription: card.descriptions?.badge_anniversary_bonus?.trim(),

        anniversaryBonus: {
            points: card.calculated_rewards?.bonuses?.anniversary.points,
            value: card.calculated_rewards?.bonuses?.anniversary.value,
        },

        cardTypes: getCardTypesFromAPI(card),

        // TODO: possible removal with CCDEP-2110 if analytics is not using these fields
        rewardTypes: getRewardTypesFromAPI(card),
        userCategory: card.user_category,

        ...rewardProperties,
        subsequentYearPointsInDollars: Math.floor(rewardProperties.totalSubsequentYearPoints * rewardProperties.averagePointValue),

        ...getSignupBonusProperties(card?.calculated_rewards?.bonuses),
        ...getRatehubReviewBasics(card),

        // if credit_score is missing we'll set the minimumCreditScore to null, not undefined;
        minimumCreditScore: getCreditScoreRange(card.eligibility.credit_score.public)?.min ?? null,
        minimumIncome: card.eligibility.income.minimum.public || 0,

        bonusDescriptionMarkup: card.descriptions.bonus,
        ...getTravelProperties({
            pointRedemption: card.point_redemption,
            features: card.features,
            chosenFeatures,
        }),

        ...getLowInterestProperties({
            purchaseInterest,
            lowInterestMonthlyBalance,
        }),

        ...getBalanceTransferProperties({
            balanceTransferFee: card.fees?.balance_transfer,
            balanceTransferAmount,
            balanceTransferInterestPromotional: card.balance_transfer_interest.promotional,
        }),

        ...getSecuredProperties({
            purchaseInterest,
            securedMonthlyBalance,
        }),
    };
}

const BALANCE_TRANSFER_FEE_PERCENTAGE_DEFAULT = 3;

const API_TO_CARD_TYPE = {
    'rewards': CARD_TYPES.REWARDS,
    'low-interest': CARD_TYPES.LOW_INTEREST,
    'balance-transfer': CARD_TYPES.BALANCE_TRANSFER,
    'secured': CARD_TYPES.SECURED,
};

const API_TO_REWARD_TYPES = {
    'travel': REWARD_TYPES.TRAVEL,
    'cash-back': REWARD_TYPES.CASH_BACK,
    'store-credit': REWARD_TYPES.STORE_CREDIT,
};

// TODO update these values elsewhere + remove this mapping
const API_FEATURE_MAP = {
    'baggage-delay':        TRAVEL_FEATURES.BAGGAGE_DELAY,
    'baggage-loss':         TRAVEL_FEATURES.BAGGAGE_LOSS,
    'car-rental':           TRAVEL_FEATURES.CAR_RENTAL,
    'extended-warranty':    TRAVEL_FEATURES.EXTENDED_WARRANTY,
    'flight-delay':         TRAVEL_FEATURES.FLIGHT_DELAY,
    'lounge-access':        TRAVEL_FEATURES.LOUNGE_ACCESS,
    'mobile-insurance':     TRAVEL_FEATURES.MOBILE_INSURANCE,
    'no-forex':             TRAVEL_FEATURES.NO_FOREX,
    'price-protection':     TRAVEL_FEATURES.PRICE_PROTECTION,
    'purchase-assurance':   TRAVEL_FEATURES.PURCHASE_ASSURANCE,
    'travel-accident':      TRAVEL_FEATURES.TRAVEL_ACCIDENT,
    'travel-emergency':     TRAVEL_FEATURES.TRAVEL_EMERGENCY,
    'trip-cancellation':    TRAVEL_FEATURES.TRIP_CANCELLATION,
    'trip-interruption':    TRAVEL_FEATURES.TRIP_INTERRUPTION,
    'hotel-motel-burglary':    TRAVEL_FEATURES.HOTEL_MOTEL_BURGLARY,
};

/**
 * map back-end card properties for Travel cards to “cardInfo” property names, with default values for some
 *
 * @param {object} pointRedemption
 * @param {string} pointRedemption.flexibility_level low | medium | high
 * @param {string} pointRedemption.copy.travel plain text of how to redeem
 * @param {string[]} [features=[]] api strings for each card feature
 * @param {string[]} chosenFeatures user’s wizard feature preferences
 * @return {object} contains travel-related properties
 */
function getTravelProperties({ pointRedemption, features = [], chosenFeatures }) {
    const cardFeatures = features.map(feature => API_FEATURE_MAP[feature]);

    return {
        redemptionFlexibility: {
            level: pointRedemption?.flexibility_level,
            description: pointRedemption?.copy.travel || '',
        },

        // features is left as an array of slugs: the intersection of card features and user’s selection
        // but if chosenFeatures is nullish, we show all card features with no filtering
        features: chosenFeatures
            ? chosenFeatures.filter(apiSlug => cardFeatures.includes(apiSlug)) || []
            : cardFeatures,
    };
}


function getCardTypesFromAPI(card) {
    const cardTypes = (card.types?.primary || []).map(type => API_TO_CARD_TYPE[type]);

    if (cardTypes.some(cardType => !cardType)) {
        const cardId = card.ids?.primary;

        noticeError(new RangeError(
            `[${cardId} • ${card.name}]\n\t• Unrecognized card type in [${card.types?.primary}]`,
        ), {
            cardId: cardId,
            cardTypes: card.types?.primary,
            targetKeys: Object.keys(API_TO_CARD_TYPE),
        });
    }

    return cardTypes.filter(cardType => cardType);
}

function getRewardTypesFromAPI(card) {
    const rewardTypes = (card.types?.rewards || []).map(type => API_TO_REWARD_TYPES[type]);

    if (rewardTypes.some(rewardType => !rewardType)) {
        const cardId = card.ids?.primary;

        noticeError(new RangeError(
            `[${cardId} • ${card.name}]\n\t• Unrecognized reward type in [${card.types?.rewards}]`,
        ), {
            cardId: cardId,
            rewardTypes: card.types?.rewards,
            targetKeys: Object.keys(API_TO_REWARD_TYPES),
        });
    }

    return rewardTypes.filter(rewardType => rewardType);
}


/**
 * Get FE giftCardOffer structure from the API response.
 * This still reflects that we support multiple gift offers, but that is not our current practice.
 * For example, the description and dollar value seen in the FE consider only the first card found.
 *
 * @param gift_cards
 * @return {{giftCardOffer: {promoDetailsHTML, maxDollarValue, giftCards: *}}|undefined}
 */
function getGiftCardOffer(gift_cards) {
    const firstCard = gift_cards?.[0];

    return firstCard?.description && firstCard?.amount && firstCard?.name
        ? {
            giftCardOffer: {
                promoDetailsHTML: firstCard.description,
                maxDollarValue: firstCard.amount,
                offerType: firstCard.name,          // expected to morph into an enumerated type
                ...firstCard?.expiry_date && { expiryDate: new Date(firstCard.expiry_date) },
                giftCards: gift_cards.map(giftCard => {
                    return {
                        // name has been repurposed to be a text description of the offer and is required
                        // for example: "gift card", "cash bonus", or "rebate"
                        name: giftCard.name,
                        amount: giftCard.amount,
                        cardImageURL: giftCard.image_url,
                        ...giftCard?.expiry_date && { expiryDate: new Date(giftCard.expiry_date) },
                    };
                }),
            },
        }
        : undefined;
}


/**
 * map back-end card properties for Rewards cards to “cardInfo” property names, with default values for some
 *
 * @param {object} calculatedRewards back-end data for a single credit card
 * @param {object} pointSystem back-end data for a card’s point system
 * @param {number} monthlySpendingAverage
 * @return {object} contains rewards-related properties
 */
function getRewardsProperties({ calculatedRewards, pointSystem, monthlySpendingAverage }) {
    return calculatedRewards === undefined
        ? {
            netFirstYearValue: null,
            netSubsequentYearValue: null,
            totalSubsequentYearPoints: null,

            welcomeSignUp: null,
            welcomeSpending: null,

            firstYearPointsInDollars: null,
            firstYearRewardPoints: null,

            averagePointValue: null,
            monthlySpendingAverage,
        }
        : {
            netFirstYearValue: calculatedRewards.first_year.net_value,
            netSubsequentYearValue: calculatedRewards.subsequent_years.net_value,

            totalSubsequentYearPoints: Math.floor(
                calculatedRewards.subsequent_years.tiers.reduce(
                    (sum, { points }) => sum + points,
                    0,      // start at 0; do not roll anniversary points in here
                ),
            ),

            welcomeSignUp: {
                points: calculatedRewards.bonuses?.welcome_signup.points,
                value: Math.floor(calculatedRewards.bonuses?.welcome_signup.value),
            },

            welcomeSpending: {
                points: calculatedRewards.bonuses?.welcome_spending.points,
                value: Math.floor(calculatedRewards.bonuses?.welcome_spending.value),
            },

            firstYearPointsInDollars: Math.floor(calculatedRewards.first_year.tiers
                .reduce((sum, { value }) => sum + value, 0)),

            firstYearRewardPoints: Math.floor(calculatedRewards.first_year.tiers
                .reduce((sum, { points }) => sum + points, 0)),

            // currently only used by card details
            averagePointValue: Math.max(...Object.values(pointSystem.dollar_values)),

            monthlySpendingAverage,
        };
}


/**
 * Sums Welcome signup and Welcome spending bonuses for each of points and dollars.
 *
 * @param {Object} bonuses
 * @param {Object} bonuses.welcome_signup
 * @param {number} bonuses.welcome_signup.points
 * @param {number} bonuses.welcome_signup.value
 * @param {string} bonuses.details
 * @returns {{signUpBonusPoints: number, signUpBonusDollars: number, welcomeBonus: Object}|undefined}
 */
function getSignupBonusProperties(bonuses) {
    return bonuses
        ? {
            signUpBonusPoints: bonuses.welcome_signup.points + bonuses.welcome_spending.points,
            signUpBonusDollars: Math.floor(bonuses.welcome_signup.value + bonuses.welcome_spending.value),
            welcomeBonus: bonuses.details,  // just a points summary
        }
        : undefined;
}


/**
 * If the API response includes ratehub_review.content, return them within a ratehubReview object.
 * Note that createCardInfoFromAPIResponse will overwrite this structure by
 * including additional information, like author and dates.
 *
 * @param {object} card
 * @return {{ratehubReview: {rating, content}}|undefined}
 */
function getRatehubReviewBasics(card) {
    // synopsis ratehubReview will remain `undefined` if no content
    return card.ratehub_review?.content != null
        ? {
            ratehubReview: {
                rating: card.ratehub_review.rating,
                content: replaceShortcodesInRatehubReview(card),
            },
        }
        : undefined;
}


function getLowInterestProperties({ purchaseInterest, lowInterestMonthlyBalance }) {
    if (purchaseInterest !== null && purchaseInterest >= 0 && lowInterestMonthlyBalance >= 0) {
        const lowInterestAnnualInterestFee = lowInterestMonthlyBalance * purchaseInterest / 100;

        return {
            lowInterestAnnualInterestFee: lowInterestAnnualInterestFee,
            lowInterestMonthlyInterestFee: lowInterestAnnualInterestFee / 12,

            lowInterestMonthlyBalance,
        };
    } else {
        return {};
    }
}


/**
 * map back-end card properties for Balance Transfer cards to “cardInfo” property names, with default values for some
 *
 * @param {number} balanceTransferFee fee for balance transfers
 * @param {number} balanceTransferAmount how much the user wants to transfer to a new card
 * @param {number} balanceTransferInterestPromotional balance transfer promotional information
 * @param {number} balanceTransferInterestPromotional.term months of offer
 * @param {number} balanceTransferInterestPromotional.rate special rate during offer
 * @return {object} contains balance transfer-related properties
 */
function getBalanceTransferProperties({
    balanceTransferFee,
    balanceTransferAmount,
    balanceTransferInterestPromotional,
}) {
    // balanceTransferPromoMonths used below to conditionally spread additional dependent props
    const balanceTransferPromoMonths = balanceTransferInterestPromotional.term;
    const balanceTransferPromoInterest = balanceTransferPromoMonths
        ? balanceTransferInterestPromotional.rate || 0
        : undefined;

    let balanceTransferFeeRate,
        balanceTransferPromoMonthlyInterestFee;

    if (
        balanceTransferAmount >= 0
        && balanceTransferPromoInterest >= 0
        && balanceTransferPromoMonths > 0
    ) {
        // balanceTransferFeeRate used below to conditionally spread additional dependent props
        balanceTransferFeeRate = balanceTransferFee ?? BALANCE_TRANSFER_FEE_PERCENTAGE_DEFAULT;
        balanceTransferPromoMonthlyInterestFee = balanceTransferAmount * (balanceTransferPromoInterest / 100 / 12);
    }

    return {
        ...(typeof balanceTransferPromoMonths !== 'undefined' && {
            balanceTransferPromoInterest,
            balanceTransferPromoMonths,
        }),

        ...(typeof balanceTransferFeeRate !== 'undefined' && {
            balanceTransferAmount,
            balanceTransferFeeRate,
            balanceTransferFee: balanceTransferAmount * balanceTransferFeeRate / 100,
            balanceTransferMonthlyPayment:
                balanceTransferAmount / balanceTransferPromoMonths + balanceTransferPromoMonthlyInterestFee,
        }),
    };
}


function getSecuredProperties({ purchaseInterest, securedMonthlyBalance }) {
    return {
        ...(purchaseInterest >= 0 && securedMonthlyBalance >= 0
            && { securedMonthlyInterestFee: securedMonthlyBalance * purchaseInterest / 100 / 12 }),

        ...(securedMonthlyBalance >= 0
            && { securedMonthlyBalance: parseFloat(securedMonthlyBalance) }),
    };
}


export default createCardSynopsisFromAPIResponse;
