import createBaseConversionPayload from './createBaseConversionPayload';
import getCreditScoreRange from './getCreditScoreRange';


/**
 * Constructs the credit card lead payload to send to the conversion service
 * @param {Object} card FE “cardInfo” structure for credit card data
 * @param {Object} lead basic, minimal lead information
 * @param {string} lead.email the lead’s email address
 * @param {Object} lead.giftCard the lead’s chosen gift card
 * @param {string} lead.language language abbreviation: en or fr
 * @param {string} [lead.creditScore] CardFinder slug for credit score
 * @param {string} [lead.income] yearly income dollar amount
 * @param {string} [lead.refererUrl] an override to the default refererUrl found in the header for tracking
 * @param {Object} [location] The user's city and province info
 * @param {Object} experiments
 * @return {Object}
 */
function createLeadConversionPayload({ card, lead, location, experiments }) {
    const basePayload = createBaseConversionPayload({ 
        card, 
        location, 
        language: lead.language, 
        experiments, 
        refererUrl: lead.refererUrl, 
    });
    const leadDetails = getLeadDetails(lead);

    return {
        ...basePayload,
        type: 'SUBMITTED_LEAD',
        lead: {
            city: location.cityID,
            province: location.provinceCode,
            firstName: lead.firstName ?? '',
            lastName: lead.lastName ?? '',
            email: lead.email,
            phone: lead.phone ?? null,
            notes: '',
            subscribe: false,
            contacted: false,
            details: leadDetails,
        },
    };
}

/**
 * construct an object with additional details about this lead
 *
 * @param {Object} lead
 * @param {Object} [lead.giftCard] gift card info
 * @param {string} [lead.giftCard.name] name of the gift card
 * @param {number} [lead.giftCard.amount] value of the gift card
 * @param {string} [lead.creditScore] CardFinder slug of user’s credit score
 * @param {number} [lead.income] user’s yearly income
 */
function getLeadDetails(lead) {
    const creditScoreMax = getCreditScoreRange(lead.creditScore)?.max;

    return Object.assign({},
        creditScoreMax && { creditScore: creditScoreMax },
        lead.income && { income: lead.income },
        lead?.giftCard?.name && { giftCardType: lead.giftCard.name },
        lead?.giftCard?.amount && { offerAmount: lead.giftCard.amount },
    );
}

export default createLeadConversionPayload;
