import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import MessageStyles from '../definitions/MessageStyles';
import MessagePropType from '../definitions/MessagePropType';
import getComponentDisplayName from '../functions/getComponentDisplayName';
import ErrorMessageContainer from './ErrorMessageContainer';


function withErrorMessageContainer(Component) {
    function WrappedComponent({
        errorMessage,
        errorMessageStyle,
        shouldShowInvalid, // component needs to do conditional rendering
        className,
        ...componentProps
    }) {
        return (
            <ErrorMessageContainer
                message={errorMessage}
                messageStyle={errorMessageStyle}
                isInline={componentProps.isInline}
                isInvalid={shouldShowInvalid}
                className={className}
            >
                <Component
                    shouldShowInvalid={shouldShowInvalid}
                    {...componentProps}
                />
            </ErrorMessageContainer>
        );
    }

    // Helpful for debugging stack traces
    WrappedComponent.displayName = `withErrorMessageContainer(${getComponentDisplayName(Component)})`;

    WrappedComponent.propTypes = {
        className: PropTypes.string,
        errorMessage: MessagePropType,
        errorMessageStyle: PropTypes.oneOf(Object.values(MessageStyles)),
        shouldShowInvalid: PropTypes.bool,
    };

    WrappedComponent.defaultProps = {
        className: undefined,
        errorMessage: undefined,
        errorMessageStyle: MessageStyles.DEFAULT,
        shouldShowInvalid: false,
    };

    return observer(WrappedComponent);
}

withErrorMessageContainer.propTypes = {
    Component: PropTypes.element,
};

export default withErrorMessageContainer;
