import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import MessagePropType from '../definitions/MessagePropType';
import toFloat from '../functions/toFloat';
import FormattedNumberInput from './FormattedNumberInput';
import InputController from './InputController';


function NumberInput({
    id,
    name,
    value,

    decimalScale,
    allowNegative,

    onChange,
    onValidityChange,
    onValidate,
    onBlur,

    label,
    placeholder,

    canShowInvalid,

    onCreateField,

    ...otherProps
}) {
    return (
        <InputController 
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            onBlur={onBlur}
            defaultErrorMessage={DEFAULT_ERROR_MESSAGE}
            canShowInvalid={canShowInvalid}
            getFormattedValue={value => value?.toString()}
            getParsedValue={toFloat}
            onCreateField={onCreateField}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                function handleValueChange(values) {
                    // This component requires the `value` node off of the `values` object
                    // which just sends an unformatted string.
                    inputProps.onChange(values.value);
                }
    
                return (
                    <FormattedNumberInput
                        {...inputProps}
                        label={label}
                        placeholder={placeholder}
                        onChange={handleValueChange}

                        decimalScale={decimalScale}
                        allowNegative={allowNegative}

                        errorMessage={inputState.errorMessage}
                        isInvalid={inputState.isInvalid}
                        shouldShowInvalid={inputState.shouldShowInvalid}
                    />
                );
            }}
        </InputController>
    );
}

NumberInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string, // legacy support; will be parsed to a number
    ]),

    decimalScale: PropTypes.number,
    allowNegative: PropTypes.bool,

    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,
    onBlur: PropTypes.func,

    label: MessagePropType,
    placeholder: MessagePropType,
    
    canShowInvalid: PropTypes.bool,
    onCreateField: PropTypes.func,
};

NumberInput.defaultProps = {
    id: undefined,
    value: undefined,
    decimalScale: undefined,
    allowNegative: true,
    onChange: undefined,
    onValidityChange: undefined,
    onValidate: undefined,
    onBlur: undefined,
    label: undefined,
    placeholder: undefined,
    canShowInvalid: undefined,
    onCreateField: undefined,
};

const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage
        id="base-ui.number-input.errors.default"
        defaultMessage="Please enter a valid number"
    />
);

export default NumberInput;
