import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    noticeError,
    useModal,
    trackHeapEvent,
    getReferrerURL,
    getAffiliateData,
    AFFILIATE_QUERY_PARAM_KEYS,
    ModalFullScreenTakeoverStyles,
    LightboxFullScreenTakeoverStyles,
} from '@ratehub/base-ui';

import INCENTIVE_FULLSCREEN_TAKEOVER_STYLES from '../definitions/IncentiveFullScreenTakeoverStyles';
import trackHeapCCWebClickEvent from '../functions/trackHeapCCWebClickEvent';
import trackHeapCCRedirectEvent from '../functions/trackHeapCCRedirectEvent';
import navigateNewTabToMoreDetails from '../functions/navigateNewTabToMoreDetails';
import CreditCardIncentive from '../components/CreditCardIncentive';


/**
 * Creates a callback for applying for a credit card.
 * When the card features a gift card offer it will open the gift card modal before proceeding with normal processing.
 *
 * @returns {(function({
 *      cardInfo: {CreditCardSynopsisShape},
 *      [userInfo: {UserInfoCCPropTypes}],
 *      [onApplyNow: onApplyNowCallback],
 *      [otherProps]: *})
 *      : (null|undefined))|*}
 */
function useApplyForCreditCardCallback(isCompactIncentive) {
    const modal = useModal();

    return useCallback(
        /**
         * To be invoked when a user is applying for a credit card so that it will open the gift card modal when needed.
         *
         * @param {CreditCardSynopsisShape} cardInfo
         * @param {Object} [cardInfo.giftCardOffer]
         * @param {UserInfoCCPropTypes} [userInfo]
         * @param {onApplyNowCallback} [onApplyNow]
         * @param {string} [provinceCode]
         * @param {string} [languageCode]
         * @param {string} [applyURLOverride]
         * @param {*} [otherProps]
         * @returns {null}
         */
        ({
            cardInfo,
            userInfo,
            onApplyNow,
            applyURLOverride,
            provinceCode,
            languageCode,
            ...otherProps
        }) => {
            if (!cardInfo) {
                noticeError(new RangeError('`cardInfo` argument to credit card callback must not be empty.'));

                return null;
            }

            trackHeapCCWebClickEvent(cardInfo);

            const { isMonetized, applyRedirectURL, detailsURL, isSponsored, bank } = cardInfo;

            // gift card offer is enabled when there is a gift card offer
            const isGiftCardEnabled = cardInfo.giftCardOffer;

            // do the standard onApplyNow() action when there is no a gift card offer
            if (!isGiftCardEnabled) {
                trackHeapEvent('CC conversion attempt', {
                    href: detailsURL,
                    provider: bank,
                    monetized: isMonetized.toString(),
                    sponsored: isSponsored.toString(),
                });

                trackHeapCCRedirectEvent({
                    referrerURL: getReferrerURL(),
                    affiliateId: getAffiliateData()?.[AFFILIATE_QUERY_PARAM_KEYS.affiliateId],
                    cardInfo,
                });

                onApplyNow?.(cardInfo);
                navigateNewTabToMoreDetails({
                    isMonetized,
                    detailsURL,
                    applyRedirectURL,
                    applyRedirectURLOverride: applyURLOverride,
                    queryParams: {
                        ...(provinceCode && { province: provinceCode }),
                        sponsored: isSponsored,
                    },
                });

                return null;
            }

            // if we did not exit early with the return directly above:
            //  • there IS a gift card offer, AND
            //  • we have been asked to show it (by context or by experiment)
            //  • OR we have been asked to show a CTA modal gate
            // If this is invoked, we know they explicitly accepted or declined the offer.
            // They did NOT cancel, and we are about to send them to the provider’s site.
            function handleAcceptOrDecline(additionalInfo) {
                modal.close(modalId);

                trackHeapCCRedirectEvent({
                    referrerURL: getReferrerURL(),
                    affiliateId: getAffiliateData()?.[AFFILIATE_QUERY_PARAM_KEYS.affiliateId],
                    additionalInfo,
                    cardInfo,
                });

                onApplyNow?.(cardInfo);
                navigateNewTabToMoreDetails({
                    isMonetized,
                    detailsURL,
                    applyRedirectURL,
                    applyRedirectURLOverride: applyURLOverride,
                    queryParams: {
                        ...(provinceCode && { province: provinceCode }),
                        sponsored: isSponsored,
                    },
                });
            }

            // We use the modal’s layer id to close the modal programmatically
            const modalId = modal.open({
                isContentFullWidth: true,
                showBranding: false,
                verticalAlignment: 'flex-start',

                style: {
                    ...isCompactIncentive
                        ? { ...LightboxFullScreenTakeoverStyles }
                        : {
                            ...ModalFullScreenTakeoverStyles,
                            ...INCENTIVE_FULLSCREEN_TAKEOVER_STYLES,
                        },
                },

                screenReaderLabel: (
                    <FormattedMessage
                        id="edb.cc.gift-card.screen-reader"
                        defaultMessage="Credit card gift offer"
                    />
                ),

                children: (
                    <CreditCardIncentive
                        cardInfo={cardInfo}
                        onAcceptOrDecline={handleAcceptOrDecline}
                        userInfo={userInfo}
                        {...otherProps}
                    />
                ),
            });
        },
        [ modal, isCompactIncentive ],
    );
}

/**
 * @callback onApplyNowCallback
 * @param {CreditCardSynopsisShape} cardInfo
 */

export default useApplyForCreditCardCallback;
