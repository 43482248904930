import { Config, fetchJSON, PROVINCE } from '@ratehub/base-ui';

import SCENARIOS from '../definitions/Scenarios';
import CREDIT_SCORES from '../definitions/CreditScores';
import REWARD_TYPES from '../definitions/RewardTypes';
import USER_CATEGORIES from '../definitions/UserCategories';
import NETWORKS from '../definitions/Networks';
import REWARDS_PROGRAMS from '../definitions/RewardsPrograms';
import TRAVEL_FEATURES from '../definitions/TravelFeatures';


const DEPRECATED_YUKON_PROVINCE_CODE = 'YK';

const NETWORKS_NAMES = {
    [NETWORKS.AMEX]: NETWORKS.AMEX,
    [NETWORKS.MASTERCARD]: NETWORKS.MASTERCARD,
    [NETWORKS.VISA]: NETWORKS.VISA,
};

/**
 * Retrieve credit cards from API with supplied parameters
 * @param {Object} params
 * @param {Object} abortControllerSignal an AbortSignal object instance to cancel ongoing fetch request
 * @returns {Promise<{ creditCards: Array<Object>, fetchedTypes: Array<string> }>}
 *              object with array of credit cards and array of fetched
 *              product types if multiple types were fetched
 */
async function fetchCreditCards(params, abortControllerSignal) {
    const apiParams = {
        language: params.language,
        // Handle conversion of Yukon province code
        province: params.province === PROVINCE.YUKON ? DEPRECATED_YUKON_PROVINCE_CODE : params.province,
        ...(params.affiliateId && { affiliateId: params.affiliateId }),
    };

    // Default API call is based on params.scenario
    let apiURL = `${Config.CC_API_URL}/${params.scenario}`;

    if (params.noAnnualFee) {
        apiParams.noFee = params.noAnnualFee;
    }

    if (params.creditScore && params.creditScore !== CREDIT_SCORES.NONE) {
        apiParams.creditScore = params.creditScore;
    }

    if (params.income > 0) {
        apiParams.income = params.income;
    }

    if (params.bank) {
        apiParams.provider = params.bank;
    }

    if (params.scenario === SCENARIOS.REWARDS) {
        if (params.monthlySpending) {
            apiParams.spendingProfile = params.monthlySpending;
        }

        if (params.rewardType && params.rewardType !== REWARD_TYPES.ALL) {
            apiParams.rewardsType = params.rewardType;
        }

        if (params?.features?.length > 0) {
            apiParams.features = params.features.map(feature => API_FEATURE_MAP[feature]);
        }

        if (params.rewardsProgram && params.rewardsProgram !== REWARDS_PROGRAMS.ALL) {
            apiParams.pointSystem = params.rewardsProgram;
        }
    }

    if (params.requestedTypes?.length) {
        apiParams.requestedTypes = params.requestedTypes;
        apiParams.fallbackTypes = params.fallbackTypes;

        apiURL = `${Config.CC_API_URL}/card-mix`;
    }

    if (params.sortBy) {
        apiParams.sortBy = params.sortBy;
    }

    if (params.requiresGiftOffer) {
        apiParams.giftOffer = params.requiresGiftOffer;
    }

    if (Object.values(USER_CATEGORIES).includes(params.userCategory)) {
        apiParams.userCategory = params.userCategory;
    }

    if (Object.keys(NETWORKS_NAMES).includes(params.network)) {
        apiParams.network = NETWORKS_NAMES[params.network];
    }

    if (params.prioritizeSponsored) {
        apiParams.prioritizeSponsored = params.prioritizeSponsored;
    }

    if (typeof params.isCustomApprovalRulesEnabled == 'boolean') {
        apiParams.approvalRulesDataSet = params.isCustomApprovalRulesEnabled ? 1 : 0;
    }

    // For /card-set endpoint
    if (params.ids) {
        apiParams.ids = !Array.isArray(params.ids)
            ? params.ids.split(',')
            : params.ids;

        apiURL = `${Config.CC_API_URL}/card-set`;
    }

    const json = await fetchJSON({
        url: apiURL,
        options: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(apiParams),
        },
        signal: abortControllerSignal,
    }, 'fetchCreditCards');

    return {
        creditCards: Array.isArray(json?.data?.creditCards)
            ? json.data.creditCards
            : [],
        // If multiple product types were fetched need to populate array with which ones
        fetchedTypes: Array.isArray(json?.data?.fetchedTypes)
            ? json.data.fetchedTypes
            : [],
    };
}

// todo: update these values elsewhere + remove this mapping
const API_FEATURE_MAP = {
    [TRAVEL_FEATURES.BAGGAGE_DELAY]:        'baggage-delay',
    [TRAVEL_FEATURES.BAGGAGE_LOSS]:         'baggage-loss',
    [TRAVEL_FEATURES.CAR_RENTAL]:           'car-rental',
    [TRAVEL_FEATURES.EXTENDED_WARRANTY]:    'extended-warranty',
    [TRAVEL_FEATURES.FLIGHT_DELAY]:         'flight-delay',
    [TRAVEL_FEATURES.LOUNGE_ACCESS]:        'lounge-access',
    [TRAVEL_FEATURES.MOBILE_INSURANCE]:     'mobile-insurance',
    [TRAVEL_FEATURES.NO_FOREX]:             'no-forex',
    [TRAVEL_FEATURES.PRICE_PROTECTION]:     'price-protection',
    [TRAVEL_FEATURES.PURCHASE_ASSURANCE]:   'purchase-assurance',
    [TRAVEL_FEATURES.TRAVEL_ACCIDENT]:      'travel-accident',
    [TRAVEL_FEATURES.TRAVEL_EMERGENCY]:     'travel-emergency',
    [TRAVEL_FEATURES.TRIP_CANCELLATION]:    'trip-cancellation',
    [TRAVEL_FEATURES.TRIP_INTERRUPTION]:    'trip-interruption',
    [TRAVEL_FEATURES.HOTEL_MOTEL_BURGLARY]:    'hotel-motel-burglary',
};

export default fetchCreditCards;
