import { Config } from '@ratehub/base-ui';

import getSavingsProductShape from './getSavingsProductShape';
import getSharedDepositShape from './getSharedDepositShape';
import fetchDeposit from './fetchDeposit';


const API_ENDPOINT = `${Config.SAVINGS_API_URL}/account-queue`; 


/**
 * Fetch savings accounts according to options
 * @param {string} options.province - Province code
 * @param {string} options.language - Language code
 * @param {object[]} options.products - An array of requested products ordered by priority
 */
async function fetchSavings(options) {
    const response = await fetchDeposit(options, API_ENDPOINT);

    return {
        data: {
            products: response.data?.products?.map(getProductShape) || [],
        },
    };
}

function getProductShape(product) {
    return product
        ? {
            // Get ProductSingle display specific fields
            ...getSavingsProductShape(product),

            // Get slots/queue specific fields
            ...getSharedDepositShape(product),
            interestRate: product.interest?.default?.[0]?.rate ?? undefined,
            bonusInterestRate: product.interest?.bonus?.rate ?? undefined,

            // Shared fields that overlap between ProductSingle and slots are:
            // provider, isSponsored, detailsURL, and applyUrl
        } 
        : undefined;
}

export default fetchSavings;
