import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import Modal from 'react-modal';

import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import ZIndex from '../definitions/ZIndex';
import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import sendMessageToParentIframe from '../functions/sendMessageToParentIframe';
import isWidget from '../functions/isWidget';
import ModalFullScreenTakeoverStyles from '../styles/ModalFullScreenTakeoverStyles';
import LightboxFullScreenTakeoverStyles from '../styles/LightboxFullScreenTakeoverStyles';
import LogoFull from '../graphics/LogoFull';
import LogoLetterMark from '../graphics/LogoLetterMark';
import CloseButton from './CloseButton';


const DISPLAY_MODES = {
    MODAL: 'modal',
    LIGHTBOX: 'lightbox',
};

const CONTENT_HEADER_HEIGHT = Sizes.SPACING.FOUR;

function FullScreenTakeover({
    isOpen,
    onClose,

    showBranding,
    screenReaderLabel,

    children,

    isContentFullWidth,
    verticalAlignment,
    displayMode,

    ...otherProps
}) {
    const intl = useIntl();
    const modalContainer = useRef(null);

    /*
        If we're inside a dynamically resizing widget, DISPLAY_MODES.modal can be very large.
        The current solution is to force it to use lightbox mode.
    */
    const isLightbox = displayMode === DISPLAY_MODES.LIGHTBOX || isWidget();
    const isHeaderEnabled = !!(onClose || showBranding);

    /**
     * If we're in widget land, modals all open at the top of the iframe.
     * We want to scroll the parent window so we can see the modal.
     */
    function handleAfterOpen() {
        if (isWidget()) {
            sendMessageToParentIframe({
                requestScrollTo: true,
                elementOffsetTop: verticalAlignment === 'flex-start'
                    ? 0
                    : modalContainer.current.getBoundingClientRect().top,
            });
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            contentLabel={messageToString(screenReaderLabel, intl)}
            style={isLightbox ? LightboxFullScreenTakeoverStyles : ModalFullScreenTakeoverStyles}
            onAfterOpen={handleAfterOpen}
            {...otherProps}
        >
            <Container
                verticalAlignment={verticalAlignment}
                isContentFullWidth={isContentFullWidth}
                isLightbox={isLightbox}
                isHeaderEnabled={isHeaderEnabled}
            >
                <If condition={isHeaderEnabled && !isLightbox}>
                    <Header
                        onClose={onClose}
                        showBranding={showBranding}
                    />
                </If>

                <section
                    className="contentContainer"
                    ref={modalContainer}
                >
                    <If condition={isHeaderEnabled && isLightbox}>
                        <Header
                            onClose={onClose}
                            showBranding={showBranding}
                        />
                    </If>

                    {children}
                </section>
            </Container>
        </Modal>
    );
}

FullScreenTakeover.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    showBranding: PropTypes.bool.isRequired,
    screenReaderLabel: MessagePropType.isRequired,
    children: PropTypes.node.isRequired,
    isContentFullWidth: PropTypes.bool,
    verticalAlignment: PropTypes.oneOf([
        'flex-start',
        'center',
    ]),
    displayMode: PropTypes.oneOf(Object.values(DISPLAY_MODES)),
};

FullScreenTakeover.defaultProps = {
    isOpen: true,
    onClose: undefined,
    isContentFullWidth: false,
    verticalAlignment: 'center',
    displayMode: DISPLAY_MODES.MODAL,
};

const MAX_CONTENT_WIDTH_MODAL = '1220px';
const MAX_CONTENT_WIDTH_LIGHTBOX = '800px';
const LOGO_BREAKPOINT = '1024px';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${props => props.verticalAlignment};

    width: 100%;
    /* Need to allow content that expands the height of the container
    to be correctly shown. */
    min-height: 100%;

    .content-header {
        display: flex;
        align-items: center;

        position: absolute;
        top: 0;
        z-index: ${ZIndex.MODALS};

        width: 100%;
        height: ${CONTENT_HEADER_HEIGHT};
        max-width: ${props => props.isLightbox ? MAX_CONTENT_WIDTH_LIGHTBOX : MAX_CONTENT_WIDTH_MODAL};

        > .logo {
            display: none;
            width: auto;
            margin-left: ${Sizes.SPACING.TWO};

            @media (min-width: ${LOGO_BREAKPOINT}) {
                display: block;
            }
        }

        > .letterMark {
            margin: 0 auto;

            @media (min-width: ${LOGO_BREAKPOINT}) {
                display: none;
            }
        }

        > .closeBtn {
            position: absolute;
            right: ${Sizes.SPACING.TWO};
        }
    }

    > .contentContainer {
        width: 100%;
        max-width: ${props => props.isContentFullWidth ? '100%' : MAX_CONTENT_WIDTH_MODAL};

        ${props => props.isHeaderEnabled && !props.isLightbox && `
            padding-top: ${CONTENT_HEADER_HEIGHT};
        `}

        ${props => props.isLightbox && `
            max-width: ${MAX_CONTENT_WIDTH_LIGHTBOX};
            border: 1px solid ${Colours.STONE};
            border-radius: 8px;
            box-shadow: 0 2px 16px 0 ${Colours.STONE_LIGHT};
            background-color: ${Colours.COCONUT};

            /* 2px needed to ensure the contents don't extend outside the border radius */
            padding: 2px;

            > .content-header {
                position: relative;
            }
        `}
    }
`;

// eslint-disable-next-line react/no-multi-comp, react/prop-types
function Header({ onClose, showBranding }) {
    const intl = useIntl();

    return (
        <header className="content-header">
            <If condition={showBranding}>
                <LogoFull className="logo" />
                <LogoLetterMark className="letterMark" />
            </If>

            <If condition={onClose}>
                <CloseButton
                    className="closeBtn"
                    onClick={onClose}
                    offscreenText={messageToString(
                        <FormattedMessage
                            id="base-ui.FullScreenTakeover.modalOffscreenText"
                            defaultMessage="Close modal"
                        />, intl,
                    )}

                    data-name="cta-click.close-modal"
                />
            </If>
        </header>
    );
}

export default FullScreenTakeover;
export { DISPLAY_MODES };
