import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
 * Fetches chart data from the faas charts API based on slugs and date range
 *
 * @param seriesSlugs {array} an array of objects each containing a name and a slug of a series to fetch.
 * @param seriesSlugs.name {string} The display name that typically appears in legends & tooltips (if enabled)
 * @param seriesSlugs.slug {string} The actual slug used to identify & fetch the series (eg. 'best-rates.5y-fixed.max')
 * @param startDate {string} start date for date range filter - use YYYY-MM-DD
 * @param endDate {string} end date for date range filter - use YYYY-MM-DD
 * @returns {object} contains series (an array of all fetched series) and dates (an array of all datetimestamps in each series)
 *    In `series`, main series will be formatted for HighCharts
 */
async function fetchChartData(seriesSlugs, startDate, endDate) {
    try {
        const target = {
            url: Config.CHARTS_API_URL,
            params: {
                series: seriesSlugs.join(','),
                dateStart: startDate,
                dateEnd: endDate,
            },
            encode: true, // Requires encoding for spaces in series slug
        };

        const formattedResponse = {
            series: {},
            dates: [],
        };

        const response = await fetchJSON(target, 'fetchChartData');

        Object.keys(response.data?.series).forEach(key => {
            formattedResponse.series[key] = [];

            Object.keys(response.data.series[key]).forEach(date => {
                formattedResponse.dates.push(Date.parse(date));
                formattedResponse.series[key].push({ 
                    x: Date.parse(date), 
                    y: response.data.series[key][date], 
                });
            });

            // Hard cap to handle HighCharts only supporting up to 1000 data points
            formattedResponse.series[key] = formattedResponse.series[key].slice(0, 1000);
        });

        // Hard cap to handle HighCharts only supporting up to 1000 data points
        formattedResponse.dates = formattedResponse.dates.slice(0, 1000);

        return formattedResponse;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, '[fetchChartData] Fetch charts data failed');
        throw error;
    }
}

export default fetchChartData;
