/* eslint-disable max-len */
/* THIS IS GENERATED BY generateBlockMaps.js - PLEASE DO NOT EDIT */
import {
    AccordionContainer,
    AccordionItem,
    AdBigbox,
    AdLeaderboard,
    BillboardImageContainer,
    BlogSpotlight,
    BlueprintInnerBlocks,
    BlueprintInterface,
    BlueprintPlacementTool,
    BoxOverlay,
    CTAChooser,
    CTAChooserWithProduct,
    CallToActionBlock,
    Carousel,
    Chart,
    Column,
    ColumnContainer,
    ContentAreaContents,
    ContentAreaSlot,
    CustomerReviewContainer,
    CustomerReviewItem,
    EmbeddedVideo,
    ExperimentSegment,
    FactBlock,
    FactBlockContainer,
    FaqAccordion,
    FaqAccordionItem,
    FeaturedArticles,
    FeaturedProductList,
    FeaturedProductShowcase,
    GeneratedTable,
    HeroBanner,
    HeroBannerWide,
    HorizontalRule,
    HumbleBragContainer,
    HumbleBragItem,
    IconChooserBlock,
    ImageBlock,
    ImageGridContainer,
    ImageGridItem,
    InlineForm,
    InlineImage,
    LayoutRow,
    LayoutRowColumn,
    LinkBlockAnchor,
    LinkBlockContainer,
    LinkBlockList,
    ListContainer,
    ListItem,
    LogoBlockContainer,
    LogoBlockItem,
    MasterPagePropReplacer,
    MasterPageStringReplacer,
    MinimalAccordion,
    NavigationListContainer,
    NavigationListItem,
    NewsletterSignUpInlineForm,
    PercentageDialContainer,
    PercentageDialItem,
    RateHistoryLineChart,
    RichTextBlock,
    StickyBanner,
    StoreFrontContainer,
    StoreFrontItem,
    TabbedContentContainer,
    TeamMemberShowcase,
    TextBody,
    TextHeading,
    TwoColumnMessageBlock,
    WPDataTable,
    WordpressCoreCustomHTML,
    WordpressCoreGroup,
} from '@ratehub/web-components';


const BLOCK_TO_COMPONENT_MAP = {
    [AccordionContainer.blockKey]: { render: AccordionContainer },
    [AccordionItem.blockKey]: { render: AccordionItem },
    [AdBigbox.blockKey]: { render: AdBigbox },
    [AdLeaderboard.blockKey]: { render: AdLeaderboard },
    [BillboardImageContainer.blockKey]: { render: BillboardImageContainer },
    [BlogSpotlight.blockKey]: { render: BlogSpotlight },
    [BlueprintInnerBlocks.blockKey]: { render: BlueprintInnerBlocks },
    [BlueprintInterface.blockKey]: { render: BlueprintInterface },
    [BlueprintPlacementTool.blockKey]: { render: BlueprintPlacementTool },
    [BoxOverlay.blockKey]: { render: BoxOverlay },
    [CTAChooser.blockKey]: { render: CTAChooser },
    [CTAChooserWithProduct.blockKey]: { render: CTAChooserWithProduct },
    [CallToActionBlock.blockKey]: { render: CallToActionBlock },
    [Carousel.blockKey]: { render: Carousel },
    [Chart.blockKey]: { render: Chart },
    [Column.blockKey]: { render: Column },
    [ColumnContainer.blockKey]: { render: ColumnContainer },
    [ContentAreaContents.blockKey]: { render: ContentAreaContents },
    [ContentAreaSlot.blockKey]: { render: ContentAreaSlot },
    [CustomerReviewContainer.blockKey]: { render: CustomerReviewContainer },
    [CustomerReviewItem.blockKey]: { render: CustomerReviewItem },
    [EmbeddedVideo.blockKey]: { render: EmbeddedVideo },
    [ExperimentSegment.blockKey]: { render: ExperimentSegment },
    [FactBlock.blockKey]: { render: FactBlock },
    [FactBlockContainer.blockKey]: { render: FactBlockContainer },
    [FaqAccordion.blockKey]: { render: FaqAccordion },
    [FaqAccordionItem.blockKey]: { render: FaqAccordionItem },
    [FeaturedArticles.blockKey]: { render: FeaturedArticles },
    [FeaturedProductList.blockKey]: { render: FeaturedProductList },
    [FeaturedProductShowcase.blockKey]: { render: FeaturedProductShowcase },
    [GeneratedTable.blockKey]: { render: GeneratedTable },
    [HeroBanner.blockKey]: { render: HeroBanner },
    [HeroBannerWide.blockKey]: { render: HeroBannerWide },
    [HorizontalRule.blockKey]: { render: HorizontalRule },
    [HumbleBragContainer.blockKey]: { render: HumbleBragContainer },
    [HumbleBragItem.blockKey]: { render: HumbleBragItem },
    [IconChooserBlock.blockKey]: { render: IconChooserBlock },
    [ImageBlock.blockKey]: { render: ImageBlock },
    [ImageGridContainer.blockKey]: { render: ImageGridContainer },
    [ImageGridItem.blockKey]: { render: ImageGridItem },
    [InlineForm.blockKey]: { render: InlineForm },
    [InlineImage.blockKey]: { render: InlineImage },
    [LayoutRow.blockKey]: { render: LayoutRow },
    [LayoutRowColumn.blockKey]: { render: LayoutRowColumn },
    [LinkBlockAnchor.blockKey]: { render: LinkBlockAnchor },
    [LinkBlockContainer.blockKey]: { render: LinkBlockContainer },
    [LinkBlockList.blockKey]: { render: LinkBlockList },
    [ListContainer.blockKey]: { render: ListContainer },
    [ListItem.blockKey]: { render: ListItem },
    [LogoBlockContainer.blockKey]: { render: LogoBlockContainer },
    [LogoBlockItem.blockKey]: { render: LogoBlockItem },
    [MasterPagePropReplacer.blockKey]: { render: MasterPagePropReplacer },
    [MasterPageStringReplacer.blockKey]: { render: MasterPageStringReplacer },
    [MinimalAccordion.blockKey]: { render: MinimalAccordion },
    [NavigationListContainer.blockKey]: { render: NavigationListContainer },
    [NavigationListItem.blockKey]: { render: NavigationListItem },
    [NewsletterSignUpInlineForm.blockKey]: { render: NewsletterSignUpInlineForm },
    [PercentageDialContainer.blockKey]: { render: PercentageDialContainer },
    [PercentageDialItem.blockKey]: { render: PercentageDialItem },
    [RateHistoryLineChart.blockKey]: { render: RateHistoryLineChart },
    [RichTextBlock.blockKey]: { render: RichTextBlock },
    [StickyBanner.blockKey]: { render: StickyBanner },
    [StoreFrontContainer.blockKey]: { render: StoreFrontContainer },
    [StoreFrontItem.blockKey]: { render: StoreFrontItem },
    [TabbedContentContainer.blockKey]: { render: TabbedContentContainer },
    [TeamMemberShowcase.blockKey]: { render: TeamMemberShowcase },
    [TextBody.blockKey]: { render: TextBody },
    [TextHeading.blockKey]: { render: TextHeading },
    [TwoColumnMessageBlock.blockKey]: { render: TwoColumnMessageBlock },
    [WPDataTable.blockKey]: { render: WPDataTable },
    [WordpressCoreCustomHTML.blockKey]: { render: WordpressCoreCustomHTML },
    [WordpressCoreGroup.blockKey]: { render: WordpressCoreGroup },
};

export default BLOCK_TO_COMPONENT_MAP;
