import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import {
    Form,
    PrimaryButton,
    Sizes,
    renderMessage,
    PositionOffscreenStyles,

    AsyncButton,
    MessagePropType,
    DirectInput,
    navigateWindowTo,
} from '@ratehub/base-ui';

import useInlineForm, { INPUT_TYPES } from '../hooks/useInlineForm';


function InlineForm({
    inputLabel,
    inputPlaceholder,
    inputType,
    inputErrorMessage,
    inputName,
    dataName,

    ctaLabel,
    ctaVariant,
    ctaName,

    submitURL,
    onClick,

    justifyContent,
    isCompact,

    asyncButtonMessages,
    asyncButtonComponent,

    ...otherProps
}) {
    const intl = useIntl();
    const state = useInlineForm({
        inputType: inputType,
        onSubmit: handleFormSubmit,
    });

    const isAsync = !!(asyncButtonMessages && asyncButtonComponent);

    /* Compute a default button colour if one is not supplied */
    const buttonVariant = ctaVariant ?? 'blueberry-dark';

    async function handleFormSubmit() {
        state.setIsTouched(true);

        if (!await state.isValid) {
            return false;
        }

        const result = onClick ? await onClick(state.value) : true;

        if (result === false) {
            return false;
        }

        state.setIsDisabled(true);

        if (submitURL) {
            const url = new URL(submitURL);

            url.searchParams.set(inputName, state.value);
            navigateWindowTo(url.toString());
        }
    }

    return (
        <InlineForm.Form
            onSubmit={state.submit}
            data-name={dataName}
            justifyContent={justifyContent}
            noValidate
            isCompact={isCompact}
            {...otherProps}
        >
            {/* Lint complains when we use another input component within a label */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={classNames('rh-title-xs', 'rh-display-block', 'label')}>
                <span
                    className="positioned-offscreen"
                    {...renderMessage(inputLabel, intl)}
                />

                <DirectInput
                    type={inputType === INPUT_TYPES.EMAIL ? 'email' : 'text'}
                    name={inputName}
                    placeholder={inputType !== INPUT_TYPES.STRING
                        ? getDirectInputPlaceholder(inputType)
                        : inputPlaceholder
                    }
                    onChange={e => state.setValue(e.target.value)}
                    value={state.value}
                    shouldShowInvalid={state.shouldShowInvalid}
                    errorMessage={inputErrorMessage ?? getErrorMessage(inputType)}
                    onBlur={state.blur}
                />
            </label>

            <Choose>
                <When condition={isAsync}>
                    <AsyncButton
                        name={ctaName}
                        data-name={ctaName}
                        className="submit"
                        messages={{
                            'ready': asyncButtonMessages.ready,
                            'in-progress': asyncButtonMessages['in-progress'],
                            'complete': asyncButtonMessages.complete,
                        }}
                        buttonComponent={asyncButtonComponent}
                        variant={buttonVariant}
                        type="submit"
                        disabled={state.isDisabled}
                        size={isCompact ? 'medium' : 'large'}
                    />
                </When>
                <Otherwise>
                    <PrimaryButton
                        name={ctaName}
                        data-name={ctaName}
                        variant={buttonVariant}
                        className="submit"
                        size={isCompact ? 'medium' : 'large'}
                        message={ctaLabel}
                    />
                </Otherwise>
            </Choose>
        </InlineForm.Form>
    );
}

InlineForm.propTypes = {
    inputLabel: MessagePropType.isRequired,
    inputPlaceholder: PropTypes.string,
    inputType: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    inputErrorMessage: MessagePropType,
    inputName: PropTypes.string.isRequired,
    dataName: PropTypes.string,

    ctaName: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaVariant: PropTypes.string,

    submitURL: PropTypes.string,
    onClick: PropTypes.func,

    justifyContent: PropTypes.oneOf([ 'flex-start', 'center', 'flex-end' ]),
    isCompact: PropTypes.bool,

    asyncButtonMessages: PropTypes.shape({
        ready: MessagePropType.isRequired,
        'in-progress': MessagePropType.isRequired,
        complete: MessagePropType.isRequired,
    }),
    asyncButtonComponent: PropTypes.oneOf([ 'primary', 'alternate' ]),
};

InlineForm.defaultProps = {
    inputPlaceholder: undefined,
    inputType: INPUT_TYPES.STRING,
    inputErrorMessage: undefined,
    dataName: 'inline form',

    ctaLabel: undefined,
    ctaName: 'rh_inline_form_cta',
    ctaVariant: undefined,

    submitURL: undefined,
    onClick: undefined,

    justifyContent: 'center',
    isCompact: false,

    asyncButtonMessages: undefined,
    asyncButtonComponent: 'primary',
};

const MOBILE_BREAKPOINT = '40em';

InlineForm.Form = styled(Form)`
    display: flex;
    flex-direction: row; // We enforce row mode all the time.
    flex-wrap: wrap; // We do allow wrapping though.
    justify-content: ${props => props.justifyContent};

    margin-left: -${Sizes.SPACING.HALF};
    margin-right: -${Sizes.SPACING.HALF};

    // We just want 100% width here but adding 1 extra
    // rem to offset negative margin above.
    width: calc(100% + ${Sizes.SPACING.HALF} + ${Sizes.SPACING.HALF});
    max-width: 800px;

    > .label {
        margin: 0;

        > .positioned-offscreen {
            ${PositionOffscreenStyles}
        }
    }

    .error-message-container {
        /* Make input slightly smaller than it usually is */
        > input {
            height: ${props => props.isCompact ? Sizes.SPACING.THREE : Sizes.SPACING.FOUR};
            line-height: ${props => props.isCompact ? Sizes.SPACING.THREE : Sizes.SPACING.FOUR};

            @media (max-width: ${MOBILE_BREAKPOINT}) {
                height: ${Sizes.SPACING.THREE};
                line-height: ${Sizes.SPACING.THREE};
            }
        }
    }

    > .label,
    > .submit {
        flex-basis: 240px;
        flex-shrink: 0;
        flex-grow: 1;

        margin: ${Sizes.SPACING.QUARTER} ${Sizes.SPACING.HALF};
    }

    > .submit {
        @media (max-width: ${MOBILE_BREAKPOINT}) {
            min-height: ${Sizes.SPACING.THREE};
        }
    }
`;

function getErrorMessage(type) {
    if (type === INPUT_TYPES.POSTALCODE) {
        return MESSAGES.POSTCODE_DEFAULT_ERROR;
    }

    if (type === INPUT_TYPES.EMAIL) {
        return MESSAGES.EMAIL_DEFAULT_ERROR;
    }

    return MESSAGES.NO_VALUE_DEFAULT_ERROR;
}

function getDirectInputPlaceholder(inputType) {
    if (inputType === INPUT_TYPES.EMAIL) {
        return MESSAGES.defaultPlaceholderEmail;
    } else if (inputType === INPUT_TYPES.POSTALCODE) {
        return MESSAGES.defaultPlaceholderPostalCode;
    }
}

const MESSAGES = defineMessages({
    EMAIL_DEFAULT_ERROR: {
        id: 'inline-form.email.default-error',
        defaultMessage: 'Please enter a valid email address',
    },
    POSTCODE_DEFAULT_ERROR: {
        id: 'inline-form.postcode.default-error',
        defaultMessage: 'Please match the requested format - A1A 1A1',
    },
    NO_VALUE_DEFAULT_ERROR: {
        id: 'inline-form.string.no-value-error',
        defaultMessage: 'Please enter a value',
    },
    defaultPlaceholderEmail: {
        id: 'inline-form.email.field.placeholder',
        defaultMessage: 'Enter email',
    },
    defaultPlaceholderPostalCode: {
        id: 'inline-form.postal.code.field.placeholder',
        defaultMessage: 'Enter postal code (A1A 1A1)',
    },
});

InlineForm.blockKey = 'rh/inline-form';

export default observer(InlineForm);
