import { defineMessages } from 'react-intl';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { Config, DOCUMENTS } from '@ratehub/base-ui';

import getShouldShowBasedOnBannerCookie from './getShouldShowBasedOnBannerCookie';


dayjs.extend(isSameOrAfter);


/**
 * returns Base-ui's MessageBanner props if you want to show the Revisit Banner
 * returns null if you don't want to
 * @param {object} sessionStore
 * @returns {object|null}
 */
function getRevisitBannerPropsInsuranceAuto(sessionStore) {
    if (!getShouldShow(window?.location?.href)) {
        return null;
    }

    const metadata = sessionStore
        .getDocumentMetadataByPath(DOCUMENTS.INSURANCE_AUTO.PATH)
        .filter(isIncompleteAndUnexpired)
        .sort(sortByDateUpdated)
        ?.[0];

    if (!metadata) {
        return null;
    }

    if (!getShouldShowBasedOnBannerCookie(
        `revisit-banner-${DOCUMENTS.INSURANCE_AUTO.TYPE}`,
        metadata.id,
        metadata.userUpdatedAt ?? metadata.dateCreated,
    )) {
        return null;
    }

    return getMessageBannerProp(metadata.id);
}

function getShouldShow(url) {
    url = url?.toLowerCase();

    if (/\/insurance\/car-quotes/.test(url)) {
        return false;
    }

    return /auto|automobile|parking|trucks|vehicle|car|suvs|demerit|driver|driving|g1|g2/.test(url);
}

function isIncompleteAndUnexpired(documentMetadata) {
    // we should filter out bugged document metadata
    if (documentMetadata.userUpdatedAt == null && documentMetadata.dateCreated == null) {
        return false;
    }

    return documentMetadata.isCompleted === false // do not bother if value is undefined
        && dayjs(documentMetadata.userUpdatedAt ?? documentMetadata.dateCreated).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isSameOrAfter(dayjs().subtract(30, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
}

function sortByDateUpdated(docA, docB) {
    return dayjs(docA.userUpdatedAt ?? docA.dateCreated).isSameOrAfter(dayjs(docB.userUpdatedAt ?? docB.dateCreated))
        ? -1
        : 1;
}

// returns props for base-ui's MessageBanner
function getMessageBannerProp(documentId) {
    return {
        documentId,
        documentType: DOCUMENTS.INSURANCE_AUTO.TYPE,
        messageBannerStyle: 'compact',
        iconKey: 'note',
        variant: 'success',
        message: MESSAGES.AUTO_INSURANCE_BEST_CAR_QUOTE_REVISIT_MESSAGE,
        ctaVariant: 'alternativeAnchor',
        ctaMessage: MESSAGES.AUTO_INSURANCE_BEST_CAR_QUOTE_REVISIT_CTA,
        ctaDataName: 'car-landing page-revisit application-cta',
        href: Config.RH_INSURANCE_REVISIT_BANNER_URL,
    };
}

const MESSAGES = defineMessages({
    AUTO_INSURANCE_BEST_CAR_QUOTE_REVISIT_MESSAGE: {
        id: 'AutoInsurance.BestCarInsuranceQuote.RevisitBannerMessage',
        defaultMessage: 'Still looking for the best auto insurance rate? Speak with an RH Insurance broker.',
    },
    AUTO_INSURANCE_BEST_CAR_QUOTE_REVISIT_CTA: {
        id: 'AutoInsurance.BestCarInsuranceQuote.RevisitBannerCTA',
        defaultMessage: 'Book a call',
    },
});

export default getRevisitBannerPropsInsuranceAuto;
