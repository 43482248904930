import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MessagePropType from '../definitions/MessagePropType';
import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import TextElement from './TextElement';


function Caption({ variant, message, children, size, as, ...otherProps }) {
    return (
        <Caption.TextElement
            variant={variant}
            message={message}
            size={size}
            forwardedAs={as}
            {...otherProps}
        >
            {children}
        </Caption.TextElement>
    );
}

Caption.TextElement = styled(TextElement)`
    margin: ${Sizes.SPACING.ONE} 0;

    font-family: ${Typography.FONT_FAMILY_STACK};
    color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

    font-size: ${Sizes.FONTS['2XS']};
    font-weight: ${Typography.WEIGHTS.REGULAR};
    line-height: 16px;

    ${props => props.size === 'small' && `
        font-size: ${Sizes.FONTS['3XS']};
        line-height: 14px;
    `}

    a {
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

        outline: none;
        transition: color 300ms;

        &:visited {
            color: ${Colours.BLUEBERRY_DARK};
        }

        &:hover,
        &:focus {
            color: ${Colours.BLUEBERRY};
            text-decoration: none;
        }

        &:active {
            color: ${Colours.BLUEBERRY_DARK};
            text-decoration: none;
        }
    }

    b {
        font-weight: ${Typography.WEIGHTS.MEDIUM};
    }
`;

Caption.propTypes = {
    variant: PropTypes.oneOf([
        'light',
        'dark',
    ]),
    message: MessagePropType,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    size: PropTypes.oneOf([
        'normal',
        'small',
    ]),
    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};

Caption.defaultProps = {
    variant: 'dark',
    message: undefined,
    children: undefined,
    size: 'normal',
    as: 'p',
};

export default Caption;
