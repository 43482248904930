import { defineMessages } from 'react-intl';
import dayjs from 'dayjs';

import { Locales, DOCUMENTS } from '@ratehub/base-ui';
import { SUBMISSION_SOURCES } from '@ratehub/documents/mtg-journey-document/stable';

import getShouldShowBasedOnBannerCookie from './getShouldShowBasedOnBannerCookie';


const NOT_ALLOWED_URL_PATTERNS = [
    '/mortgages/apply', '/hypotheque/appliquez', // app.ca
    '/mortgages/quotes', '/hypotheque/quotes', // wizard flows
    '/mortgages/canwise',
    '/mortgages/landing',
    'calculator', 'calculatrice',
];

/**
 * returns Base-ui's MessageBanner props if you want to show the Revisit Banner
 * returns null if you don't want to
 * @param {object} sessionStore
 * @returns {object|null}
 */
function getRevisitBannerPropsMortgages(sessionStore, locale) {
    if (!getShouldShow(window?.location?.href)) {
        return null;
    }

    const metadata = sessionStore
        .getDocumentMetadataByPath(DOCUMENTS.MORTGAGE_JOURNEY.PATH)
        .filter(hasMatchedRequirements)
        .sort(sortByDateEdited)
        ?.[0];

    if (!metadata) {
        return null;
    }

    if (!getShouldShowBasedOnBannerCookie(
        `revisit-banner-${DOCUMENTS.MORTGAGE_JOURNEY.TYPE}`,
        metadata.id,
        metadata.lastEditDate ?? metadata.updatedAt,
    )) {
        return null;
    }

    return hasDroppedOff(sessionStore?.profile)
        ? getMessageBannerProp(metadata.id, locale)
        : null;
}

function getShouldShow(url) {
    const notAllowedFound = NOT_ALLOWED_URL_PATTERNS.some(pattern => url.includes(pattern));

    if (notAllowedFound) {
        return false;
    }

    const allowedPattern = /mortgage|hypotheque|hypothecaires|first-time-home-buyer/;

    // should show on rest of the mortgage pages
    return url.match(allowedPattern);
}

function hasMatchedRequirements(metadata) {
    if (metadata.lastEditDate == null && metadata.updatedAt == null) {
        return false;
    }

    // only care about the ones created by wizard
    if (metadata?.source !== SUBMISSION_SOURCES.WIZARD) {
        return false;
    }

    const today = dayjs().startOf('day');
    const lastUpdated = dayjs(metadata.lastEditDate ?? metadata.updatedAt).startOf('day');

    // ignore old documents
    if (today.diff(lastUpdated, 'days') > 30) {
        return false;
    }

    return true;
}

function sortByDateEdited(metadataA, metadataB) {
    const dateA = dayjs(metadataA.lastEditDate ?? metadataA.updatedAt);
    const dateB = dayjs(metadataB.lastEditDate ?? metadataB.updatedAt);

    return dateB.diff(dateA);
}

function hasDroppedOff(profile) {
    return !(profile?.email && profile?.phone && (profile.firstName || profile.lastName));
}

// returns props for base-ui's MessageBanner
// We provide different messages/CTA for EN users / FR users
// - EN users: CTA for booking calendar
// - FR users: CTA for resuming application
function getMessageBannerProp(documentId, locale) {
    return {
        documentId,
        documentType: DOCUMENTS.MORTGAGE_JOURNEY.TYPE,
        messageBannerStyle: 'compact',
        iconKey: 'note',
        variant: 'success',
        message: MESSAGES.MORTGAGES_REVISIT_BANNER_MESSAGE,
        ctaVariant: 'alternativeAnchor',
        ctaMessage: MESSAGES.MORTGAGES_REVISIT_BANNER_CTA,
        ctaDataName: 'mtg-revisit-application-cta',
        href: `/${Locales.FRENCH === locale ? 'hypotheque' : 'mortgages'}/quotes/?documentID=${documentId}`,
    };
}

const MESSAGES = defineMessages({
    MORTGAGES_REVISIT_BANNER_MESSAGE: {
        id: 'Mortgages.RevisitBanner.Message',
        defaultMessage: 'Hurry! Complete your inquiry to get the best mortgage rate.',
    },
    MORTGAGES_REVISIT_BANNER_CTA: {
        id: 'Mortgages.RevisitBanner.CTA',
        defaultMessage: 'continue',
    },
});

export default getRevisitBannerPropsMortgages;
