/*
 *  Before removing an experiment from this file, read
 *  https://github.com/ratehub/front-end/blob/main/docs/experiments.md#how-to-remove-an-experiment
 */


// https://cms.ratehub.dev/wp-admin/edit.php?post_type=experiments
// https://cms.ratehub.ca/wp-admin/edit.php?post_type=experiments

const EXPERIMENTS = {
    // added 2023-Oct-24
    mobileCTA: 'cc-mobile-cta',                         // to be removed by ccdep-3118

    // added 2024-Apr-29
    openedDetails: 'cc-opened-details',                 // to be removed by ccdep-3569

    // added 2024-May-16
    noThanks: 'cc-no-thanks',                           // to be removed by ccdep-3600

    // added 2024-Jun-03
    noThanksAlt: 'cc-no-thanks-alt',                    // to be removed by ccdep-3629

    // added 2024-Jul-04
    smallerIncentive: 'cc-gift-offer-smaller',          // to be removed by ccdep-3681
};

export default EXPERIMENTS;
