import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconArrowLeft({ outlineWidth, stroke, fill, ...otherProps }){
    return (
        <IconArrowLeft.Svg
            viewBox="-1 -1 34 34"
            fill="none"
            fillRule="evenodd"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="16"
                cy="16"
                r="16"
            />
            <path
                fill="none"
                d="M16 9v14M22 17l-6.096 6L10 17.188"
            />
        </IconArrowLeft.Svg>
    );
}

IconArrowLeft.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconArrowLeft.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    fill: Colours.TRANSPARENT,
};

IconArrowLeft.Svg = styled.svg`
    transform: rotate(90deg);
    transform-origin: 50% 50%;

    stroke-width: 2px;
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconArrowLeft;

