import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MessagePropType from '../definitions/MessagePropType';
import { VARIANTS, GLAMAZON_STYLES } from '../styles/AnchorStyles';

import TextElement from './TextElement';


function TextAnchor({
    message,
    children,
    href,
    variant,
    disabled,
    onClick,
    ...otherProps
}) {
    return (
        <TextAnchorContainer
            href={disabled ? undefined : href}
            variant={variant}
            disabled={disabled}
            onClick={onClick}
            {...otherProps}
        >
            <TextElement
                as="span"
                className="text-wrapper"
                message={message}
            >
                {children}
            </TextElement>
        </TextAnchorContainer>
    );
}

TextAnchor.propTypes = {
    message: MessagePropType,
    children: PropTypes.node,
    href: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

TextAnchor.defaultProps = {
    children: undefined,
    message: undefined,
    variant: VARIANTS.DARK,
    disabled: undefined,
    href: undefined,
    onClick: undefined,
};

const TextAnchorContainer = styled.a`
    ${GLAMAZON_STYLES};

    cursor: pointer;
`;

TextAnchor.VARIANTS = VARIANTS;

export default observer(TextAnchor);