import { Config, fetchJSON } from '@ratehub/base-ui';

import SCENARIO_TYPES from '../definitions/scenarioTypes';
import getScenarioUrlSlugFromType from './getScenarioUrlSlugFromType';


/**
 *
 * @param {string} scenarioType
 * @param {object} scenarioInputs
 * @param {boolean} flatten // Flattens return
 */
async function fetchBestRates(scenarioType, params, flatten = true) {
    const fetchedRates = await fetchJSON({
        url: `${Config.MORTGAGE_RATES_API_URL}/best/${getScenarioUrlSlugFromType(scenarioType)}-rates`,
        params,
        arrayFormat: 'bracket',
        rateLimitRefetch: true,
    }, 'fetchBestRates');

    return normalizeRateResponse(fetchedRates.data, scenarioType, flatten);
}

async function normalizeRateResponse({ providers, rates, primeRate, ...additionalProperties }, scenario, flatten) {
    let flattenedRates = [];

    // If the provider is brokered, replaces the brokerage slug with the brokerage object for easy access
    Object.values(providers)
        .filter(provider => {
            try {
                return provider.brokerage;
            } catch {
                throw new Error (`A brokerage for ${provider.name} was not found while normlizing the rate response for fetchAllRates`);
            }
        }) // Ensure we only mutate a provider if there is a brokerage
        .forEach(provider =>
            provider.brokerage = providers[provider.brokerage],
        );

    if (scenario === SCENARIO_TYPES.HELOC) {
        rates.forEach(rate => {
            const rateProvider = providers[rate.provider];
            if (rateProvider == null) {
                throw new Error (`There is no ${rate.provider} provider within the returned providers object, while normlizing the rate response for fetchBestRates`);
            } else {
                rate.provider = rateProvider;
                rate.primeRate = primeRate; // The Lead Form requires primeRate, but it is only passed a single rate. Therefore primeRate is added here as well
            }
            flattenedRates.push(rate);
        });
    } else {
        Object.keys(rates).forEach(type => {
            Object.keys(rates[type]).forEach(term => {
                rates[type][term].forEach(rate => {
                    const rateProvider = providers[rate.provider];
                    if (rateProvider == null) {
                        throw new Error (`There is no ${rate.provider} provider within the returned providers object, while normlizing the rate response for fetchBestRates`);
                    } else {
                        rate.provider = rateProvider;
                        rate.primeRate = primeRate; // The Lead Form requires primeRate, but it is only passed a single rate. Therefore primeRate is added here as well
                    }
                    flattenedRates.push(rate);
                });
            });
        });
    }

    // add additional properties to normalizedRates
    //  note: object properties are not included when iterating over array items
    const additionalPropsObject = { ...additionalProperties, providers, primeRate };
    return flatten ? Object.assign(flattenedRates, additionalPropsObject) : { ...additionalPropsObject, rates };
}

export default fetchBestRates;
