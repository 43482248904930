import Config from '../definitions/Config';
import noticeError from './noticeError';


/**
 * Submits to FaaS conversion service
 * 
 * @param {object} payload 
 * @returns {object} conversion id
 */
export default async function submitConversionPayload(payload) {
    const response = await fetch(Config.CONVERSION_SUBMIT_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
    });

    if (!response.ok) {
        const error = new Error(`Failed to submit conversion: [${response.status}] ${response.statusText}`);
        noticeError(error, {
            url: Config.CONVERSION_SUBMIT_URL,
            payload,
        });
        throw error;  
    }
    
    return response.json();
}