import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

import {
    Sizes,
    ModalContainer,
    AlternateButton,
    Locales,

    AccountForms,
    MAGIC_LINK_SIGN_IN_SCREEN,
    MAGIC_LINK_VARIANTS,
    useSession,
    Config,
} from '@ratehub/base-ui';


function HeaderUserAccountMenuLogin({
    requiresLogin,
    magicLinkVariant,
    resumeApplicationLink,
    documentOptions,
    modalOpen,
    ...otherProps
}) {
    const intl = useIntl();
    const sessionStore = useSession();

    const [ modalIsOpen, setModalIsOpen ] = useState(false);

    function handleOpenModal() {
        setModalIsOpen(true);
    }

    function handleCloseModal() {
        setModalIsOpen(false);

        if (requiresLogin) {
            document.location.href = '/';
        }
    }

    return (
        <>
            <ModalContainer
                isOpen={modalIsOpen || modalOpen}
                onClose={handleCloseModal}
                showBranding={true}
                contentMaxWidth="712px"
                // Recommend passing in these ids - but they have to match elements in the children
                // aria={{
                //     labelledby: "heading",
                //     describedby: "full_description"
                // }}
            >
                <AccountForms
                    messages={Config.ENABLE_GOOGLE_SSO_ON_HEADER_MENU
                        ? { [MAGIC_LINK_SIGN_IN_SCREEN]: NEW_SIGN_IN_MESSAGES }
                        : {} // use default message
                    }
                    magicLinkVariant={magicLinkVariant}
                    resumeApplicationLink={resumeApplicationLink}
                    documentOptions={documentOptions}
                    shouldShowGoogleSignIn={Config.ENABLE_GOOGLE_SSO_ON_HEADER_MENU}
                />
            </ModalContainer>

            <SignInButton
                intl={intl}
                onClick={() => sessionStore.hasFetchedSession && handleOpenModal()}
                size="small"
                type="button"
                {...otherProps}
            >
                <FormattedMessage
                    id="web-server.header.sign-in-button-label"
                    defaultMessage="Sign in"
                />
            </SignInButton>
        </>
    );
}

HeaderUserAccountMenuLogin.propTypes = {
    requiresLogin: PropTypes.bool.isRequired,
    magicLinkVariant: PropTypes.oneOf(Object.values(MAGIC_LINK_VARIANTS)),
    resumeApplicationLink: PropTypes.string,
    documentOptions: PropTypes.shape({
        document: PropTypes.object,
        documentType: PropTypes.string,
        documentToken: PropTypes.string,
        documentRedirect: PropTypes.string,
    }),
    modalOpen: PropTypes.bool.isRequired,
};

HeaderUserAccountMenuLogin.defaultProps = {
    magicLinkVariant: undefined,
    resumeApplicationLink: undefined,
    documentOptions: undefined,
};

const SignInButton = styled(AlternateButton)`
    ${props => props.intl.locale === Locales.ENGLISH && `
        text-transform: capitalize;
    `}

    font-size: ${Sizes.FONTS['2XS']};

    white-space: nowrap;
    min-width: ${Sizes.SPACING.FOUR};
`;

const NEW_SIGN_IN_MESSAGES = defineMessages({
    MAGIC_LINK_SIGN_IN_TITLE: {
        id: 'web-server.header.sign-in-screen.title',
        defaultMessage: 'Enter your e-mail to join Ratehub.ca or sign in',
    },
    MAGIC_LINK_SIGN_IN_CTA: {
        id: 'web-server.header.sign-in-screen.cta',
        defaultMessage: 'continue',
    },
    EMAIL_LABEL: {
        id: 'web-server.header.sign-in-screen.email-label',
        defaultMessage: 'Email address',
    },
    EMAIL_PLACEHOLDER: {
        id: 'web-server.header.sign-in-screen.email-placeholder',
        defaultMessage: 'Enter your email',
    },
    DESCRIPTION: {
        id: 'web-server.header.sign-in-screen.description-text',
        defaultMessage: 'Sign in to access your Ratehub.ca dashboard and complete your mortgage, insurance, or credit card application.',
    },
    NEWSLETTER_SUBSCRIPTION_LABEL: {
        id: 'web-server.header.sign-in-screen.newsletter-subscription-label',
        defaultMessage: 'Join our newsletter to receive regular updates on mortgage rates!',
    },
});

export default observer(HeaderUserAccountMenuLogin);
