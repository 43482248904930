import { CREDIT_CARD_DOC_TYPES } from '@ratehub/documents/cc-journey-document/stable';
import getCreditCardURL, { CC_URL_SLUGS } from './getCreditCardURL';
/**
 * Get the resume URL for a specified credit card workflow (cardfinder or a hosted app)
 * @param {string} documentID: Document ID
 * @param {string} locale: "en-CA", "fr-CA",
 * @param {string} creditCardDocumentType: type of credit card document (Cardfinder vs. hosted app)
 * @param {string} cardSlug: Hosted app card slug when applicable
 * @returns {string} resume URL
 */
function getCreditCardResumeURL(
    documentID,
    locale,
    creditCardDocumentType,
    cardSlug = '',
) {
    if (creditCardDocumentType === CREDIT_CARD_DOC_TYPES.CARD_FINDER) {
        return getCreditCardURL(
            locale,
            CC_URL_SLUGS.CARDFINDER,
            documentID ? `?documentID=${documentID}` : '',
        );
    }
    if (creditCardDocumentType === CREDIT_CARD_DOC_TYPES.HOSTED_APP) {
        if (!cardSlug) {
            throw new Error('[getCreditCardResumeURL] Missing cardSlug for hosted app');
        }
        return getCreditCardURL(
            locale,
            CC_URL_SLUGS.APPLY,
            documentID ? `${cardSlug}/resume?documentID=${documentID}` : '',
        );
    }

    throw new Error(`[getCreditCardResumeURL] Unexpected credit card type: ${creditCardDocumentType}`);
}

export default getCreditCardResumeURL;
