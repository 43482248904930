import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Cookies from 'js-cookie';


dayjs.extend(isSameOrAfter);

/**
 * Gets the cookie and check if the user should see the banner or not based on the cookie
 *
 * @param {string} cookieName
 * @param {string} documentId
 * @param {string} documentLastModifiedAt Date time ISO string
 * @returns {boolean}
 */
function getShouldShowBasedOnBannerCookie(cookieName, documentId, documentLastModifiedAt) {
    const bannerCookie = getCookieValue(cookieName);

    if (bannerCookie) {
        // if user has clicked close - do not ever show banner for same document
        if (bannerCookie.documentId === documentId) {
            return false;
        }

        const modifiedAt = dayjs(documentLastModifiedAt);
        const closeAt = dayjs(bannerCookie.closeAt);

        // if user has new document assigned after close - do not show banner within first 24 hour of close
        return modifiedAt.diff(closeAt, 'hours') > 24;
    }

    return true;
}

function getCookieValue(cookieName) {
    try {
        return JSON.parse(atob(Cookies.get(cookieName)));
    } catch (error) {
        // do not throw
        return null;
    }
}

export default getShouldShowBasedOnBannerCookie;
