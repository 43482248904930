import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import fetchAddressSuggestions from '../functions/fetchAddressSuggestions';
import fetchAddressDetails from '../functions/fetchAddressDetails';
import noticeError from '../functions/noticeError';
import AddressPropType from '../definitions/AddressPropType';
import Messages from '../definitions/AddressMessages';
import ADDRESS_SEARCH_TYPE from '../definitions/AddressSearchType';
import AddressSearchResultTypesPropType from '../definitions/AddressSearchResultTypesPropType';
import Autocomplete from './Autocomplete';
import InputController from './InputController';


// NOTE TO DEVELOPER:
// The very strange autoComplete value is to disable Chrome's autofill.
// Chrome does not respect the conventional autoComplete="off" | "no"
// Autofill causes problems with our API-driven search behaviour.
// The value selected comes from here: https://github.com/facebook/react/issues/1159

// NOTE 2:
// `data-lpignore="true" is to disable LastPass: it keeps offering to fill this in.
// https://support.logmeininc.com/lastpass/help/manage-your-form-fills-lp040002

function AddressInputSearch({ 
    id, 
    name, 
    value, 

    onChange, 
    onBlur,

    searchResultTypes, 

    isRequired,
    isDisabled,

    ...otherProps 
}) {
    const fetchSuggestions = useCallback(
        async query => {
            // Ensure we have atleast 3 characters to look up.
            if (!query || query.length < 3) {
                return;
            }

            // Let this throw an error since Autocomplete will handle it
            return fetchAddressSuggestions(query, { types: searchResultTypes });
        },
        [ searchResultTypes ],
    );

    return (
        <InputController
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isRequired={isRequired}
            isDisabled={isDisabled}
            defaultErrorMessage={(
                <FormattedMessage
                    {...Messages.SEARCH_INVALID}
                />
            )}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                async function handleChange(option) {
                    // Our address lookup would fail if we tried to look-up a non-parsable address.
                    if (!option) {
                        return;
                    }

                    const placeId = option?.placeId;

                    try {
                        const details = await fetchAddressDetails(placeId);

                        // transform the response to our desired shape
                        inputProps.onChange({
                            placeId,

                            unitNumber: details.unitNumber,

                            streetNumber: details.streetNumber,
                            streetName: details.streetName,
                            streetType: details.streetType,
                            streetDirection: details.streetDirection,

                            city: details.city,
                            province: details.provinceCode,
                            postalCode: details.postalCode,
                        });
                    } catch (error) {
                        noticeError(error, {
                            message: `[AddressInputSearch] Unable to fetch address details with option: ${JSON.stringify(option)}`,
                            option,
                            placeId,
                        });

                        // Fire the onChange with an empty object since we received no valid result
                        //  and the AddressInputForm can open to allow users to manually enter their address
                        inputProps.onChange({});
                    }
                }
    
                return (
                    <Autocomplete
                        {...omit(inputProps, [ 'value' ])} // value of Autocomplete must be a suggestion
                        autoComplete="new"
                        data-lpignore="true"
                        fetchSuggestions={fetchSuggestions}
                        suggestionRenderer={suggestionRenderer}
                        onChange={handleChange}
                        errorMessage={inputState.errorMessage}
                        shouldShowInvalid={inputState.shouldShowInvalid}
                    />
                );
            }}
        </InputController>
    );
}

AddressInputSearch.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: AddressPropType,

    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,

    searchResultTypes: AddressSearchResultTypesPropType,

    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

AddressInputSearch.defaultProps = {
    id: undefined,
    value: undefined,

    onBlur: undefined,

    searchResultTypes: [ ADDRESS_SEARCH_TYPE.ADDRESS ],

    isRequired: undefined,
    isDisabled: undefined,
};

function suggestionRenderer(value) {
    return `${value.description}`;
}

function omit(obj, keys) {
    const newObj = { ...obj };
    keys.forEach(key => delete newObj[key]);
    return newObj;
}

export default AddressInputSearch;
