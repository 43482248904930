import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { defineMessages, useIntl } from 'react-intl';
import { withSize } from 'react-sizeme';
import { rgba } from 'polished';

import messageToString from '../functions/messageToString';
import getFormattedNumber from '../functions/getFormattedNumber';
import hasAtLeastOneValidSeries from '../functions/hasAtLeastOneValidSeries';
import Formats from '../definitions/Formats';
import MessagePropType from '../definitions/MessagePropType';
import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import Typography from '../definitions/Typography';


const CHART_TYPES = {
    LINE: 'line',
    COLUMN: 'column',
};

const X_AXIS_TYPES = {
    DATETIME: 'datetime',
    // LINEAR: 'linear', // For future use.
    // CATEGORY: 'category', // For future use.
};

// Height options - these are all fixed-height for now.
const HEIGHT_OPTIONS = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};
const HEIGHT_MAP = {
    [HEIGHT_OPTIONS.SMALL]: '400',
    [HEIGHT_OPTIONS.MEDIUM]: '600',
    [HEIGHT_OPTIONS.LARGE]: '800',
};

const TOOLTIP_X_VALUE_FORMATS = {
    DATETIME_YEAR_MONTH_DAY: 'datetime-ymd',
    DATETIME_YEAR_MONTH: 'datetime-ym',
    DATETIME_YEAR: 'datetime-y',
    DATETIME_MEDIUM: 'medium',
    DATETIME_LONG: 'long',
};

const DATE_MASKS = {
    [TOOLTIP_X_VALUE_FORMATS.DATETIME_YEAR_MONTH_DAY]: '%Y-%m-%d',
    [TOOLTIP_X_VALUE_FORMATS.DATETIME_YEAR_MONTH]: '%Y-%m',
    [TOOLTIP_X_VALUE_FORMATS.DATETIME_YEAR]: '%Y',
    [TOOLTIP_X_VALUE_FORMATS.DATETIME_MEDIUM]: '%b. %e, %Y',
    [TOOLTIP_X_VALUE_FORMATS.DATETIME_LONG]: '%B %d, %Y',
};

const CHART_TOOLTIP_LAYOUTS = {
    CONDENSED: 'condensed',
    DETAILED: 'detailed',
};

function Chart({ series, xAxis, yAxis, type, plotOptions, height, tooltip, size, ...otherProps }) {
    const intl = useIntl();
    const isCompact = size?.width < 500;

    const defaultOptions = getDefaultChartOptions(xAxis, type, isCompact);

    const mainSeries = series
        ? series.map((set, index) => ({
            name: messageToString(set.name, intl),
            data: set.data,
            ...(set.type && { type: set.type }),
            ...(set.yAxis && { yAxis: set.yAxis }),
            states: getDefaultStates(),
            stickyTracking: false,
            zIndex: index + 1, // display above any stat series
        }))
        : [];

    const statSeries = series && xAxis.values ? getStatSeries(series, xAxis.values, intl) : [];

    const options = {
        ...defaultOptions,
        ...(height && { chart: getChartOptions(height, defaultOptions.chart) }),
        series: [ ...mainSeries, ...statSeries ],
        ...(yAxis && { yAxis: getAllYAxisOptions(yAxis, defaultOptions.yAxis, intl) }),
        ...(plotOptions && { plotOptions: getPlotOptions(plotOptions, defaultOptions.plotOptions) }),
        ...(tooltip && { tooltip: getTooltipOptions({ tooltip, defaultTooltipOptions: defaultOptions.tooltip, series, intl }) }),
    };

    return (
        <ChartContainer
            {...otherProps}
        >
            <Choose>
                <When condition={hasAtLeastOneValidSeries(options)}>
                    <HighchartsReact
                        className="rh-chart"
                        highcharts={Highcharts}
                        options={options}
                    />
                </When>
                <Otherwise>
                    <p className="chart-error-message rh-text-m">
                        {intl.formatMessage(MESSAGES.NO_DATA)}
                    </p>
                </Otherwise>
            </Choose>
        </ChartContainer>
    );
}

const Y_AXIS_SHAPE = PropTypes.shape({
    title: MessagePropType,
    labelFormat: PropTypes.oneOf(Object.keys(Formats.number)),

    // Whether or not to show this axis on the 'opposite' side of the chart.
    opposite: PropTypes.bool,
});

Chart.propTypes = {
    series: PropTypes.arrayOf(
        PropTypes.shape({
            // A user-facing name that identifies the chart.
            name: MessagePropType.isRequired,

            // The actual series data, in format returned by fetchChartData().
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    x: PropTypes.number,
                    y: PropTypes.number,
                }),
            ).isRequired,

            // Each series can appear as it's own type, separate from the Chart's type.
            type: PropTypes.oneOf(Object.values(CHART_TYPES)),

            // Enable/disable stats for this series - if number is included,
            // build the series, otherwise omit it.
            stats: PropTypes.shape({
                min: PropTypes.number,
                mean: PropTypes.number,
                max: PropTypes.number,
            }),

            // The index of the yAxis to associate this series with.
            yAxis: PropTypes.number,

            tooltipLabelFormat: PropTypes.oneOf(Object.keys(Formats.number)),
        }),
    ),
    xAxis: PropTypes.shape({
        values: PropTypes.arrayOf(PropTypes.number),
        type: PropTypes.oneOf(Object.values(X_AXIS_TYPES)),
    }),
    yAxis: PropTypes.oneOfType([ Y_AXIS_SHAPE, PropTypes.arrayOf(Y_AXIS_SHAPE) ]),
    type: PropTypes.oneOf(Object.values(CHART_TYPES)), // The *Chart's* type
    plotOptions: PropTypes.shape({
        series: PropTypes.shape({
            isStacked: PropTypes.bool,
        }),
        [CHART_TYPES.COLUMN]: PropTypes.shape({
            isStacked: PropTypes.bool,
        }),
        [CHART_TYPES.LINE]: PropTypes.shape({
            isStacked: PropTypes.bool,
        }),
    }),
    height: PropTypes.oneOf(Object.values(HEIGHT_OPTIONS)),
    tooltip: PropTypes.shape({
        xValueFormat: PropTypes.oneOf(Object.values(TOOLTIP_X_VALUE_FORMATS)),
        layout: PropTypes.oneOf(Object.values(CHART_TOOLTIP_LAYOUTS)),
    }),
    size: PropTypes.object.isRequired,
};

Chart.defaultProps = {
    series: [],
    xAxis: {},
    yAxis: {},
    type: CHART_TYPES.LINE,
    plotOptions: {
        series: {
            isStacked: false,
        },
        [CHART_TYPES.COLUMN]: {
            isStacked: false,
        },
        [CHART_TYPES.LINE]: {
            isStacked: false,
        },
    },
    height: HEIGHT_OPTIONS.SMALL,
    tooltip: {
        xValueFormat: TOOLTIP_X_VALUE_FORMATS.DATETIME_YEAR_MONTH_DAY,
        layout: CHART_TOOLTIP_LAYOUTS.CONDENSED,
    },
};

const ChartContainer = styled.div`
    > .chart-error-message {
        padding: ${Sizes.SPACING.FOUR} 0;
        text-align: center;
    }

    .highcharts-yaxis-grid {
        .highcharts-grid-line {
            stroke-dasharray: 2 8;
        }
    }

    .highcharts-tooltip {
        > span {
            line-height: ${Sizes.FONTS.L};
        }
    }
`;

const Y_AXIS_LABEL_OFFSET = 8; // space between labels and plotlines (SVG user units)

function getChartOptions(height, chartOptions) {
    return {
        ...chartOptions,
        height: HEIGHT_MAP[height],
    };
}

function getPlotOptions(plotOptions, defaultPlotOptions) {
    return {
        ...defaultPlotOptions,
        series: {
            ...defaultPlotOptions.series,
            ...(plotOptions.series?.isStacked && { stacking: 'normal' }),
        },
        [CHART_TYPES.COLUMN]: {
            ...defaultPlotOptions.column,
            ...(plotOptions.column?.isStacked && { stacking: 'normal' }),
        },
        [CHART_TYPES.LINE]: {
            ...defaultPlotOptions.line,
            ...(plotOptions.line?.isStacked && { stacking: 'normal' }),
        },
    };
}

function getAllYAxisOptions(yAxis, defaultYAxisOptions, intl) {
    return Array.isArray(yAxis)
        ? yAxis.map(singleYAxis => getSingleYAxisOptions(singleYAxis, defaultYAxisOptions, intl))
        : getSingleYAxisOptions(yAxis, defaultYAxisOptions, intl);
}

function getSingleYAxisOptions(singleYAxis, defaultYAxisOptions, intl) {
    return {
        ...defaultYAxisOptions,
        labels: {
            ...defaultYAxisOptions.labels,
            ...(singleYAxis.labelFormat && {
                formatter: function() {
                    // `this` refers to highcharts axis.
                    return getFormattedNumber(this.value, singleYAxis.labelFormat, intl);
                },
            }),
            x: singleYAxis.opposite ? Y_AXIS_LABEL_OFFSET : -Y_AXIS_LABEL_OFFSET,
        },
        ...(singleYAxis.opposite && { opposite: singleYAxis.opposite }),
        ...(singleYAxis.title && {
            title: {
                ...defaultYAxisOptions.title,
                enabled: true,
                text: messageToString(singleYAxis.title, intl),
            },
        }),
    };
}

function getTooltipOptions({ tooltip, defaultTooltipOptions, series, intl }) {
    return {
        ...defaultTooltipOptions,
        formatter: function() {
            const title = this.series.name;

            // @TECH DEBT: Allow for non-datetime xValueFormats
            // See here for date formatting docs: https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat
            const xValue = Highcharts.dateFormat(DATE_MASKS[tooltip.xValueFormat], this.x);

            // Initialize & format the yValue
            const currentSeries = series.find(set => messageToString(set.name, intl) === this.series.name);
            const seriesFormat = currentSeries?.tooltipLabelFormat ?? 'percent2';

            const yValue = getFormattedNumber(
                seriesFormat.startsWith('percent') ? this.y / 100 : this.y,
                seriesFormat,
                intl,
            );

            // Render a layout
            let layout = '';

            if (tooltip.layout === CHART_TOOLTIP_LAYOUTS.CONDENSED) {
                layout = `
                    <div class="layout-condensed rh-text-align-center">
                        <div class="text-row weight-regular">${title} ${xValue}:</div>
                        <div class="text-row weight-medium">${yValue}</div>
                    </div>
                `;
            } else {
                layout = `
                    <div class="layout-detailed rh-text-align-center">
                        <div class="text-row rh-text-2xs rh-fg-stone-darkest rh-mb-0_75">${title}</div>
                        <div class="text-row">${xValue}</div>
                        <div class="text-row weight-medium">${yValue}</div>
                    </div>
                `;
            }

            return `
                <div class="layout-${tooltip.layout}">
                    ${layout}
                </div>
            `;
        },
    };
}

// Take our all of our series data and contruct a flat array of
// Highcharts-formatted objects respresenting just the "stat" series (min/mean/max).
function getStatSeries(series, dates, intl) {
    return series.reduce((allStatSeries, currentStatSeries) => {
        Object.entries(currentStatSeries.stats).forEach(([ stat, value ]) => {
            let statName = intl.formatMessage(SERIES_LABEL_MESSAGES[stat]); // eg. 'Minimum'
            let statValue = value;

            if (statName && statValue) {
                const seriesName = messageToString(currentStatSeries.name, intl); // eg. '5-yr Fixed'

                allStatSeries.push({
                    name: `${seriesName} (${statName})`,
                    data: dates.map(date => ({ x: date, y: statValue })),
                    type: CHART_TYPES.LINE, // stat series should always be LINE
                    states: getDefaultStates(),
                    stickyTracking: false,
                    zIndex: 0,
                });
            }
        });

        return allStatSeries;
    }, []);
}

function getDefaultChartOptions(xAxis, type, isCompact) {
    return {
        chart: {
            type: type,
            styledMode: false,
            backgroundColor: Colours.TRANSPARENT,
            style: {
                fontFamily: Typography.FONT_FAMILY_STACK,
            },
            spacingLeft: 3,
            spacingRignt: 3,
        },
        title: {
            text: undefined, // disable HighCharts' built-in title
        },
        colors: [
            Colours.BLUEBERRY,
            Colours.LIME,
            Colours.BLUEBERRY_DARK,
            Colours.BLUEBERRY_LIGHT,
            Colours.YUZU,
            Colours.GRAPE,
        ],
        xAxis: {
            type: xAxis?.type ?? X_AXIS_TYPES.DATETIME,

            gridLineWidth: 0,
            gridLineColor: Colours.STONE,
            lineWidth: 0,
            lineColor: Colours.BLACKBERRY,
            tickLength: 0,
            labels: {
                style: {
                    color: Colours.STONE_DARKEST,
                    fontFamily: 'inherit',
                    fontWeight: Typography.WEIGHTS.REGULAR,

                    // Using px-based fonts on charts since the rest of the
                    // chart cannot respond to root font-size changes. The
                    // growing/shrinking of the text was causing text elements
                    // to overlap other chart elements.
                    fontSize: !isCompact ? '14px' : '10.5px',
                },
                y: 32,
            },
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            title: {
                enabled: false,
                margin: !isCompact ? 30 : 15,
            },
            gridLineWidth: 1,
            gridLineColor: Colours.STONE,
            lineWidth: 0,
            lineColor: Colours.BLACKBERRY,
            labels: {
                style: {
                    color: Colours.STONE_DARKEST,
                    fontFamily: 'inherit',
                    fontWeight: Typography.WEIGHTS.REGULAR,

                    // Using px-based fonts on charts since the rest of the
                    // chart cannot respond to root font-size changes. The
                    // growing/shrinking of the text was causing text elements
                    // to overlap other chart elements.
                    fontSize: !isCompact ? '14px' : '10.5px',
                },
                formatter: function() {
                    return this.value + '%';
                },
                x: -Y_AXIS_LABEL_OFFSET, // Negative value assumes this is a left axis. Use positive for opposite axis.
            },
        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'left',
            borderWidth: 0,
            y: 0,
            margin: 20,
            itemStyle: {
                fontSize: !isCompact ? '14px' : '10.5px',
                fontFamily: 'inherit',
                fontWeight: 400,
            },
        },
        plotOptions: {
            series: {
                lineWidth: 2,
                marker: {
                    enabled: false, // disable all markers by default
                    symbol: 'circle', // the symbol used when enabled (on hover)
                    radius: 3,
                },
                states: {
                    hover: {
                        enabled: true,
                    },
                },
                shadow: false,
            },
        },
        tooltip: {
            formatter: function() {
                const title = this.series.name;
                const date = Highcharts.dateFormat('%Y-%m-%d', this.x);
                const value = Highcharts.numberFormat(this.y, 2, '.', ',');

                return `
                    <div class="text-row">${title}</div>
                    <div class="text-row">${date}: ${value}%</div>
                `;
            },
            style: {
                fontFamily: 'inherit',
                fontSize: Sizes.FONTS['XS'],
                lineHeight: Sizes.LINE_HEIGHT.M,
            },
            padding: 20, // must be in px, highcharts only accepts number.
            backgroundColor: Colours.COCONUT,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: Colours.STONE,
            shadow: {
                color: rgba(Colours.STONE, 0.3),
                offsetX: 0,
                offsetY: 2,
                opacity: 0.2,
                width: 11,
            },
            useHTML: true,
        },
        credits: {
            enabled: false,
        },
    };
}

// These states are currently used for all series types. The API technically
// takes a different structure depending on series type, but differences
// are subtle and a generalized object works for all types for us.
function getDefaultStates() {
    return {
        inactive: {
            opacity: 1,
        },
    };
}

const MESSAGES = defineMessages({
    NO_DATA: {
        id: 'base-ui.Chart.no-data-found',
        defaultMessage: 'Sorry, no chart data available.',
    },
    SERIES_LABEL_MINIMUM: {
        id: 'base-ui.Chart.SeriesLabelMinimum',
        defaultMessage: 'Min.',
    },
    SERIES_LABEL_MEAN: {
        id: 'base-ui.Chart.SeriesLabelMean',
        defaultMessage: 'Avg.',
    },
    SERIES_LABEL_MAXIMUM: {
        id: 'base-ui.Chart.SeriesLabelMaximum',
        defaultMessage: 'Max.',
    },
});

const SERIES_LABEL_MESSAGES = {
    min: MESSAGES.SERIES_LABEL_MINIMUM,
    mean: MESSAGES.SERIES_LABEL_MEAN,
    max: MESSAGES.SERIES_LABEL_MAXIMUM,
};

export default withSize()(Chart);
export { CHART_TYPES, TOOLTIP_X_VALUE_FORMATS, CHART_TOOLTIP_LAYOUTS };
