import { submitConversionPayload } from '@ratehub/base-ui';

import createLeadPayload from './createLeadPayload';
import createCPAPayload from './createCPAPayload';
import createCPCPayload from './createCPCPayload';


/**
 * @async
 * Submit a mortgage conversion to the backend
 * @param {object} values 
 * @param {object} values.rate - Rate object currently being inquired on
 * @param {object} values.lead
 * @param {object} values.experiments - All current active mortgage experiments
 * @param {string} values.locale
 * @returns {object} conversion id
 */
async function submitMortgageConversion(values) {
    const conversionPayload = getPayloadForConversion(values);

    return await submitConversionPayload(conversionPayload);
}

function getPayloadForConversion(values) {
    const { cpcLink, cpaLink } = values.rate;

    if (cpcLink) {
        return createCPCPayload(values);
    } else if (cpaLink) {
        return createCPAPayload(values);
    } else {
        return createLeadPayload(values);
    }
}

export default submitMortgageConversion;