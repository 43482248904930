import { defineMessages } from 'react-intl';

import SPENDING_CATEGORIES from './SpendingCategories';


export default defineMessages({
    DESCRIPTION: {
        id: 'cc-cardfinder.earnings-table.description',
        defaultMessage: 'On {categories}',
    },
    [SPENDING_CATEGORIES.GROCERIES]: {
        id: 'cc-cardfinder.earnings-table.groceries',
        defaultMessage: 'Groceries',
    },
    [SPENDING_CATEGORIES.GAS]: {
        id: 'cc-cardfinder.earnings-table.gas',
        defaultMessage: 'Gas',
    },
    [SPENDING_CATEGORIES.RESTAURANTS]: {
        id: 'cc-cardfinder.earnings-table.restaurants',
        defaultMessage: 'Restaurants (including fast food & drinks)',
    },
    [SPENDING_CATEGORIES.BILLS]: {
        id: 'cc-cardfinder.earnings-table.bills',
        defaultMessage: 'Bills',
    },
    [SPENDING_CATEGORIES.TRAVEL]: {
        id: 'cc-cardfinder.earnings-table.travel',
        defaultMessage: 'Travel (including flights, hotels, taxis, public transit)',
    },
    [SPENDING_CATEGORIES.ENTERTAINMENT]: {
        id: 'cc-cardfinder.earnings-table.entertainment',
        defaultMessage: 'Entertainment',
    },
    [SPENDING_CATEGORIES.PHARMACY]: {
        id: 'cc-cardfinder.earnings-table.pharmacy',
        defaultMessage: 'Drug & Pharmacy',
    },
    [SPENDING_CATEGORIES.OTHER]: {
        id: 'cc-cardfinder.earnings-table.other',
        defaultMessage: 'Other purchases',
    },
});
