import React from 'react';
import dynamic from 'next/dynamic';

import CircleSpinner from './CircleSpinner';


export default dynamic(
    () => import(/* webpackChunkName: "charts" */'./Highchart'),
    {
        ssr: false,
        loading: () => <CircleSpinner />,
    },
);
