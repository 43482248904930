import PRODUCT_TYPES from '../definitions/ProductTypes';
import isEarningsAPercentageOverride from './isEarningsAPercentageOverride';

/**
 * Should the given card show earnings expressed as % of money spent, or as pts / dollar?
 *
 * @param {Object} arg
 * @param {string} arg.cardId used to check for card-specific overrides that opt-in to percentage
 * @param {string} arg.cardProductType used if this is an ANY list, but this is a cash-back card
 * @param {string} [arg.productType=arg.cardProductType] if we’re in a product list, what type?
 * @return {boolean}
 */
function isEarningsAPercentage({ cardId, cardProductType, productType = cardProductType }) {
    return productType === PRODUCT_TYPES.CASH_BACK
        || (productType === PRODUCT_TYPES.ANY_REWARDS && cardProductType === PRODUCT_TYPES.CASH_BACK)
        || isEarningsAPercentageOverride(cardId);
}

export default isEarningsAPercentage;
