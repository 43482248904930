import { Colours } from '@ratehub/base-ui';


// `content` override properties for ModalFullScreenTakeoverStyles required by CreditCardIncentive
export default {
    content: {
        padding: 0,
        inset: 0,
        borderRadius: 0,
        backgroundImage: `linear-gradient(180deg, ${Colours.GRAPE_LIGHTEST} 345px, ${Colours.COCONUT} 345px)`,
    },
};
